import React from 'react';
import { Component, Fragment } from 'react'
import FormSelect from '../FormSelect'
import Field from './Field';

export default class extends Component {
  // a schema object can't contain both
  state = { schemaOneAnyOf: undefined, indexOneAnyOf: undefined };

  componentWillMount() {
    const { indexOneAnyOf } = this.props;
    if (indexOneAnyOf) {
      this.setState({ schemaOneAnyOf: this.props.multiOp[indexOneAnyOf], indexOneAnyOf });
    }
  }

  render() {
    const options = this.props.multiOp.map((op, i) => ({ content: op.title, value: i }));
    return (
      <Fragment>
        <FormSelect
          label={this.props.label}
          noForm
          name={'frmSelect' + this.props.name}
          options={options}
          value={this.state.indexOneAnyOf}
          onChange={(i) => {
            this.props.onChange();
            this.setState({ schemaOneAnyOf: this.props.multiOp[i], indexOneAnyOf: i });
            return i;
          }}
        />
        {
          this.state.schemaOneAnyOf &&
          <Field
            name={this.props.name}
            schema={this.state.schemaOneAnyOf}
          />
        }
      </Fragment>
    );
  }
}