import ConsoleError from '/error/ConsoleError';
import FinaleAccessor from '/lib/accessors/FinaleAccessor';

export const User = new FinaleAccessor('/api/v1', 'user');

export const fetchLoggedUser = () => {
  return async (dispatch, getState) => {
    dispatch({ type: 'USER_LOGGED_FETCH' })

    try {
      const data = await doFetchLoggedUser();
      dispatch({ type: 'USER_LOGGED_FETCH_SUCCESS', data });
    } catch (error) {
      dispatch({ type: 'USER_LOGGED_FETCH_FAIL', error });
    }
  }
}

export const doFetchLoggedUser = async () => {
  const response = await fetch('/api/v1/user/current', { credentials: 'include' });

  if (response.status === 200) {
    return response.json();
  } else if (response.status === 401) {
    throw new ConsoleError('unouthorized', 'fetching logged user');
  }
}

export const fetchUsersList = (text = '') => {
  return async (dispatch, getState) => {
    dispatch({ type: 'USER_LIST_FETCH_START' })

    try {
      const url = !text ? '/list' : `/list?text=${text}`;
      const response = await User.fetch(url, { credentials: 'include' });
      if (response.status === 401) {
        throw new ConsoleError('unouthorized', 'fetching user list');
      }
      const users = await response.json();
      dispatch({ type: 'USER_LIST_FETCH_SUCCESS', users });
    } catch (error) {
      dispatch({ type: 'USER_LIST_FETCH_FAIL', error });
    }
  }
}

export const fetchUser = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: 'USER_FETCH_START' })

    try {
      const response = await User.fetch(`/${id}`, { credentials: 'include' });
      if (response.status === 401) {
        throw new ConsoleError('unouthorized', 'fetching user');
      }
      const data = await response.json();
      dispatch({ type: 'USER_FETCH_SUCCESS', data });
    } catch (error) {
      dispatch({ type: 'USER_FETCH_FAIL', error });
    }
  }
}

export const updateUserField = (field, value) => ({ type: 'USER_UPDATE_FIELD', field, value });

export const updateUser = () => {
  return async (dispatch, getState) => {
    dispatch({ type: 'USER_UPDATE_START' })

    try {
      const user = getState().user.otherUser.data;
      const response = await User.fetch(`/${user.id}`, {
        method: 'PUT',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(user)
      });
      if (response.status === 401) {
        throw new ConsoleError('unouthorized', 'updating user');
      }
      dispatch({ type: 'USER_UPDATE_SUCCESS', data: await response.json() });
    } catch (error) {
      dispatch({ type: 'USER_UPDATE_FAIL', error });
    }
  }
}

export const deleteUser = (id, callback) => {
  return async (dispatch, getState) => {
    dispatch({ type: 'USER_DELETE_START' })

    try {
      const response = await User.fetch(`/${id}`, {
        method: 'DELETE',
        headers: new Headers({ 'Content-Type': 'application/json' })
      });
      if (response.status === 401) {
        throw new ConsoleError('unouthorized', 'deleting user');
      }
      dispatch({ type: 'USER_DELETE_SUCCESS' });
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({ type: 'USER_DELETE_FAIL', error });
    }
  }
}

export const cleanUserData = () => ({ type: 'USER_CLEAN_DATA' })

export const createUser = (callback) => {
  return async (dispatch, getState) => {
    dispatch({ type: 'USER_CREATE_START' })

    try {
      const user = getState().user.otherUser.data;
      const response = await User.fetch('', {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(user)
      });
      if (response.status === 401) {
        throw new ConsoleError('unouthorized', 'deleting user');
      }
      const createdUser = await response.json();
      dispatch({ type: 'USER_CREATE_SUCCESS' });
      if (callback) {
        callback(createdUser);
      }
    } catch (error) {
      dispatch({ type: 'USER_CREATE_FAIL', error });
    }
  }
}

export const resetPassword = (id, callback) => {
  return async (dispatch, getState) => {
    dispatch({ type: 'USER_RESET_PASSWORD_START' })

    try {
      const user = getState().user.otherUser.data;
      const response = await User.fetch(`/reset_password/${id}`, {
        method: 'PUT',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ password: user.password })
      });
      if (response.status === 401) {
        throw new ConsoleError('unouthorized', 'deleting user');
      }
      dispatch({ type: 'USER_RESET_PASSWORD_SUCCESS' });
      if (callback) {
        callback(id)
      }
    } catch (error) {
      dispatch({ type: 'USER_RESET_PASSWORD_FAIL', error });
    }
  }
}

export const fetchRoles = () => async (dispatch, getState) => {

  dispatch({ type: 'USER_FETCH_ROLES_START' });

  try {
    const response = await User.fetch(`/roles`, {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json' })
    });
    if (response.status === 401) {
      throw new ConsoleError('unouthorized', 'deleting user');
    }
    const roles = await response.json();
    dispatch({ type: 'USER_FETCH_ROLES_SUCCESS', roles });
  } catch (error) {
    dispatch({ type: 'USER_FETCH_ROLES_FAIL', error });
  }
}

export const createUserRole = (id, role, callback) => {
  return async (dispatch, getState) => {
    dispatch({ type: 'USER_CREATE_ROLE_START' })

    try {
      const response = await User.fetch(`/${id}/role/${role}`, {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' })
      });
      if (response.status === 401) {
        throw new ConsoleError('unouthorized', 'deleting user');
      }
      dispatch({ type: 'USER_CREATE_ROLE_SUCCESS' });
      if (callback) {
        callback()
      }
    } catch (error) {
      dispatch({ type: 'USER_CREATE_ROLE_FAIL', error });
    }
  }
}
export const deleteUserRole = (id, role, callback) => {
  return async (dispatch, getState) => {
    dispatch({ type: 'USER_DELETE_ROLE_START' })

    try {
      const response = await User.fetch(`/${id}/role/${role}`, {
        method: 'DELETE',
        headers: new Headers({ 'Content-Type': 'application/json' })
      });
      if (response.status === 401) {
        throw new ConsoleError('unouthorized', 'deleting user');
      }
      dispatch({ type: 'USER_DELETE_ROLE_SUCCESS' });
      if (callback) {
        callback()
      }
    } catch (error) {
      dispatch({ type: 'USER_DELETE_ROLE_FAIL', error });
    }
  }
}