import React, { Component, Fragment } from 'react';
import Field from './JSONSchema/Field';

export default class FormJSONSchema extends Component {
  options = {};

  render() {
    return (
      <Fragment>
        <Field
          name={this.props.name}
          schema={this.props.schema}
          value={this.props.value || {}}
        />
      </Fragment>
    );
  }
}