
import ConsoleError from '/error/ConsoleError';
import Accessor from '/lib/accessors/Accessor';
import queryParams from '/lib/queryParams';

export default class FinaleAccessor extends Accessor {

  async fetchAll(query = {}, sort) {
    const response = await this.fetch(`?${queryParams({ ...query, sort })}`);

    if(response.status === 200) {
      const contentRange = this.getContentRange(response);
      const data = this.generateEntries(await response.json());
      return { data, contentRange };
    } else if(response.status === 403) {
      throw new ConsoleError('forbidden', `fetching ${this.entity} list`);
    } else if(response.status === 401) {
      throw new ConsoleError('unouthorized', `fetching ${this.entity} list`);
    } else {
      throw new ConsoleError('unknown', `fetching ${this.entity} list`);
    }
  }

  async fetchList(page, perPage, sort, query) {
    const offset = (page - 1) * perPage;
    const count = perPage;
    const response = await this.fetch(`?${queryParams({ offset, count, sort, ...query })}`);

    if(response.status === 200) {
      const contentRange = this.getContentRange(response);
      const data = this.generateEntries(await response.json());
      return { data, contentRange };
    } else if(response.status === 403) {
      throw new ConsoleError('forbidden', `fetching ${this.entity} list`);
    } else if(response.status === 401) {
      throw new ConsoleError('unouthorized', `fetching ${this.entity} list`);
    } else {
      throw new ConsoleError('unknown', `fetching ${this.entity} list`);
    }
  }

  async fetchOne(id) {
    const response = await this.fetch(`/${id}`);

    if(response.status === 200) {
      return this.generateEntry(await response.json());
    } else if(response.status === 403) {
      throw new ConsoleError('forbidden', `fetching ${this.entity} ${id}`);
    } else if(response.status === 401) {
      throw new ConsoleError('unouthorized', `fetching ${this.entity} ${id}`);
    } else {
      throw new ConsoleError('unknown', `fetching ${this.entity} ${id}`);
    }
  }

  async save(id, entry) {
    const response = await this.fetch(`/${id}`, {
      method: 'PUT',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(entry)
    });

    if(response.status === 200) {
      return;
    } else if(response.status === 422) {
      throw new ConsoleError('validation_error', `updating ${this.entity} ${id}`);
    } else if(response.status === 403) {
      throw new ConsoleError('forbidden', `updating ${this.entity} ${id}`);
    } else if(response.status === 401) {
      throw new ConsoleError('unouthorized', `updating ${this.entity} ${id}`);
    } else {
      throw new ConsoleError('unknown', `updating ${this.entity} ${id}`);
    }
  }

  async create(data) {
    const response = await this.fetch({
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(data)
    });

    if(response.status === 201) {
      return this.generateEntry(await response.json());
    } else if(response.status === 422) {
      throw new ConsoleError('validation_error', `creating ${this.entity}`);
    } else if(response.status === 403) {
      throw new ConsoleError('forbidden', `creating ${this.entity}`);
    } else if(response.status === 401) {
      throw new ConsoleError('unouthorized', `creating ${this.entity}`);
    } else {
      throw new ConsoleError('unknown', `creating ${this.entity}`);
    }
  }

  async delete(id) {
    const response = await this.fetch(`/${id}`, {
      method: 'DELETE'
    });

    if(response.status === 200) {
      return this.generateEntry(await response.json());
    } else if(response.status === 422) {
      throw new ConsoleError('validation_error', `creating ${this.entity}`);
    } else if(response.status === 403) {
      throw new ConsoleError('forbidden', `creating ${this.entity}`);
    } else if(response.status === 401) {
      throw new ConsoleError('unouthorized', `creating ${this.entity}`);
    } else {
      throw new ConsoleError('unknown', `creating ${this.entity}`);
    }
  }

  getContentRange(response) {
    const contentRange = response.headers.get('content-range');
    const [ match, $1, $2, $3 ] = contentRange.match(/items ([0-9]{1,})-([0-9]{1,})\/([0-9]{1,})/);
    const [ from, to, total ] = [ parseInt($1), parseInt($2), parseInt($3) ];
    return { from, to, total };
  }

}