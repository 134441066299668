
import FinaleAccessor from '/lib/accessors/FinaleAccessor';
import CRUDActions from '/lib/CRUDActions';

import { ValidatorGroup } from './validator_group';

export const Validator = new FinaleAccessor('/api/v1', 'validator');
export const ValidatorActions = CRUDActions(Validator, {
  hooks: [
    {
      name: 'create_afterCreate',
      async handler(callback, validatorGroupId) {
        await ValidatorGroup.fetch(`/${ validatorGroupId }/validator/${ this.data.entry.id }`, { method: 'POST' });
      }
    }
  ]
});

export const fetchValidators = ValidatorActions.fetchValidators;
export const fetchValidator = ValidatorActions.fetchValidator;
export const setValidatorListQuery = ValidatorActions.setValidatorListQuery;
export const updateValidatorField = ValidatorActions.updateValidatorField;
export const updateValidator = ValidatorActions.updateValidator;
export const deleteValidator = ValidatorActions.deleteValidator;
export const copyFromValidator = ValidatorActions.copyFromValidator;
export const createValidator = ValidatorActions.createValidator;
export const cleanValidatorData = ValidatorActions.cleanValidatorData;
