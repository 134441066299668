
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import { fetchDataSource, deleteDataSource } from '/actions/dataSource';

@connect()
@decorate(withRouter)
export default class DataSourceDelete extends Component {

  static mapStateToProps(store) {
    return {
      entry: store.data_source.data,
      isLoading: store.data_source.isLoading
    }
  }

  static mapDispatchToProps = {
    fetch: fetchDataSource,
    delete: deleteDataSource
  }

  delete() {
    this.props.delete(this.props.entry.id, () => this.goBack());
  }

  goBack() {
    this.props.history.push(`/console/data_sources`);
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.id);
  }

  render() {
    if (this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <h2>Delete Data source <b>#{this.props.entry.id}</b></h2>
        <p>Está seguro de que quiere eliminar el Data source <b>"{this.props.entry.name}"</b>?</p>
        <div>
          <button className="btn" onClick={() => this.delete()}>Eliminar</button>
          &nbsp;
          <button className="btn" onClick={() => this.goBack()}>Cancelar</button>
        </div>
      </div>
    );
  }
}
