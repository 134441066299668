
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import { fetchDataSource, updateDataSource, updateDataSourceField } from '/actions/dataSource';
import { DataSourceEdit } from './DataSourceEdit';
import { fetchAllDataSourcesTypes } from '/actions/datasource_types';

@connect()
@decorate(withRouter)
export default class DataSourceUpdate extends Component {

  static mapStateToProps(store) {
    return {
      entry: store.data_source.data,
      isLoading: store.data_source.isLoading,
      submitting: store.data_source.updating,
      failSubmit: store.data_source.failUpdating,
      types: store.datasource_types.list.items
    }
  }

  static mapDispatchToProps = {
    fetch: fetchDataSource,
    updateField: updateDataSourceField,
    update: updateDataSource,
    fetchTypes: fetchAllDataSourcesTypes
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.id);
    this.props.fetchTypes();
  }

  render() {
    if (this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }
    return (
      <div className="container">
        <h2>Update Data source #{this.props.entry.id}</h2>
        <DataSourceEdit
          onChange={this.props.updateField}
          onSubmit={() => { this.props.update(); this.props.history.goBack(); }}
          values={ this.props.entry }
          types={this.props.types}
          submitting={this.props.submitting}
          failSubmit={this.props.failSubmit}
        />
      </div>
    );
  }
}
