import React, { Component } from 'react';
import FormInput from '../FormInput';
import Field from '/components/ui/Forms/JSONSchema/Field';
import Form from '/components/ui/Forms/Form';
import withForm from '/components/ui/Forms/withForm';

const parseByTypes = {
  integer: value => parseInt(value || 0, 10),
  number: value => parseFloat(value || 0)
}

@withForm()
export default class TypeArray extends Component {

  state = {
    displayValue: "",
    objectValue: []
  }

  get delimiter() {
    return this.props.delimiter || ',';
  }

  getParser() {
    return parseByTypes[this.props.parseItems] || (value => value);
  }

  handleChange(value) {
    this.setState({ displayValue: value });

    return value
      .split(this.delimiter)
      .filter(value => !!value.trim())
      .map(this.getParser());
  }

  handleObjectArrayChange(index, value) {

    const values = [...this.state.objectValue];
    values[index] = value;

    this.setState({ objectValue: values });
    this.props.onChange(values);

    return values;
  }

  getObjectValue() {
    return this.state.objectValue.reduce((res, val, i) => ({ ...res, [i]: val }), {});
  }

  parseValue(value) {
    if (!this.state.displayValue) {
      return Array.isArray(value) ? value && value.join(this.delimiter) || [] : value;
    } else {
      return this.state.displayValue;
    }
  }
  newElementOnArray() {
    const newVal = this.state.objectValue;
    newVal.push({})
    this.setState({ objectValue: newVal });
  }
  render() {
    if (!this.props.items || this.props.items.type !== 'object') {
      return (
        <FormInput
          {...this.props}
          placeholder={`¡Array field! Separate values by "${this.delimiter}"`}
          onChange={value => this.handleChange(value)}
          parseValue={value => this.parseValue(value)}
        />
      );
    } else {
      return (
        <Form
          onChange={(index, value) => this.handleObjectArrayChange(index, value)}
          values={this.getObjectValue()}
        >
          {
            this.state.objectValue.map((elem, index) => {
              return <Field
                key={index}
                name={"" + index}
                schema={this.props.items}
              />
            })
          }
          < button className="btn btn-primary" onClick={() => this.newElementOnArray()} > Add {this.props.name} value</button >
          <br />
        </Form>
      );
    }
  }

}
