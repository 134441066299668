import React, { Component } from 'react';
import withForm from './withForm';
import Form from './Form';

@withForm()
export default class FormObject extends Component {

  handleChange(key, value) {
    this.props.onChange({
      ...this.props.value,
      [key]: value
    })
  }

  render() {
    return (
      <Form
        onChange={ (key, value) => this.handleChange(key, value) }
        values={ this.props.value || {} }
      >
        <div className={ "form-group " + this.props.className }>
          { this.props.children }
        </div>
      </Form>
    );
  }

}
