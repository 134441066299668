
import React, { Component } from 'react';

import connect from '/components/decorators/connect';

import { Link } from 'react-router-dom';

import CardList from '/components/ui/CardList/CardList';
import Pagination from '/components/ui/Pagination';
import Form, { FormInput, FormSelect } from '/components/ui/Forms';
import CountryInput from '/components/views/Country/CountryInput';
import DataSourceInput from '/components/views/DataSource/DataSourceInput';
import SurveyCard from './SurveyCard';

import { fetchSurveys, setSurveyListQuery } from '/actions/survey';

@connect()
export default class SurveysList extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      surveys: globalState.survey.list.items,
      isLoading: globalState.survey.list.isLoading,
      page: globalState.survey.list.page,
      perPage: globalState.survey.list.perPage,
      contentRange: globalState.survey.list.contentRange,
      query: globalState.survey.list.query
    }
  }

  static mapDispatchToProps = {
    fetchSurveys,
    setListQuery: setSurveyListQuery
  }

  componentDidMount() {
    this.fetchPage();
  }

  fetchPage(page = this.props.page) {
    if (!this.props.isLoading) {
      this.props.fetchSurveys(page, () => this.isReady = true);
    }
  }

  onNext() {
    this.fetchPage(this.props.page + 1);
  }

  onPrev() {
    this.fetchPage(this.props.page - 1);
  }

  updateQuery(field, value) {
    this.props.setListQuery(field, value);
    if (this.timeOutId) {
      window.clearTimeout(this.timeOutId);
    }
    if (this.isReady) {
      this.timeOutId = setTimeout(() => {
        this.fetchPage();
      }, 500);
    }
  }

  render() {
    return (
      <div className="container-fluid view-list">
        <div className="row">
          <div className="col-9">
            <h1>
              Surveys
              <Link className="btn btn-primary float-right" to="/survey/create">NEW SURVEY</Link>
            </h1>
          </div>
          <div className="col-3">
            <Pagination
              onNext={() => this.onNext()}
              onPrev={() => this.onPrev()}
              contentRange={this.props.contentRange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-9">
            <CardList
              cards={this.props.surveys}
              render={entry => <SurveyCard key={entry.id} entry={entry} actions={{ list: false }} />}
              isLoading={this.props.isLoading}
            />
          </div>
          <div className="col-3">
            <Form
              onChange={(field, value) => this.updateQuery(field, value || undefined)}
              onSubmit={() => this.fetchPage()}
              values={this.props.query}
            >
              <FormInput
                name="q"
                label="Search:"
              />
              <FormInput
                name="sourceSurveyId"
                label="Source Survey ID:"
                type="string"
              />
              <DataSourceInput
                name="dataSourceId"
                label="Data Source:"
              />
              <CountryInput
                name="countryId"
                label="Country:"
              />
              <FormSelect
                name="sort"
                label="Sort:"
                options={
                  [
                    { value: "-createdAt", content: "Creados recientemente" },
                    { value: "createdAt", content: "Created anteriormente" },
                    { value: "-updatedAt", content: "Actualizados recientemente" },
                    { value: "updatedAt", content: "Actualizados anteriormente" }
                  ]
                }
              />
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
