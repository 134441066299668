
import FinaleAccessor from '/lib/accessors/FinaleAccessor';
import CRUDActions from '/lib/CRUDActions';
import clientHooks from './clientHooks';

class ValidatorTypesAccessor extends FinaleAccessor {
  getContentRange() {}
}

export const ValidatorTypes = new ValidatorTypesAccessor('/api/v1', 'validator_types');
export const ValidatorTypesActions = CRUDActions(ValidatorTypes, { hooks: clientHooks });

export const fetchAllValidatorTypes = ValidatorTypesActions.fetchAllValidatorTypes;