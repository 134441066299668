import React, { Component } from 'react';
import { FormSelect } from '/components/ui/Forms';

import { FieldmapGroup } from '/actions/fieldmap_group';

export default class FieldmapGroupInput extends Component {

  state = {
    options: []
  }

  static get defaultProps() {
    return {
      label: 'Fieldmap group:',
      valueField: 'id',
      contentField: 'name',
      name: 'fieldmapGroupId',
      placeholder: ''
    }
  }

  componentWillMount() {
    this.load = true;
  }

  componentWillUnmount() {
    this.load = false;
  }

  async componentDidMount() {
    const { data: fieldmapGroups } = await FieldmapGroup.fetchAll({}, 'name')

    if(!this.load) {
      return;
    }

    this.setState({
      options: fieldmapGroups.map(fieldmapGroup => ({
        value: fieldmapGroup[this.props.valueField],
        content: fieldmapGroup[this.props.contentField]
      }))
    })
  }

  render() {
    return (
      <FormSelect
        { ...this.props }
        placeholder={ "Select a fieldmap group..." }
        default="*undefined*"
        options={ [ { value: undefined, content: this.props.placeholder }, ...this.state.options ] }
      />
    );
  }
}
