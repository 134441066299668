
import React, { Component } from 'react';

import connect from '/components/decorators/connect';

import { Link } from 'react-router-dom';

import CardList from '/components/ui/CardList/CardList';
import Pagination from '/components/ui/Pagination';
import Form, { FormInput, FormSelect } from '/components/ui/Forms';
import FieldmapGroupCard from './FieldmapGroupCard';

import { importFieldmaps, loadFieldmapData } from '/actions/fieldmap';
import {
  importFieldmapGroup,
  fetchFieldmapGroups,
  setFieldmapGroupListQuery,
} from '/actions/fieldmap_group';
import ImportButton from '../../ui/ImportButton';

@connect()
export default class FieldmapGroupList extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      items: globalState.fieldmap_group.list.items,
      isLoading: globalState.fieldmap_group.list.isLoading,
      page: globalState.fieldmap_group.list.page,
      perPage: globalState.fieldmap_group.list.perPage,
      contentRange: globalState.fieldmap_group.list.contentRange,
      query: globalState.fieldmap_group.list.query
    }
  }

  static mapDispatchToProps = {
    fetchFieldmapGroups,
    importFieldmapGroup,
    importFieldmaps,
    setListQuery: setFieldmapGroupListQuery,
    loadFieldmapData
  }

  state = {
    importing: false
  }

  componentDidMount() {
    this.fetchPage();
  }

  fetchPage(page = this.props.page) {
    this.props.fetchFieldmapGroups(page, () => this.isReady = true);
  }

  onNext() {
    this.fetchPage(this.props.page + 1);
  }

  onPrev() {
    this.fetchPage(this.props.page - 1);
  }

  updateQuery(field, value) {
    this.props.setListQuery(field, value);
    if (this.timeOutId) {
      window.clearTimeout(this.timeOutId);
    }
    if (this.isReady) {
      this.timeOutId = setTimeout(() => {
        this.fetchPage();
      }, 500);
    }
  }

  handleImport(name, fieldmapsDefinition) {
    if(fieldmapsDefinition.name) {
      name = fieldmapsDefinition.name;
    } else {
      name = name.split('.')[0];
    }

    if(fieldmapsDefinition.fieldmaps) {
      fieldmapsDefinition = fieldmapsDefinition.fieldmaps;
    }

    this.props.importFieldmapGroup({ name, fieldmapsDefinition }, ({ id }) => {
      this.setState({ importing: false });
      this.props.history.push(`/fieldmap_group/${id}`);
    });
  }

  render() {
    return (
      <div className="container-fluid view-list">
        <div className="row">
          <div className="col-9">
            <h1>
              Fieldmap groups
              <ImportButton
                className={`float-right ${this.state.importing ? "loading" : ""}`}
                onImport={(name, data) => this.handleImport(name, data)}
                onError={() => alert('The file has invalid JSON format.')}
                onImporting={() => this.setState({ importing: true })}
              />
              <Link className="btn btn-primary float-right" to="/fieldmap_group/create">NEW</Link>
            </h1>
          </div>
          <div className="col-3">
            <Pagination
              onNext={() => this.onNext()}
              onPrev={() => this.onPrev()}
              contentRange={this.props.contentRange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-9">
            <CardList
              cards={this.props.items}
              render={entry => <FieldmapGroupCard key={entry.id} entry={entry} actions={{ list: false }} />}
              isLoading={this.props.isLoading}
            />
          </div>
          <div className="col-3">
            <Form
              onChange={(field, value) => this.updateQuery(field, value)}
              onSubmit={() => this.fetchPage()}
              values={this.props.query}
            >
              <FormInput
                name="q"
                label="Search:"
              />
              <FormSelect
                name="sort"
                label="Sort:"
                options={
                  [
                    { value: "-createdAt", content: "Creados recientemente" },
                    { value: "createdAt", content: "Created anteriormente" },
                    { value: "-updatedAt", content: "Actualizados recientemente" },
                    { value: "updatedAt", content: "Actualizados anteriormente" }
                  ]
                }
              />
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
