
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import connect from '/components/decorators/connect';
import decorate from '/components/decorators/decorate';
import Header from '/components/layout/Header';
import Menu from '/components/layout/Menu';
import MenuItem from '/components/layout/MenuItem';

import '/components/layers/AppLayer.scss';
import logoUrl from '/resources/assets/logo.png';

import ClientList from '/components/views/Client/ClientList';
import ClientUpdate from '/components/views/Client/ClientUpdate';
import ClientDelete from '/components/views/Client/ClientDelete';
import ClientCreate from '/components/views/Client/ClientCreate';

import DataSourceList from '/components/views/DataSource/DataSourceList';
import DataSourceView from '/components/views/DataSource/DataSourceView';
import DataSourceUpdate from '/components/views/DataSource/DataSourceUpdate';
import DataSourceCreate from '/components/views/DataSource/DataSourceCreate';
import DataSourceDelete from '/components/views/DataSource/DataSourceDelete';

import UserList from '/components/views/User/UserList';
import UserView from '/components/views/User/UserView';
import UserUpdate from '/components/views/User/UserUpdate';
import UserCreate from '/components/views/User/UserCreate';
import UserDelete from '/components/views/User/UserDelete';
import ResetPassword from '/components/views/User/ResetPassword';
import ChangeRoles from '/components/views/User/ChangeRoles';

import CountryList from '/components/views/Country/CountryList';
import CountryUpdate from '/components/views/Country/CountryUpdate';

import AppClientLayer from '/components/layers/AppClientLayer';

import NotFoundView from '/components/views/NotFoundView';

import { fetchClient } from '/actions/client';

import WelcomePanel from '/components/views/WelcomePanel';

@connect()
@decorate(withRouter)
export default class AppLayer extends Component {

  static mapStateToProps(globalState) {
    return {
      isMenuOpen: globalState.menu.isOpen,
      isUserLogged: globalState.user.isLogged,
      clientCode: globalState.client.code
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      logout: () => dispatch({ type: 'USER_LOGOUT' }),
      fetchClient: code => dispatch(fetchClient(code)),
      unselectClient: () => dispatch({ type: 'CLIENT_UNSELECT' }),
      selectClient: code => dispatch({ type: 'CLIENT_SELECT', code })
    };
  };

  componentDidMount() {
    if (this.props.hasOwnProperty('isUserLogged') && !this.props.isUserLogged) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.hasOwnProperty('isUserLogged') && !this.props.isUserLogged) {
      this.props.history.push('/login');
      return false;
    }
  }

  getClientCode(props = this.props) {
    return props.clientCode;
  }

  render() {
    return (
      <div id="outerContainer" className={"layer-app" + (this.props.isMenuOpen ? ' menu-open' : ' menu-closed')}>
        <Menu
          pageWraperId="pageWraper"
          outerContainerId="outerContainer"
          logo={logoUrl}
          logoAlt="Logo LimeSync"
          logoTo="/console"
        >
          {
            this.getClientCode() && (
              <Fragment>
                <MenuItem exact to="/console" text="Console" />
                <MenuItem to={`/console/client/${this.getClientCode()}/survey`} text="Survey" />
                <MenuItem to={`/console/client/${this.getClientCode()}/reports`} text="Reports" />
                <MenuItem to={`/console/client/${this.getClientCode()}/validator_group`} text="Validator Group" />
                <MenuItem to={`/console/client/${this.getClientCode()}/fieldmap_group`} text="Fieldmap Group" />
                <MenuItem to={`/console/client/${this.getClientCode()}/export`} text="Export" />
                <MenuItem to={`/console/client/${this.getClientCode()}/task`} text="Tasks" />
                <MenuItem to="/logout" text="Log out" onClick={this.props.logout} />
              </Fragment>
            )
          }
          {
            !this.getClientCode() && (
              <Fragment>
                <MenuItem to="/console/client" text="Client" />
                <MenuItem to="/console/data_sources" text="Data sources" />
                <MenuItem to="/console/users" text="Users" />
                <MenuItem to="/console/country" text="Countries" />
                <MenuItem to="/logout" text="Log out" onClick={this.props.logout} />
              </Fragment>
            )
          }
        </Menu>
        <main id="pageWraper">
          <Header toggleMenu={(bool) => null} />
          <Switch>
            <Route exact component={WelcomePanel} path="/console" />

            <Route exact component={ClientList} path="/console/client" />
            <Route exact component={ClientCreate} path="/console/client/create" />
            <Route exact component={ClientUpdate} path="/console/client/:clientCode/update" />
            <Route exact component={ClientDelete} path="/console/client/:clientCode/delete" />

            <Route exact component={UserList} path="/console/users" />
            <Route exact component={UserCreate} path="/console/user/create" />
            <Route exact component={UserView} path="/console/user/:id" />
            <Route exact component={UserUpdate} path="/console/user/:id/update" />
            <Route exact component={UserDelete} path="/console/user/:id/delete" />
            <Route exact component={ResetPassword} path="/console/user/:id/reset_password" />
            <Route exact component={ChangeRoles} path="/console/user/:id/edit_roles" />

            <Route exact component={DataSourceList} path="/console/data_sources" />
            <Route exact component={DataSourceCreate} path="/console/data_source/create" />
            <Route exact component={DataSourceView} path="/console/data_source/:id" />
            <Route exact component={DataSourceUpdate} path="/console/data_source/:id/update" />
            <Route exact component={DataSourceCreate} path="/console/data_source/:id/copy" />
            <Route exact component={DataSourceDelete} path="/console/data_source/:id/delete" />

            <Route exact component={CountryList} path="/console/country" />
            <Route exact component={CountryUpdate} path="/console/country/:id/update" />

            <Route component={AppClientLayer} path="/console/client/:clientCode" />
            <Route component={NotFoundView} path="*" />
          </Switch>
        </main>
      </div>
    );
  }
}
