
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import CRUDActions from '/components/ui/Actions/CRUDActions';

import { fetchDataSource } from '/actions/dataSource';
import Moment from '/components/ui/Moment';

@connect()
@decorate(withRouter)
export default class DataSourceView extends Component {

  static mapStateToProps(globalState) {
    return {
      entry: globalState.data_source.data,
      isLoading: globalState.data_source.isLoading
    }
  }

  static mapDispatchToProps = {
    fetch: fetchDataSource
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.id);
  }

  render() {
    if (this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <CRUDActions
          entry={this.props.entry}
          permissionEntity="datasource"
          entity="data_source"
          basePath={'/console'}
          view={false}
          list={false}
        />
        <h2>#{this.props.entry.id} - {this.props.entry.name}</h2>
        <div>
          <b>Name:</b> {this.props.entry.name}
        </div>
        <div>
          <b>Config</b>
        </div>
        {Object
          .entries(this.props.entry.config)
          .map(
            ([key, val]) => (
              <div key={key}>
                <b>&nbsp;&nbsp;{key}:</b> {val}
              </div>
            )
          )}
        <div>
          <b>Create at:</b> <Moment date={this.props.entry.createdAt} format="YYYY/MM/DD" />
        </div>
        <div>
          <b>Updated at:</b> <Moment date={this.props.entry.updatedAt} format="YYYY/MM/DD" />
        </div>
        <div>
          <b>Disabled: </b>{this.props.entry.isDisabled ? 'yes' : 'no'}
        </div>
        <div>
          <b>Notas:</b> {this.props.entry.notes}
        </div>
      </div>
    );
  }
}
