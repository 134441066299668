
import CRUDReducer from '/lib/CRUDReducer';
import CRUDState from '/lib/CRUDState';

export default {
  initialState: CRUDState({
    list: {
      query: {
        sort: 'code'
      }
    },

    code: null
  }),

  before: (state, action, handler) => {
    return handler(state, action);
  },

  actions: CRUDReducer('client', {
    INITIALIZE(state) {
      return { ...state }
    },

    CLIENT_SELECT(state, { code }) {
      return { ...state, code };
    },

    CLIENT_UNSELECT(state) {
      return { ...state, code: null };
    }
  })
}
