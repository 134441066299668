
export default {
  initialState: {
    username: '',
    password: '',
    remember: false,

    error: null,
    errorCode: ''
  },

  before: (state, action, handler) => {
    return handler(state, action);
  },

  mapStateToStore(state) {
    return {
      remember: state.remember
    }
  },

  actions: {
    INITIALIZE(state, { storedState: { login } }) {
      return { ...state, ...login };
    },
    LOGIN_SET(state, { field, value }) {
      return { ...state, [field]: value }
    },
    LOGIN_SUCCESS(state, { user }) {
      return {
        email: '',
        remember: false,

        errorMessage: ''
      }
    },
    LOGIN_FAIL(state, { error, code }) {
      return { ...state, error, errorCode: code }
    }
  }
}
