import React, { Component } from 'react';

import Icon from '/components/ui/Icon';
import Action from '/components/ui/Actions/Action';

export default props => (
  <Action label="Delete" { ...props } to={ props.to || `/${props.entity}/${props.id}/delete` }>
    <Icon icon="trash-alt" />
  </Action>
);
