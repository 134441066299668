
import React from 'react';

import Form, { FormSubmit, FormCheckbox } from '/components/ui/Forms';

const RegenerateTaskForm = ({ onSubmit, ...props }) => (
  <Form
    {...props}
    onSubmit={
      () => onSubmit('client:regenerateResponses', [
        props.client.code,
        ...(props.values.force ? ['--force'] : []),
      ])
    }
  >
    <FormCheckbox label="Force" name="force" />
    <FormSubmit>Run</FormSubmit>
  </Form>
)

export default RegenerateTaskForm