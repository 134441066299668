
import React from 'react';

export default ({ className, onImport, onImporting, onError, children }) => (
    <button
        children={children || 'IMPORT'}
        className={`btn btn-primary ${className}`}
        onClick={
            () => {
                if(!onImport) {
                    throw new Error('onImport is required.');
                }

                if(onImporting) {
                    onImporting();
                }

                const input = document.createElement('input');
                input.setAttribute("type", "file");
                input.setAttribute("accept", "application/json");
                input.addEventListener('input', async event => {
                    const file = event.target.files.item(0);
                    const reader = new FileReader();
                    reader.onload = event => {
                        let data = null;

                        try {
                            data = JSON.parse(event.target.result);
                        } catch(error) {
                            if(onError) {
                                onError(error);
                            } else {
                                throw error;
                            }
                        }

                        onImport(file.name, data);
                    }

                    reader.readAsText(file, "UTF-8");
                });

                input.click();
            }
        }
    />
)
