import React, { Component } from 'react';
import '/components/ui/Icon.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-free-solid';


class Icon extends Component {

  render() {
    return (
      <i className="icon">
        <FontAwesomeIcon { ...this.props } />
      </i>
    );
  }
}

export default Icon;
