
import React, { Component } from 'react';

import Card from '/components/ui/CardList/Card';
import CRUDActions from '/components/ui/Actions/CRUDActions';
import './TaskView.scss';

export default class CommandTaskCard extends Component {
  render() {
    const { entry, actions = {}, ...props } = this.props;

    return (
      <Card { ...props }>
        <CRUDActions entry={ entry } entity="task" create={ false } delete={ false } copy={ false } edit={ false } idField='_id' permissionEntity={ null } { ...actions } />
        <div>#{ entry._id }</div>
        <pre className="command-string">{ entry.data.args.join(' ') }</pre>
        <div>Status: { entry.status }</div>
      </Card>
    )
  }
}
