
import React, { Component } from 'react';
import connect from '/components/decorators/connect';

@connect()
export default class Breadcrumbs extends Component {

  static mapDispatchToProps() {
    return {
      setBreadcrumbs: breadcrumbs => dispatch({ type: 'BREADCRUMBS_SET', breadcrumbs })
    };
  }

  componentDidMount() {
    this.props.setBreadcrumbs(this.props.list);
  }

  render() {
    return null;
  }
}

export const withBreadcrumbs = () => Child => (
  @connect()
  class extends Component {

    static mapStateToProps(globalState) {
      return { breadcrumbs: globalState.breadcrumbs };
    }

    render() {
      const props = { ...this.props };
      delete props.dispatch;

      return <Child { ...props } />;
    }
  }
)
