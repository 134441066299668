
import React from 'react';

import Icon from '/components/ui/Icon';
import Action from '/components/ui/Actions/Action';

export default props => (
  <Action label="Reset password" { ...props } to={ props.to || `/${props.entity}/${props.id}/reset_password` }>
    <Icon icon="key" />
  </Action>
);
