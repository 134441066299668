import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import './Action.scss';

export default ({ onAction, to, label, entry, children, basePath = '' }) => (
  <Link className="action" aria-label={ label } to={ basePath + to } onClick={ onAction && (() => onAction(entry)) }>
  	{ children }
  </Link>
);
