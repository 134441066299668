
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import { deleteValidatorGroup, fetchValidatorGroup } from '/actions/validator_group';

@connect()
@decorate(withRouter)
export default class ValidatorGroupDelete extends Component {

  static mapStateToProps(globalState) {
    return {
      entry: globalState.validator_group.data,
      isLoading: globalState.validator_group.isLoading
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      fetch: id => dispatch(fetchValidatorGroup(id)),
      delete: (id, callback) => dispatch(deleteValidatorGroup(id, callback))
    }
  }

  delete() {
    this.props.delete(this.props.entry.id, () => {
      this.goBack();
    });
  }

  goBack() {
    this.props.history.goBack();
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.id);
  }

  render() {
    if(this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    if(this.props.entry.surveys.length > 0) {
      return (
        <div className="container">
          <h2>Delete validator group <b>#{ this.props.entry.id }</b></h2>
          <p>No es posible eliminar un grupo de validadores si este está asociado a surveys...</p>
          <div>
            <button className="btn" onClick={ () => this.goBack() }>Back</button>
          </div>
        </div>
      );
    }

    return (
      <div className="container">
        <h2>Delete validator group <b>#{ this.props.entry.id }</b></h2>
        <p>Está seguro de que quiere eliminar el grupo de validaciones <b>"{ this.props.entry.name }"</b>?</p>
        <div>
          <button className="btn" onClick={ () => this.delete() }>Eliminar</button>
          &nbsp;
          <button className="btn" onClick={ () => this.goBack() }>Cancelar</button>
        </div>
      </div>
    );
  }
}
