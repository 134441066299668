import React, { Component } from 'react';
import { FormObject, FormSelect, FormInput } from '/components/ui/Forms/index';
import FormContext from '/components/ui/Forms/FormContext';

export default class ConditionInput extends Component {
    render() {
        return (
            <FormObject name={this.props.name} onChange={condition => {
                if((condition.comparator === 'equals' || condition.comparator === 'notEquals') && typeof value !== 'string') {
                    condition.value = '';
                } else if(condition.comparator === 'empty' || condition.comparator === 'notEmpty') {
                    condition.value = null;
                } else if(condition.comparator === 'any' && !Array.isArray(condition.value)) {
                    condition.value = [];
                }

                return condition;
            }}>
                <FormInput name="field" label="Field"></FormInput>
                <FormSelect
                    name="comparator"
                    label="Comparator"
                    options={[
                        { value: 'equals', content: 'equals' },
                        { value: 'notEquals', content: 'not equals' },
                        { value: 'empty', content: 'empty' },
                        { value: 'notEmpty', content: 'not empty' },
                        { value: 'any', content: 'any of' },
                    ]}
                />
                <FormContext.Consumer>
                    {
                        ({ values: condition }) => {
                            if(condition.comparator === 'equals' || condition.comparator === 'notEquals') {
                                return <FormInput name="value" label="Value"></FormInput>;
                            } else if(condition.comparator === 'any') {
                                return (
                                    <FormSelect
                                        name="value"
                                        label="Values"
                                        isMulti
                                        canCreate
                                        options={[
                                            { label: 'Null', value: null },
                                            { label: 'Empty String', value: "" }
                                        ]}
                                    />
                                );
                            } else {
                                return null;
                            }
                        }
                    }
                </FormContext.Consumer>
            </FormObject>
        );
    }
}
