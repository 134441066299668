
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import { deleteFieldmap, fetchFieldmap } from '/actions/fieldmap';

@connect()
@decorate(withRouter)
export default class FieldmapDelete extends Component {

  static mapStateToProps(globalState) {
    return {
      entry: globalState.fieldmap.data,
      isLoading: globalState.fieldmap.isLoading
    }
  }

  static mapDispatchToProps = {
    fetch: fetchFieldmap,
    delete: deleteFieldmap
  }

  delete() {

    const fieldmapGroupId = this.props.match.params.fieldmapGroupId;
    
    this.props.delete(this.props.entry.id, () => {
      this.props.history.push(`/fieldmap_group/${fieldmapGroupId}`);
    });
  }

  goBack() {
    this.props.history.goBack();
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.fieldmapId);
  }

  render() {
    if (this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <h2>Delete fieldmap <b>#{this.props.entry.id}</b></h2>
        <p>Está seguro de que quiere eliminar el Fieldmap <b>"{this.props.entry.target}"</b>?</p>
        <div>
          <button className="btn" onClick={() => this.delete()}>Eliminar</button>
          &nbsp;
          <button className="btn" onClick={() => this.goBack()}>Cancelar</button>
        </div>
      </div>
    );
  }
}
