import React, { Component } from 'react';

import Loader from '/components/ui/Loader';

export default ({ cards, isLoading, emptyMessage, render = (element => element) }) => (
  <div className="card-list-container">
    {
      !isLoading && (
        cards && cards.length ?
          <div className="card-list">{ cards.map(render) }</div> :
          <div className="card-list empty">{ emptyMessage || "No items to show." }</div>
      )
    }
    { isLoading && <Loader /> }
  </div>
);
