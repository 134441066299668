import React, { Component } from 'react';
import FormGroup from './FormGroup';
import withForm from './withForm';

import ReactJson from 'react-json-view';

@withForm()
export default class FormJSON extends Component {

  handleChange(value) {
    if(this.props.readOnly) {
      return undefined;
    }

    return ({ updated_src: value }) => {
      this.props.onChange(this.props.parse ? JSON.stringify(value, null, 4) : value)
    }
  }

  render() {
    return (
      <FormGroup className={ this.props.groupClassName }>
        { this.props.label && <label className={ this.props.labelClassName } htmlFor={ this.props.name }>{ this.props.label }</label> }
        <div className={ this.props.inputClassName }>
          <ReactJson
            id={ this.props.name }
            className={ "form-control" + (this.props.size ? ' form-control-' + this.props.size : '') }
            onEdit={ this.handleChange() }
            onAdd={ this.handleChange() }
            onDelete={ this.handleChange() }
            src={ this.props.parse ? JSON.parse(this.props.value) : this.props.value }
            name={ false }
          />
        </div>
        { this.props.text && <small id={ this.props.name + '_small' } className="form-text text-muted">{ this.props.text }</small> }
      </FormGroup>
    );
  }
}
