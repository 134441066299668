
import React, { Component } from 'react';

import { orderBy } from 'lodash';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import CardList from '/components/ui/CardList/CardList';
import FieldmapCard from '/components/views/Fieldmap/FieldmapCard';

import CRUDActions from '/components/ui/Actions/CRUDActions';

import { fetchFieldmapGroup } from '/actions/fieldmap_group';

@connect()
@decorate(withRouter)
export default class FieldmapGroupView extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      entry: globalState.fieldmap_group.data,
      isLoading: globalState.fieldmap_group.isLoading
    }
  }

  static mapDispatchToProps = {
    fetch: fetchFieldmapGroup
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.id);
  }

  render() {
    if (this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <CRUDActions entry={this.props.entry} entity="fieldmap_group" permissionEntity="fieldmap" view={false} />
        <h1>Fieldmap group <b>{this.props.entry.name} (#{this.props.entry.id})</b></h1>
        <label>Description:</label>
        <p>{this.props.entry.description || "No description."}</p>
        <label>Fieldmaps:</label>
        <CardList
          cards={this.props.entry.fieldmaps && orderBy(this.props.entry.fieldmaps, 'FieldmapGroupsFieldmaps.order')}
          render={entry => <FieldmapCard key={entry.id} entry={entry} fieldmapGroupId={this.props.entry.id} actions={{ copy: false }} />}
          isLoading={this.props.isLoading}
        />
      </div>
    );
  }
}
