
import React from 'react';

import Icon from '/components/ui/Icon';
import Action from '/components/ui/Actions/Action';

export default props => (
  <Action label="Edit" { ...props } to={ props.to || `/${props.entity}/${props.id}/edit_roles` }>
    <Icon icon="user-shield" />
  </Action>
);
