
import React, { Component } from 'react';

import Card from '/components/ui/CardList/Card';
import CRUDActions from '/components/ui/Actions/CRUDActions';
import ReactCountryFlag from "react-country-flag";

export default class CountryCard extends Component {
  render() {
    const { entry, actions = {}, ...props } = this.props;

    return (
      <Card { ...props }>
        <CRUDActions
          entry={ entry }
          entity="country"
          create={ false }
          basePath={'/console'}
          { ...actions }
        />
        <div>#{ entry.id }</div>
        <div><b>Name:</b> { entry.name } <ReactCountryFlag svg code={ entry.code } /></div>
        <div><b>Code:</b> { entry.code }</div>
        <div><b>Number:</b> { entry.number }</div>
      </Card>
    )
  }
}
