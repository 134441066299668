
import React, { Component } from 'react';
import connect from '/components/decorators/connect';

export default () => Child => (
  @connect(globalState => ({ locale: globalState.i18n.locale }))
  class extends Component {
    render() {
      const props = { ...this.props };
      delete props.dispatch;

      return <Child { ...props } />;
    }
  }
)
