
import React from 'react';
import '/components/ui/Loader.scss';

export default ({ small }) => (
  <div className={`spinner ${small ? 'small' : ''}`}>
    <div className="bounce1"></div>
    <div className="bounce2"></div>
    <div className="bounce3"></div>
  </div>
)
