
import CRUDReducer from '/lib/CRUDReducer';
import CRUDState from '/lib/CRUDState';

export default {
  initialState: CRUDState(),

  before: (state, action, handler) => {
    return handler(state, action);
  },

  actions: CRUDReducer('validator', {
    INITIALIZE(state) {
      return { ...state }
    }
  })
}
