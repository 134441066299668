
import React, { Component } from 'react';

import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import { Link } from 'react-router-dom';

import { fetchClients } from '/actions/client';

import CardList from '/components/ui/CardList/CardList';
import SurveyCard from '/components/views/Client/ClientCard';

@connect()
export default class ClientList extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      entries: globalState.client.list.items,
      isLoading: globalState.client.list.isLoading
    }
  }

  static mapDispatchToProps = {
    fetch: fetchClients
  }

  componentDidMount() {
    this.props.fetch();
  }

  render() {
    return (
      <div className="container view-list client-list">
        <div className="row">
          <div className="col-12">
            <h1>
              Clients
              <Link className="btn btn-primary float-right" to="/console/client/create">NEW CLIENT</Link>
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <CardList
              cards={this.props.entries}
              render={(entry, i) => <SurveyCard key={i} entry={entry} />}
              isLoading={this.props.isLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}
