import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';

/**
 * Layers
 */
import AppLayer from '/components/layers/AppLayer';
import LoginLayer from '/components/layers/LoginLayer';

/**
 * Views
 */
import LoginView from '/components/views/LoginView';
import NotFoundView from '/components/views/NotFoundView';

export default ({ store }) => (
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="/console/:clientCode?" breadcrumb={[ "LimeSync", "Console" ]} component={AppLayer} />
        <Route path="/login">
          <LoginLayer>
            <Switch>
              <Route exact component={LoginView}         path="/login" />
              <Route exact component={NotFoundView} />
            </Switch>
          </LoginLayer>
        </Route>
        <Redirect from="/" to="/login" />
      </Switch>
    </Router>
  </Provider>
);
