
export default {
  login: {
    title: 'Please sign in',
    email: 'Email address',
    username: 'Username',
    password: 'Password',
    remember: 'Remember me',
    signIn: 'Sign in',
    forgot: 'Forgot your password?',
    reset: 'Reset password',
    back: 'Back to login'
  }
}
