import React, { Component } from 'react';

import Form, { FormInput, FormSubmit, FormSelect, FormValue } from '/components/ui/Forms';
import { withForm } from '/components/ui/Forms/index';

@withForm()
export default class ValueMapEdit extends Component {

  state = {
    oldKey: '',
    newKey: '',
    isDuplicated: false
  }

  handleRename(oldKey, newKey) {
    if(this.getValues().hasOwnProperty(newKey)) {
      this.setState({ oldKey, newKey, isDuplicated: true });
    } else {
      this.setState({ oldKey: '', newKey: '', isDuplicated: false });
      this.props.onChange(
        Object.keys(this.getValues())
          .reduce((acc, key) => {
            if(key === oldKey) {
              return { ...acc, [newKey]: this.getValues()[oldKey] };
            } else {
              return { ...acc, [key]: this.getValues()[key] };
            }
          }, {})
      );
    }
  }

  handleChange(key, value) {
    this.props.onChange(
      Object.keys(this.getValues())
        .reduce((acc, _key) => {
          if(key === _key) {
            return { ...acc, [_key]: value };
          } else {
            return { ...acc, [_key]: this.getValues()[_key] };
          }
        }, {})
    );
  }

  handleRemove(key) {
    this.props.onChange(
      Object.keys(this.getValues())
        .reduce((acc, _key) => {
          if(key === _key) {
            return acc;
          } else {
            return { ...acc, [_key]: this.getValues()[_key] };
          }
        }, {})
    );
  }

  handleAdd() {
    this.props.onChange({ ...this.getValues(), "": "" });
  }

  renderItem(key, index) {
    return (
      <div className="row" key={index}>
        <div className="col">
          <label for={`${key}[key]`}>Key: </label>
          <FormInput
            noForm
            name={`${key}[key]`}
            style={{ color: this.state.oldKey === key && this.state.isDuplicated ? 'red' : 'inherit' }}
            value={this.state.oldKey === key ? this.state.newKey : key}
            onChange={newKey => this.handleRename(key, newKey)}
          />
        </div>
        <div className="col">
          <label for={key}>Value: </label>
          <FormSelect
            name={key}
            value={key}
            canCreate
            options={[
              { label: 'Null', value: null },
              { label: 'Empty String', value: "" },
              { label: 'True', value: true },
              { label: 'False', value: false }
            ]}
            getCreatedValueOption={
              value => {
                if(!isNaN(value)) {
                  return { value: parseFloat(value), label: value };
                } else {
                  return { value, label: value }
                }
              }
            }
          />
        </div>
        <div className="col-2 text-center align-self-center">
          <button
            className="btn"
            onClick={() => this.handleRemove(key)}
            style={{ backgroundColor: '#fda9a9' }}
          >
            Remove
          </button>
        </div>
        <div className="col-12">
          <hr/>
        </div>
      </div>
    );
  }

  getValues() {
    return this.props.value || {};
  }

  render() {
    return (
      <Form
        values={this.getValues()}
        onChange={(key, value) => this.handleChange(key, value)}
      >
        <div className="row">
          <div className="col-10">
            <div style={{ fontSize: '18px' }}>Value map:</div>
          </div>
          <div className="col-2 text-center">
            <button
              className="btn"
              onClick={() => this.handleAdd()}
              style={{ backgroundColor: '#c2f98c' }}
            >
              Add
            </button>
          </div>
          <div className="col-12">
            <hr/>
            { Object.keys(this.getValues()).map((key, index) => this.renderItem(key, index)) }
          </div>
        </div>
      </Form>
    )
  }

}