
import React, { Component } from 'react';

import { orderBy } from 'lodash';

import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import FieldmapCard from '/components/views/Fieldmap/FieldmapCard';

import Form, { FormInput, FormTextarea, FormSubmit } from '/components/ui/Forms';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import Icon from '/components/ui/Icon';

import {
  updateFieldmapGroupField,
  fetchFieldmapGroup,
  updateFieldmapGroup
} from '/actions/fieldmap_group';

const FieldmapItem = SortableElement(FieldmapCard);

const FieldmapSortAction = SortableHandle(() => <Icon className="action" rotation={90} icon="exchange-alt" />);

const FieldmapList = SortableContainer(({ entries, fieldmapGroupId }) => (
  <div>
    {
      entries.map((entry, index) => (
        <FieldmapItem
          fieldmapGroupId={fieldmapGroupId}
          key={`item-${index}`}
          index={index}
          entry={entry}
          hideOptions
          actions={
            {
              permissionEntity: "fieldmap",
              children: <FieldmapSortAction />
            }
          }
        />
      ))
    }
  </div>
));

@connect()
@decorate(withRouter)
export default class FieldmapGroupUpdate extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      entry: globalState.fieldmap_group.data,
      isLoading: globalState.fieldmap_group.isLoading,
      submitting: globalState.fieldmap_group.updating,
      failSubmit: globalState.fieldmap_group.failUpdating
    }
  }

  static mapDispatchToProps = {
    fetch: fetchFieldmapGroup,
    updateField: updateFieldmapGroupField,
    update: updateFieldmapGroup
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.id);
  }

  reOrder(oldIndex, newIndex) {
    const fieldmaps = this.state.fieldmaps.slice();
    fieldmaps.splice(newIndex, 0, fieldmaps.splice(oldIndex, 1)[0]);

    this.setState({
      fieldmaps,
      moves: [
        ...this.state.moves,
        { oldIndex, newIndex }
      ]
    });
  }

  state = {
    fieldmaps: [],
    moves: []
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.entry && this.props.entry || prevProps.entry && this.props.entry && prevProps.entry.id !== this.props.entry.id) {
      this.setState({
        fieldmaps: orderBy(this.props.entry.fieldmaps, 'FieldmapGroupsFieldmaps.order')
      });
    }
  }

  save() {
    let orders = null;

    if (this.state.moves.length) {
      orders = this.state.fieldmaps.map((fieldmap, order) => ({ fieldmapId: fieldmap.id, order }));
    }

    this.props.update(orders);
  }

  render() {
    if (this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <h1>Update fieldmap group <b>{this.props.entry.name} (#{this.props.entry.id})</b></h1>
        <Form
          onChange={this.props.updateField}
          onSubmit={() => this.save()}
          values={this.props.entry || {}}
          submitting={this.props.submitting}
          failSubmit={this.props.failSubmit}
        >
          <FormInput name="name" label="Name:" />
          <FormTextarea name="description" label="Description:" />
          <label>Fieldmaps:</label>
          <FieldmapList
            fieldmapGroupId={this.props.match.params.id}
            lockAxis="y"
            entries={this.state.fieldmaps}
            onSortEnd={({ oldIndex, newIndex }) => this.reOrder(oldIndex, newIndex)}
            useDragHandle
          />
          <Link
            to={`/fieldmap_group/${this.props.entry.id}/fieldmap/create`}
            style={
              {
                border: '2px dashed #cccccc',
                padding: '30px 0px',
                textAlign: 'center',
                fontSize: '25px',
                color: '#cccccc',
                marginBottom: '10px',
                fontWeight: 'bold',
                display: 'block'
              }
            }
          >ADD</Link>
          <FormSubmit />
        </Form>
      </div>
    );
  }
}
