
import React, { Component } from 'react';
import connect from '/components/decorators/connect';

export default () => Child => (
  @connect(globalState => ({ user: globalState.user.data }))
  class extends Component {
    render() {
      const user = this.props.user;
      const props = { ...this.props };

      props.hasPermission = (...permissions) => {
        return permissions.reduce((res, permission) => res && user.permissions.includes(permission), true);
      }

      props.matchesPermission = regexPattern => {
        return user.permissions.find(permission => regexPattern.test(permission));
      }

      return <Child { ...props } />;
    }
  }
)
