
import React from 'react';
import withFormSubmit from './withFormSubmit';
import Icon from '../Icon';



const FormSubmit = props => (
  <button
    type="submit"
    className={`btn btn-primary ${props.submitting ? 'loading' : ''} ${props.failSubmit ? 'is-submit-error' : ''}`}
    onClick={props.onSubmit}>
      {props.children || 'Save'}
      {props.failSubmit && <Icon icon="times"/>}
    </button>
)

export default withFormSubmit()(FormSubmit);
