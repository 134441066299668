
import React, { Component } from 'react';

import './SurveyCreate.scss';
import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import Form, { FormInput, FormSubmit, FormDate, FormObject, FormSelect, FormCheckbox } from '/components/ui/Forms';
import CountryInput from '/components/views/Country/CountryInput';
import DataSourceInput from '/components/views/DataSource/DataSourceInput';
import ValidatorGroupInput from '/components/views/ValidatorGroup/ValidatorGroupInput';

import { createSurvey, updateSurveyField, copyFromSurvey, cleanSurveyData } from '/actions/survey';
import { fetchSurveysId, fetchDataSource, cleanDataSourceData } from '/actions/dataSource';

@connect()
@decorate(withRouter)
export default class SurveyCreate extends Component {

  state = { hideSearch: false }

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      entry: globalState.survey.data,
      isLoading: globalState.survey.isLoading,
      submitting: globalState.survey.creating,
      failSubmit: globalState.survey.failCreating,
      surveysIds: globalState.data_source.surveysIds,
      dataSource: globalState.data_source.data
    }
  }

  static mapDispatchToProps = {
    cleanData: cleanSurveyData,
    copyFrom: copyFromSurvey,
    updateField: updateSurveyField,
    create: createSurvey,
    fetchSurveysId: fetchSurveysId,
    fetchDataSource: fetchDataSource,
    cleanDataSourceData: cleanDataSourceData
  }

  componentDidMount() {
    if (this.props.match.params.surveyId) {
      this.props.copyFrom(this.props.match.params.surveyId);
    } else {
      this.props.cleanData();
    }

    this.clickListener = () => this.hideSurveysIdsResults();

    document.querySelector('body').addEventListener('click', this.clickListener);
  }

  hideSurveysIdsResults() {
    this.setState({ hideSearch: true });
  }

  componentWillUnmount() {
    if (this.clickListener) {
      document.querySelector('body').removeEventListener('click', this.clickListener);
    }
  }

  save() {
    this.props.create(entry => {
      this.props.history.push(`/survey/${entry.id}`);
    });
  }

  render() {
    let { surveysIds } = this.props;
    if (this.props.isLoading) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    if (typeof surveysIds === 'object' && !Array.isArray(surveysIds)) {
      surveysIds = [];
    }

    const foundSurveysIds = surveysIds
      ?.filter(({ sourceId, title }) => {
        const regexr = RegExp(`${this.props.entry.sourceSurveyId}`, 'i');
        return regexr.exec(sourceId) || regexr.exec(title);
      });

    const renderedSurveysIds = foundSurveysIds
      ?.map(
        ({ sourceId, title }, i) =>
          <div key={i}
            className="survey-id-helper-option"
            onClick={() => { this.props.updateField('sourceSurveyId', sourceId); this.hideSurveysIdsResults(); }}>{sourceId} - {title}</div>
      );

    return (
      <div className="container">
        <h1>Create new survey</h1>
        <Form
          onChange={(key, val) => {
            if (key === 'dataSourceId') {
              this.props.fetchSurveysId(val);
              if(val) {
                this.props.fetchDataSource(val);
              } else {
                this.props.cleanDataSourceData();
                this.props.updateField('sourceOptions', {});
              }
            }
            this.props.updateField(key, val);
          }}
          onSubmit={() => this.save()}
          values={this.props.entry || {}}
          submitting={this.props.submitting}
          failSubmit={this.props.failSubmit}
        >
          <div className="row">
            <div className="col-3">
            <CountryInput />
            </div>
            <div className="col-3">
              <DataSourceInput name="dataSourceId" />
            </div>
            <div className="col-6">
              <FormInput name="sourceSurveyId" label="Source Survey ID:" onChange={value => { this.setState({ hideSearch: false }); this.props.updateField('sourceSurveyId', value); return value; }} />
              <div className="input-survey-id-container" onClick={e => e.stopPropagation()}>
                <div style={!this.props.surveysIds || this.state.hideSearch || foundSurveysIds.length === 0 ? { display: 'none' } : {}} className="survey-id-helper-container">
                  {renderedSurveysIds}
                </div>
              </div>
            </div>
            <div className="col-6">
              <ValidatorGroupInput label="Primary Validation Group:" name="validatorGroupId" />
            </div>
            <div className="col-6">
              <ValidatorGroupInput label="Secondary Validation Group:" name="secondaryValidatorGroupId" />
            </div>
            <div className="col-3">
              <FormDate name="startDate" label="Start date:" dateFormat="YYYY-MM-DD" format="YYYY-MM-DD 00:00:00" />
            </div>
            <div className="col-3">
              <FormDate name="endDate" label="End date:" dateFormat="YYYY-MM-DD" format="YYYY-MM-DD 00:00:00" />
            </div>
          </div>
          <FormObject name="sourceOptions">
            <div className="row">
              {
                this.props.dataSource?.type === 'limesurvey' && (
                  <div className="col-6">
                    <FormCheckbox name="fetchParticipantAttributes" label="Fetch participant attributes" />
                  </div>
                )
              }
              {
                this.props.entry?.sourceOptions?.fetchParticipantAttributes && (
                  <div className="col-6">
                    <FormSelect
                      name="participantAttributeNames"
                      label="Participant attribute names:"
                      canCreate
                      isMulti
                    />
                    <div style={{ fontSize: '14px', color: '#666' }}>
                      <p style={{ marginBottom: '0px' }}>
                        Los atributos a ingresar son los indices de atributos.<br/>
                        Ejemplo: attribute_1, attribute_2, firstname, etc.<br/>
                        Estos indices de atributos se podrán obtener en el lime en <b>Participantes de la encuesta > Gestionar atributos</b>.
                      </p>
                      Los atributos fijos de las tablas de participantes son:
                      <ul style={{ paddingLeft: '2em' }}>
                        <li>firstname</li>
                        <li>lastname</li>
                        <li>email</li>
                        <li>emailstatus</li>
                        <li>token</li>
                        <li>language</li>
                        <li>validfrom</li>
                        <li>validuntil</li>
                      </ul>
                      <p>Los atributos definidos por el usuario que se componen por <b>"attribute"</b> seguido de <b>guión bajo</b> y <b>un número</b>.</p>
                    </div>
                  </div>
                )
              }
            </div>
          </FormObject>
          <FormSubmit />
        </Form>
      </div>
    );
  }
}
