import { routerReducer } from 'react-router-redux';
import { i18nReducer } from 'react-redux-i18n';

import reducerHandler from '/utils/reducerHandler';

import siteConfigReducer from '/reducers/siteConfig';
import breadcrumbsReducer from '/reducers/breadcrumbs';
import menuReducer from '/reducers/menu';
import loginReducer from '/reducers/login';
import userReducer from '/reducers/user';
import surveyReducer from '/reducers/survey';
import fieldmapGroupReducer from '/reducers/fieldmap_group';
import fieldmapReducer from '/reducers/fieldmap';
import reports from '/reducers/reports';
import validatorGroupReducer from '/reducers/validator_group';
import validatorTypesReducer from '/reducers/validator_types';
import datasourceTypesReducer from '/reducers/datasource_types';
import validatorReducer from '/reducers/validator';
import exportReducer from '/reducers/export';
import taskReducer from '/reducers/task';
import data_source from '/reducers/data_source';
import countryReducer from '/reducers/country';
import clientReducer from '/reducers/client';

export const initialState = {};

export default {
  routing: routerReducer,
  i18n: i18nReducer,
  siteConfig: reducerHandler(siteConfigReducer),
  breadcrumbs: reducerHandler(breadcrumbsReducer),
  menu: reducerHandler(menuReducer),
  login: reducerHandler(loginReducer),
  data_source: reducerHandler(data_source),
  user: reducerHandler(userReducer),
  survey: reducerHandler(surveyReducer),
  fieldmap_group: reducerHandler(fieldmapGroupReducer),
  fieldmap: reducerHandler(fieldmapReducer),
  reports: reducerHandler(reports),
  validator_group: reducerHandler(validatorGroupReducer),
  validator_types: reducerHandler(validatorTypesReducer),
  datasource_types: reducerHandler(datasourceTypesReducer),
  validator: reducerHandler(validatorReducer),
  export: reducerHandler(exportReducer),
  task: reducerHandler(taskReducer),
  country: reducerHandler(countryReducer),
  client: reducerHandler(clientReducer)
};
