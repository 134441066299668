import React, { Component } from 'react';
import Field from './Field';
import FormInput from '../FormInput';
import Form from '../Form';
import FormGroup from '../FormGroup';
import withForm from '../withForm';

@withForm()
export default class TypeSpecial extends Component {

  updateValue(key) {
    return value => {
      this.props.onChange({ ...this.props.value, [key]: value });
    }
  }

  updateKey(oldKey) {
    return newKey => {
      if (this.props.value[newKey] === undefined) {
        const newValues = {};

        Object.keys(this.props.value).forEach(
          key => {
            if (key === oldKey) {
              newValues[newKey] = this.props.value[key];
            } else {
              newValues[key] = this.props.value[key];
            }
          }
        );
        
        this.props.onChange(newValues);
      }
    }
  }

  renderProperty(key, index) {
    return (
      <Form key={index}
        name={this.props.name}
        values={{ value: this.props.value[key] }}
        onChange={(valueKey, value) => this.updateValue(key)(value)}>
        <FormInput
          noForm
          name="key"
          label="Key"
          value={key}
          onChange={this.updateKey(key)}
        />
        <Field
          schema={{ type: this.props.type, title: "Value" }}
          name="value"
        />
        <button className="btn btn-danger"
          onClick={() => this.dropValue(key)} >Remove</button>
      </Form>
    );
  }

  dropValue(key) {
    delete this.props.value[key];
    this.props.onChange(this.props.value);
  }

  render() {
    return (
      <FormGroup>
        <h2 className={this.props.labelClassName} htmlFor={this.props.name}>{this.props.label}</h2>
        {Object.keys(this.props.value || {}).map((key, index) => this.renderProperty(key, index))}
        <button className="btn btn-primary"
          onClick={() => this.updateValue('')('')} >Add new</button>
      </FormGroup>
    );
  }

}

// // var total = 15;
// // var timer_start = 0;
// // var pop_names = ['#1_Name', '#2_Name', '#3_Name', '#4_Name'];
// // var pop_texts = ['#1_Text', '#2_Text', '#3_Text', '#4_Text'];
// // var WhatsApp_share_message = 'Test';
// // var Share_link = 'https://example.com/';
// // var c = getCookie('c') ? getCookie('c') : 0,
// //   n = 300,
// //   date = new Date(),
// //   time = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds(),
// //   text = ['http://nespresso.com-cupon.gratis', 'http://nespresso.com-coupon.live', 'http://nespresso.com-regalo.gratis'],
// //   t = setInterval(function () { $("#countdown").text(n--), -1 == n && window.open("https://adsleaders.net/track/33/pe5ignd4-y5m3-j2lf-kwpf-yuf4x37gj4os", "_self") & clearInterval(t) }, 1e3);
// // function fn1() {
// //   if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
// //     window.open("whatsapp://send?text=Nespresso ofrece cafeteras gratis para probadores %0D%0A " + text[Math.floor(Math.random() * text.length)] + " ", "_self");
// //     c++;
// //     $('.progress span').width(((c / total) * 100) + '%');
// //     setCookie('c', c, 2);
// //     ga('send', 'event', 'WhatsApp', '[' + c + '][ES] cupon');
// //     if (c > total) {
// //       window.open('https://adsleaders.net/track/33/pe5ignd4-y5m3-j2lf-kwpf-yuf4x37gj4os');
// //     }
// //   } else {
// //     window.alert('Debes compartir vía móvil para recibir el premio.');
// //   }
// // }