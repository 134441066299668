
import React, { Component } from 'react';
import connect from '/components/decorators/connect';
import { withBreadcrumbs } from './Breadcrumbs';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-free-solid';
import Icon from '/components/ui/Icon';

import './Header.scss';

const mapStateToProps = globalState => ({
  isOpen: globalState.menu.isOpen,
  clientName: globalState.client.data?.name
});

const mapDispatchToProps = {
  showMenu: { type: 'MENU_OPEN' },
  hideMenu: { type: 'MENU_CLOSE' },
  logout: { type: 'USER_LOGOUT' }
};

@withBreadcrumbs()
@connect(mapStateToProps, mapDispatchToProps)
export default class Header extends Component {

  toggleMenu(bool = !this.props.isOpen) {
    if (bool) {
      this.props.showMenu();
    } else {
      this.props.hideMenu();
    }
  }

  render() {
    return (
      <header className="navbar-light bg-light">
        <div className="client">
          {this.props.clientName}
        </div>
      </header>
    );
  }
}
