
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';

import Form, { FormInput, FormSubmit } from '/components/ui/Forms';

import {
  createUser,
  updateUserField,
  cleanUserData
} from '/actions/user';

@connect()
@decorate(withRouter)
export default class UserCreate extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      entry: globalState.user.otherUser.data,
      submitting: globalState.user.creating,
      failSubmit: globalState.user.failCreating
    }
  }

  static mapDispatchToProps = {
    cleanData: cleanUserData,
    updateField: updateUserField,
    create: createUser
  }

  componentDidMount() {
    this.props.cleanData();
  }

  save() {
    if (this.props.entry.password === this.props.entry.confirmPassword) {
      this.props.create(entry => {
        this.props.history.push(`/console/user/${entry.id}`);
      });
    }
  }

  render() {

    return (
      <div className="container">
        <h1>Create new user</h1>
        <Form
          onChange={this.props.updateField}
          onSubmit={() => this.save()}
          values={this.props.entry || {}}
          submitting={this.props.submitting}
          failSubmit={this.props.failSubmit}
        >
          <FormInput name="name" label="Name:" />
          <FormInput name="email" label="Email:" />
          <FormInput name="password" autocomplete="new-password" type="password" label="Password:" />
          <FormInput name="confirmPassword" autocomplete="new-password" type="password" label="Confirm password:" />
          <FormSubmit />
        </Form>
      </div>
    );
  }
}
