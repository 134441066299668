
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';

import { FormSelect } from '/components/ui/Forms';
import Loader from '/components/ui/Loader';

import { fetchRoles, fetchUser, createUserRole, deleteUserRole } from '/actions/user';

@connect()
@decorate(withRouter)
export default class ChangeRoles extends Component {

  state = { roleSelected: undefined }

  static mapStateToProps(store) {
    return {
      user: store.user.data,
      roles: store.user.roles,
      entry: store.user.otherUser.data,
      isLoading: store.user.otherUser.isLoading
    }
  }

  static mapDispatchToProps = {
    fetchRoles,
    fetchUser,
    createUserRole,
    deleteUserRole
  }

  componentDidMount() {
    this.fetchUser();
    this.props.fetchRoles();
  }

  fetchUser() {
    this.props.fetchUser(this.props.match.params.id);
  }

  change(roleSelected) {
    if (roleSelected !== this.state.roleSelected) {
      this.props.createUserRole(this.props.entry.id, roleSelected, () => this.fetchUser());
    }
  }

  renderRole(role, key) {
    return (
      <li key={key}>{role}
        <input
          type="button"
          className="btn btn-danger"
          value='-'
          onClick={() => this.props.deleteUserRole(this.props.entry.id, role, () => this.fetchUser())}
        />
      </li>
    )
  }

  render() {
    if (this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <h1>Change user roles - <b>#{this.props.entry.id} {this.props.entry.name}</b></h1>
        <FormSelect
          name="newRole"
          noForm
          onChange={value => { this.change(value) }}
          value={this.state.roleSelected}
          options={(this.props.roles || [])
            .map(role => ({ value: role.name, content: role.name }))
            .filter(role => !this.props.entry.roles.includes(role.content))
          } />
        <p>Actual roles</p>
        <ul>
          {this.props.entry.roles?.map((role, key) => this.renderRole(role, key))}
        </ul>

      </div>
    );
  }
}
