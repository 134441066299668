
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import { deleteSurvey, fetchSurvey } from '/actions/survey';

@connect()
@decorate(withRouter)
export default class SurveyDelete extends Component {

  static mapStateToProps(globalState) {
    return {
      entry: globalState.survey.data,
      isLoading: globalState.survey.isLoading
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      fetch: id => dispatch(fetchSurvey(id)),
      delete: (id, callback) => dispatch(deleteSurvey(id, callback))
    }
  }

  delete() {
    this.props.delete(this.props.entry.id, () => {
      this.goBack();
    });
  }

  goBack() {
    this.props.history.goBack();
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.surveyId);
  }

  render() {
    if(this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <h2>Delete survey <b>#{ this.props.entry.id }</b></h2>
        <p>Está seguro de que quiere eliminar la survey { this.props.entry.id }?</p>
        <div>
          <button className="btn" onClick={ () => this.delete() }>Eliminar</button>
          &nbsp;
          <button className="btn" onClick={ () => this.goBack() }>Cancelar</button>
        </div>
      </div>
    );
  }
}
