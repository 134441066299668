
import React, { Component } from 'react';

import Card from '/components/ui/CardList/Card';
import CRUDActions from '/components/ui/Actions/CRUDActions';

export default class DataSourceCard extends Component {
  render() {
    const { entry, actions = {}, ...props } = this.props;

    return (
      <Card { ...props }>
        <CRUDActions entry={ entry } entity="data_source" basePath="/console" permissionEntity="datasource" create={ false } { ...actions } />
        <div>#{ entry.id }</div>
        <div><b>Name:</b> { entry.name }</div>
        <div><b>Host:</b> { entry.config.host }</div>
        <div><b>Path:</b> { entry.config.path }</div>
      </Card>
    )
  }
}
