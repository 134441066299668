import React from 'react';
import withForm from './withForm';
import FormInputBase from '/components/ui/Forms/FormInputBase';
import Select from 'react-select';
import Creatable from 'react-select/creatable';

@withForm()
export default class FormSelect extends FormInputBase {

  static get defaultProps() {
    return {
      default: undefined,
      placeholder: "Select an option..."
    }
  }

  handleChange(value) {
    if(this.props.isMulti && Array.isArray(value)) {
      this.props.onChange(value.map(o => o.value));
    } else if(value === null) {
      this.props.onChange(this.props.default);
    } else {
      this.props.onChange(value.value);
    }
  }

  handleCreate(value) {
    if(this.props.isMulti) {
      this.handleChange([ ...this.getValue(), this.getCreatedOptionFromValue(value) ]);
    } else {
      this.handleChange(this.getCreatedOptionFromValue(value));
    }
  }

  getCreatedOptionFromValue(value) {
    if(value === undefined || value === null) {
      return null;
    } else if(this.props.getCreatedValueOption) {
      return this.props.getCreatedValueOption(value);
    } else {
      return { value, label: value };
    }
  }

  getOptions() {
    return (this.props.options || []).map(
      option => ({
        value: option.value,
        label: option.label || option.content
      })
    );
  }

  getValue() {
    const options = this.getOptions();
    let value = this.props.value;

    if(this.props.isMulti) {
      if(!Array.isArray(value)) {
        value = [ value ];
      }

      return value.reduce((acc, val) => {
        let option = options.find(option => option.value === val);
        if(!option && this.props.canCreate) {
          option = this.getCreatedOptionFromValue(val);
        }

        return option ? [ ...acc, option ] : acc;
      }, []);
    } else {
      const option = options.find(option => option.value === value);
      if(!option && this.props.canCreate) {
        return this.getCreatedOptionFromValue(value);
      } else {
        return option;
      }
    }
  }

  renderInput() {
    const { name, canCreate, ...props } = this.props;
    const selectProps = {
      ...props,
      id: name,
      value: this.getValue(),
      options: this.getOptions(),
      onChange: value => this.handleChange(value),
      onCreateOption: canCreate ? value => this.handleCreate(value) : undefined
    };

    if(canCreate) {
      return <Creatable {...selectProps} />;
    } else {
      return <Select {...selectProps} />;
    }
  }
}
