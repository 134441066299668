
import CRUDReducer from '/lib/CRUDReducer';
import CRUDState from '/lib/CRUDState';

export default {
  initialState: CRUDState({
    list: {
      query: {
        sort: '-lastRunAt'
      }
    },

    statusRefreshRates: {
      'waiting': 5000,
      'running': 2000
    }
  }),

  before: (state, action, handler) => {
    return handler(state, action);
  },

  actions: CRUDReducer('task', {
    INITIALIZE(state) {
      return { ...state }
    }
  })
}
