
import React, { Component } from 'react';
import connect from '/components/decorators/connect';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import coverUrl from '/resources/assets/login_cover.png';
import { login } from '/actions/login';

import Form, { FormInput, FormSubmit } from '/components/ui/Forms';

class LoginView extends Component {
  render() {
    return (
      <Form className="form-signin" values={ this.props } onChange={ this.props.setField } onSubmit={ this.props.doLogin }>
        <img className="cover mb-4" src={ coverUrl } alt="" />
        <h1 className="h3 mb-3 font-weight-normal">{ I18n.t('login.title') }</h1>
        {this.props.errorCode && <p className="error">{ I18n.t(this.props.errorCode) }</p>}
        <FormInput name="email" type="email" placeholder={ I18n.t('login.email') } />
        <FormInput name="password" type="password" placeholder={ I18n.t('login.password') } />
        <FormSubmit>{ I18n.t('login.signIn') }</FormSubmit>
      </Form>
    );
  }
}

const mapStateToProps = (globalState) => {
  return {
    email: globalState.login.email,
    password: globalState.login.password,
    errorCode: globalState.login.errorCode
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setField: (field, value) => dispatch({ type: 'LOGIN_SET', field, value }),
    doLogin: () => dispatch(login())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
