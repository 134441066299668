
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import Form, { FormInput, FormTextarea, FormSubmit } from '/components/ui/Forms';

import {
  createFieldmapGroup,
  updateFieldmapGroupField,
  copyFromFieldmapGroup,
  cleanFieldmapGroupData
} from '/actions/fieldmap_group';

@connect()
@decorate(withRouter)
export default class UpdateCreate extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      entry: globalState.fieldmap_group.data,
      isLoading: globalState.fieldmap_group.isLoading,
      submitting: globalState.fieldmap_group.creating,
      failSubmit: globalState.fieldmap_group.failCreating
    }
  }

  static mapDispatchToProps = {
    cleanData: cleanFieldmapGroupData,
    copyFrom: (id, callback) => copyFromFieldmapGroup(id, {}, callback),
    updateField: updateFieldmapGroupField,
    create: createFieldmapGroup
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.copyFrom(this.props.match.params.id, entry => {
        this.props.history.push(`/fieldmap_group/${entry.id}`);
      });
    } else {
      this.props.cleanData();
    }
  }

  save() {
    this.props.create(entry => {
      this.props.history.push(`/fieldmap_group/${entry.id}/update`);
    });
  }

  render() {
    if (this.props.isLoading) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <h1>Create new fieldmap group</h1>
        <Form
          onChange={this.props.updateField}
          onSubmit={() => this.save()}
          values={this.props.entry || {}}
          submitting={this.props.submitting}
          failSubmit={this.props.failSubmit}
        >
          <FormInput name="name" label="Name:" />
          <FormTextarea name="description" label="Description:" />
          <FormSubmit />
        </Form>
      </div>
    );
  }
}
