
import React, { Component } from 'react';

class NotFoundView extends Component {
  render() {
    return <div>NotFoundView</div>;
  }
}

export default NotFoundView;
