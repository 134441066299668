
import React, { Component } from 'react';

import Form, { FormInput, FormSelect, FormSubmit } from '/components/ui/Forms';
import CountryInput from '/components/views/Country/CountryInput';

export default ({ onSubmit, ...props }) => (
  <Form
    { ...props }
    onSubmit={ 
      () => onSubmit('client:fetch', [
        props.client.code,
        '--country', props.values.country,
        ...(props.values.batchSize ? ['--batchSize', props.values.batchSize] : []),
        '--verbose'
      ])
    }
  >
    <CountryInput label="Country:" name="country" valueField="code" />
    <FormInput
      label="Batch size:"
      name="batchSize"
      placeholder="Default batch size is defined in each Lime Instance"
      type="number"
    />
    <FormSubmit>Run</FormSubmit>
  </Form>
)
