  
import React, { Component } from 'react';

import { orderBy } from 'lodash';

import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import ValidatorCard from '/components/views/Validator/ValidatorCard';

import Form, { FormInput, FormTextarea, FormSubmit } from '/components/ui/Forms';

import {SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import Icon from '/components/ui/Icon';

import {
  updateValidatorGroupField,
  fetchValidatorGroup,
  updateValidatorGroup
} from '/actions/validator_group';

const ValidatorItem = SortableElement(ValidatorCard);

const ValidatorSortAction = SortableHandle(() => <Icon className="action" rotation={90} icon="exchange-alt" />);

const ValidatorList = SortableContainer(({ entries }) => (
  <div>
    {
      entries.map((entry, index) => (
        <ValidatorItem
          key={ `item-${index}` }
          index={ index }
          entry={ entry }
          hideOptions
          actions={
            {
              permissionEntity: "validator",
              children: <ValidatorSortAction />
            }
          }
        />
      ))
    }
  </div>
));

@connect()
@decorate(withRouter)
export default class ValidatorGroupUpdate extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      entry: globalState.validator_group.data,
      isLoading: globalState.validator_group.isLoading,
      submitting: globalState.validator_group.updating,
      failSubmit: globalState.validator_group.failUpdating
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      fetch: id => dispatch(fetchValidatorGroup(id)),
      updateField: (field, value) => dispatch(updateValidatorGroupField(field, value)),
      update: orders => dispatch(updateValidatorGroup(orders))
    }
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.id);
  }

  reOrder(oldIndex, newIndex) {
    const validators = this.state.validators.slice();
    validators.splice(newIndex, 0, validators.splice(oldIndex, 1)[0]);

    this.setState({
      validators,
      moves: [
        ...this.state.moves,
        { oldIndex, newIndex }
      ]
    });
  }

  state = {
    validators: [],
    moves: []
  }

  componentDidUpdate(prevProps, prevState) {
    if(!prevProps.entry && this.props.entry || prevProps.entry && this.props.entry && prevProps.entry.id !== this.props.entry.id) {
      this.setState({
        validators: orderBy(this.props.entry.validators, 'ValidatorGroupsValidators.order')
      });
    }
  }

  save() {
    let orders = null;

    if(this.state.moves.length) {
      orders = this.state.validators.map((validator, order) => ({ validatorId: validator.id, order }));
    }

    this.props.update(orders);
  }

  render() {
    if(this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <h1>Update validator group <b>{ this.props.entry.name } (#{ this.props.entry.id })</b></h1>
        <Form
          onChange={ this.props.updateField }
          onSubmit={ () => this.save() }
          values={ this.props.entry || {} }
          submitting={this.props.submitting}
          failSubmit={this.props.failSubmit}
        >
          <FormInput name="name" label="Name:" />
          <FormTextarea name="description" label="Description:" />
          <label>Validators:</label>
          <ValidatorList
            lockAxis="y"
            entries={ this.state.validators }
            onSortEnd={ ({ oldIndex, newIndex }) => this.reOrder(oldIndex, newIndex) }
            useDragHandle
          />
          <Link
            to={ `/validator_group/${ this.props.entry.id }/validator/create` }
            style={
              {
                border: '2px dashed #cccccc',
                padding: '30px 0px',
                textAlign: 'center',
                fontSize: '25px',
                color: '#cccccc',
                marginBottom: '10px',
                fontWeight: 'bold',
                display: 'block'
              }
            }
          >ADD</Link>
          <FormSubmit />
        </Form>
      </div>
    );
  }
}
