
import { merge } from 'lodash';

export default (initialState = {}) => merge({
  list: {
    isLoading: false,
    items: [],
    page: 1,
    perPage: 10,
    total: Infinity,
    count: Infinity,
    query: {
      sort: 'id'
    },

    contentRange: {}
  },

  copyId: null,
  data: null,
  isLoading: false,
  id: null,
  error: null,
  creating: false,
  updating: false,
  failCreating: false,
  failUpdating: false

}, initialState);
