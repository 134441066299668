
import FinaleAccessor from '/lib/accessors/FinaleAccessor';
import CRUDActions from '/lib/CRUDActions';

export const DataSource = new FinaleAccessor('/api/v1', 'data_source');
export const DataSourceActions = CRUDActions(DataSource);

export const fetchDataSources = DataSourceActions.fetchDataSources;
export const fetchDataSource = DataSourceActions.fetchDataSource;
export const setDataSourceListQuery = DataSourceActions.setDataSourceListQuery;
export const updateDataSourceField = DataSourceActions.updateDataSourceField;
export const updateDataSource = DataSourceActions.updateDataSource;
export const copyFromDataSource = DataSourceActions.copyFromDataSource;
export const createDataSource = DataSourceActions.createDataSource;
export const cleanDataSourceData = DataSourceActions.cleanDataSourceData;
export const deleteDataSource = DataSourceActions.deleteDataSource;
export const fetchSurveysId = (datasourceId) => async (dispatch) => {
  const response = await DataSource.fetch(`/${datasourceId}/list_surveys`);

  const data = await response.json();

  dispatch({ type: 'DATASOURCE_FETCH_SURVEYSID', data });
};