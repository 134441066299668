import React, { Component } from 'react';
import withForm from './withForm';
import { FormGroup } from './index';

@withForm()
export default class FormCheckbox extends Component {
  render() {
    return (
      <FormGroup className="form-check">
        <input
          type="checkbox"
          id={ this.props.name }
          className="form-check-input"
          readOnly={ this.props.readOnly }
          onChange={ e => this.props.onChange(e.target.checked) }
          checked={ this.props.value }
        />
        { this.props.label && <label htmlFor={ this.props.name }>{ this.props.label }</label> }
        { this.props.text && <small id={ this.props.name + '_small' } className="form-text text-muted">{ this.props.text }</small> }
      </FormGroup>
    )
  }
}
