
import FinaleAccessor from '/lib/accessors/FinaleAccessor';
import CRUDActions from '/lib/CRUDActions';
import clientHooks from './clientHooks';

class ValidatorTypesAccessor extends FinaleAccessor {
  getContentRange() {}
}

export const DataSourceTypes = new ValidatorTypesAccessor('/api/v1', 'datasource_types');
export const DataSourceTypesActions = CRUDActions(DataSourceTypes, { hooks: clientHooks });

export const fetchAllDataSourcesTypes = DataSourceTypesActions.fetchAllDatasourceTypes;