
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import connect from '/components/decorators/connect';
import decorate from '/components/decorators/decorate';

import '/components/layers/AppLayer.scss';

import Loader from '/components/ui/Loader';

import SurveyList from '/components/views/Survey/SurveyList';
import SurveyView from '/components/views/Survey/SurveyView';
import SurveyUpdate from '/components/views/Survey/SurveyUpdate';
import SurveyDelete from '/components/views/Survey/SurveyDelete';
import SurveyCreate from '/components/views/Survey/SurveyCreate';

import ValidatorGroupList from '/components/views/ValidatorGroup/ValidatorGroupList';
import ValidatorGroupView from '/components/views/ValidatorGroup/ValidatorGroupView';
import ValidatorGroupUpdate from '/components/views/ValidatorGroup/ValidatorGroupUpdate';
import ValidatorGroupCreate from '/components/views/ValidatorGroup/ValidatorGroupCreate';
import ValidatorGroupDelete from '/components/views/ValidatorGroup/ValidatorGroupDelete';

import ValidatorView from '/components/views/Validator/ValidatorView';
import ValidatorCreate from '/components/views/Validator/ValidatorCreate';
import ValidatorUpdate from '/components/views/Validator/ValidatorUpdate';
import ValidatorDelete from '/components/views/Validator/ValidatorDelete';

import FieldmapGroupList from '/components/views/FieldmapGroup/FieldmapGroupList';
import FieldmapGroupView from '/components/views/FieldmapGroup/FieldmapGroupView';
import FieldmapGroupUpdate from '/components/views/FieldmapGroup/FieldmapGroupUpdate';
import FieldmapGroupCreate from '/components/views/FieldmapGroup/FieldmapGroupCreate';
import FieldmapGroupDelete from '/components/views/FieldmapGroup/FieldmapGroupDelete';

import FieldmapView from '/components/views/Fieldmap/FieldmapView';
import FieldmapCreate from '/components/views/Fieldmap/FieldmapCreate';
import FieldmapUpdate from '/components/views/Fieldmap/FieldmapUpdate';
import FieldmapDelete from '/components/views/Fieldmap/FieldmapDelete';

import ExportList from '/components/views/Export/ExportList';
import ExportView from '/components/views/Export/ExportView';

import TaskList from '/components/views/Task/TaskList';
import TaskRun from '/components/views/Task/TaskRun';
import TaskView from '/components/views/Task/TaskView';

import NotFoundView from '/components/views/NotFoundView';

import ClientReports from '/components/views/Client/ClientReports'

import { fetchClient } from '/actions/client';

@connect()
@decorate(withRouter)
export default class AppClientLayer extends Component {

  static mapStateToProps(globalState) {
    return {
      client: globalState.client.data,
      clientCode: globalState.client.code
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      fetchClient: code => dispatch(fetchClient(code)),
      unselectClient: () => dispatch({ type: 'CLIENT_UNSELECT' }),
      selectClient: code => dispatch({ type: 'CLIENT_SELECT', code })
    };
  };

  componentDidMount() {
    this.props.selectClient(this.getClientCode());
    this.props.fetchClient(this.getClientCode());
  }

  componentWillUnmount() {
    this.props.unselectClient();
  }

  getClientCode(props = this.props) {
    return props.match.params.clientCode;
  }

  isClientLoaded() {
    return this.props.clientCode === this.getClientCode();
  }

  render() {
    if (!this.isClientLoaded()) {
      return <Loader />;
    }

    return (
      <Router basename={`/console/client/${this.getClientCode()}`}>
        <Switch>
          <Redirect exact from="/" to="/survey" />
          <Route exact component={SurveyList} path="/survey" />
          <Route exact component={SurveyCreate} path="/survey/create" />
          <Route exact component={SurveyView} path="/survey/:surveyId" />
          <Route exact component={SurveyUpdate} path="/survey/:surveyId/update" />
          <Route exact component={SurveyCreate} path="/survey/:surveyId/copy" />
          <Route exact component={SurveyDelete} path="/survey/:surveyId/delete" />

          <Route exact component={ValidatorGroupList} path="/validator_group" />
          <Route exact component={ValidatorGroupCreate} path="/validator_group/create" />
          <Route exact component={ValidatorGroupView} path="/validator_group/:id" />
          <Route exact component={ValidatorGroupUpdate} path="/validator_group/:id/update" />
          <Route exact component={ValidatorGroupCreate} path="/validator_group/:id/copy" />
          <Route exact component={ValidatorGroupCreate} path="/validator_group/:id/copy" />
          <Route exact component={ValidatorGroupDelete} path="/validator_group/:id/delete" />

          <Route exact component={ValidatorCreate} path="/validator_group/:validatorGroupId/validator/create" />
          <Route exact component={ValidatorView} path="/validator_group/:validatorGroupId/validator/:validatorId" />
          <Route exact component={ValidatorUpdate} path="/validator_group/:validatorGroupId/validator/:validatorId/update" />
          <Route exact component={ValidatorCreate} path="/validator_group/:validatorGroupId/validator/:validatorId/copy" />
          <Route exact component={ValidatorDelete} path="/validator_group/:validatorGroupId/validator/:validatorId/delete" />

          <Route exact component={FieldmapGroupList} path="/fieldmap_group" />
          <Route exact component={FieldmapGroupCreate} path="/fieldmap_group/create" />
          <Route exact component={FieldmapGroupView} path="/fieldmap_group/:id" />
          <Route exact component={FieldmapGroupUpdate} path="/fieldmap_group/:id/update" />
          <Route exact component={FieldmapGroupCreate} path="/fieldmap_group/:id/copy" />
          <Route exact component={FieldmapGroupDelete} path="/fieldmap_group/:id/delete" />

          <Route exact component={FieldmapCreate} path="/fieldmap_group/:fieldmapGroupId/fieldmap/create" />
          <Route exact component={FieldmapView} path="/fieldmap_group/:fieldmapGroupId/fieldmap/:fieldmapId" />
          <Route exact component={FieldmapUpdate} path="/fieldmap_group/:fieldmapGroupId/fieldmap/:fieldmapId/update" />
          <Route exact component={FieldmapCreate} path="/fieldmap_group/:fieldmapGroupId/fieldmap/:fieldmapId/copy" />
          <Route exact component={FieldmapDelete} path="/fieldmap_group/:fieldmapGroupId/fieldmap/:fieldmapId/delete" />

          <Route exact component={ExportList} path="/export" />
          <Route exact component={ExportView} path="/export/:filename" />

          <Route exact component={TaskList} path="/task" />
          <Route exact component={TaskRun} path="/task/run" />
          <Route exact component={TaskView} path="/task/:id" />

          <Route exact component={ClientReports} path="/reports" />

          <Route exact component={NotFoundView} />
        </Switch>
      </Router>
    );
  }
}
