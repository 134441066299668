
export default {
  initialState: [],

  actions: {
    BREADCRUMBS_SET: (state, { breadcrumbs }) => {
      return breadcrumbs;
    }
  }
}
