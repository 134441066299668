const initialState = {
  list: {
    isLoading: false,
    items: []
  },

  session: '',
  isLogged: false,

  lang: '',

  data: {},
  otherUser: {},
  creating: false,
  updating: false,
  failCreating: false,
  failUpdating: false
}
export default {
  initialState,

  before: (state, action, handler) => {
    if (action.error && action.error.code === 'unauthorized') {
      state = { ...state, isLogged: false };
    }

    return handler(state, action);
  },

  mapStateToStore: (state) => {
    return {
      session: state.session,
      isLogged: state.isLogged,
      lang: state.lang,
      data: state.data,
    };
  },

  actions: {
    INITIALIZE(state, { storedState: { user } }) {
      return { ...state, ...user };
    },
    LOGIN_SUCCESS(state, { data, session }) {
      return { ...state, data, isLogged: true, session };
    },
    USER_LOGOUT(state) {
      return initialState;
    },
    USER_LOAD_LIST_START(state) {
      return { ...state, list: { ...state.list, isLoading: true, items: [] } }
    },
    USER_LOAD_LIST_SUCCESS(state, { users: items }) {
      return { ...state, list: { ...state.list, isLoading: false, items } }
    },
    USER_LOAD_LIST_FAIL(state, { error }) {
      return { ...state, list: { ...state.list, isLoading: false, error } }
    },
    USER_LOGGED_FETCH_SUCCESS(state, { data }) {
      return { ...state, data };
    },
    USER_LOGGED_FETCH_FAIL(state, { error }) {
      return {
        ...state,
        error,
        isLogged: state.isLogged && error.code !== 'unouthorized'
      };
    },
    USER_LIST_FETCH_START(state) {
      return { ...state, list: { ...state.list, isLoading: true, items: [] } };
    },
    USER_LIST_FETCH_SUCCESS(state, { users: items }) {
      return { ...state, list: { ...state.list, isLoading: false, items } };
    },
    USER_LIST_FETCH_FAIL(state, { error }) {
      return { ...state, error, list: { ...state.list, isLoading: false, items: [] } };
    },
    USER_FETCH_START(state) {
      return { ...state, otherUser: { ...state.otherUser, isLoading: true } };
    },
    USER_FETCH_SUCCESS(state, { data }) {
      return { ...state, otherUser: { ...state.otherUser, isLoading: false, data } };
    },
    USER_FETCH_FAIL(state, { error }) {
      return { ...state, error, otherUser: { ...state.otherUser, isLoading: false } };
    },
    USER_DELETE_START(state) {
      return { ...state, otherUser: { ...state.otherUser, isLoading: true } };
    },
    USER_DELETE_SUCCESS(state) {
      return { ...state, otherUser: { ...state.otherUser, isLoading: false, data: {} } };
    },
    USER_DELETE_FAIL(state, { error }) {
      return { ...state, error, otherUser: { ...state.otherUser, isLoading: false } };
    },
    USER_CLEAN_DATA(state) {
      return { ...state, otherUser: { ...state.otherUser, isLoading: false, data: {} } };
    },
    USER_RESET_PASSWORD_START(state) {
      return { ...state, otherUser: { ...state.otherUser, isLoading: true } }
    },
    USER_RESET_PASSWORD_SUCCESS(state) {
      return { ...state, otherUser: { ...state.otherUser, isLoading: false, password: '', confirmPassword: '' } }
    },
    USER_RESET_PASSWORD_FAIL(state, { error }) {
      return { ...state, error, otherUser: { ...state.otherUser, isLoading: false } }
    },
    USER_FETCH_ROLES_START(state) {
      return { ...state }
    },
    USER_FETCH_ROLES_SUCCESS(state, { roles }) {
      return { ...state, roles }
    },
    USER_FETCH_ROLES_FAIL(state, { error }) {
      return { ...state, error }
    },
    USER_UPDATE_FIELD(state, { field, value }) {
      return { ...state, failUpdating: false, failCreating: false, otherUser: { ...state.otherUser, data: { ...state.otherUser.data, [field]: value } } };
    },
    USER_UPDATE_START(state) {
      return { ...state, otherUser: { ...state.otherUser, updating: true, failUpdating: false, isLoading: true } };
    },
    USER_UPDATE_SUCCESS(state, { data }) {
      return { ...state, otherUser: { ...state.otherUser, updating: false, failUpdating: false, isLoading: false, data } };
    },
    USER_UPDATE_FAIL(state, { error }) {
      return { ...state, error, otherUser: { ...state.otherUser, updating: false, failUpdating: true, isLoading: false } };
    },
    USER_CREATE_START(state) {
      return { ...state, creating: false, failCreating: false }
    },
    USER_CREATE_SUCCESS(state) {
      return { ...state, creating: false, failCreating: false }
    },
    USER_CREATE_FAIL(state, { error }) {
      return { ...state, error, creating: false, failCreating: true }
    },
  }
}
