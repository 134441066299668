
import React, { Component } from 'react';

import Card from '/components/ui/CardList/Card';
import CRUDActions from '/components/ui/Actions/CRUDActions';
import DataSourceLink from '/components/views/DataSource/DataSourceLink';
import CountryLink from '/components/views/Country/CountryLink';

export default class SurveyCard extends Component {
  render() {
    const { entry, actions = {}, ...props } = this.props;

    return (
      <Card { ...props }>
        <CRUDActions entry={ entry } entity="survey" create={ false } { ...actions } />
        <div>#{ entry.id }</div>
        <div><b>Source Survey ID:</b> { entry.sourceSurveyId }</div>
        <div>
          <b>Data Source: </b>
          { entry.dataSource && <DataSourceLink entry={ entry.dataSource } /> }
        </div>
        <div>
          <b>Country: </b>
          { entry.country && <CountryLink entry={ entry.country } /> }
        </div>
      </Card>
    )
  }
}
