
export default [
  {
    name: 'fetch_query',
    async handler() {
      const { client: { code } } = this.getState();
      this.data.query.clientCode = code;
    }
  },
  {
    name: 'create_data',
    async handler() {
      const { client: { code } } = this.getState();
      this.data.data.clientCode = code;
    }
  }
]