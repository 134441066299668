import fetch from '/lib/fetch';
import React, { Component } from 'react';
import Loader from '/components/ui/Loader';
import queryParams from '/lib/queryParams';

export default class ResponsesCount extends Component {

  state = {
    count: 0,
    loading: true
  }

  async componentDidMount() {
    const filter = this.props.filter || {};
    const response = await fetch(`/api/v1/Response/${this.props.clientCode}/count?${queryParams(filter)}`);
    this.setState({ count: (await response.json()).count, loading: false });
  }

  render() {
    const { loading, count } = this.state;
    const label = this.props.label ? this.props.label + ' ' : '';

    return <span className="responses-count">{label}{loading ? <Loader small /> : count}</span>;
  }

}