
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import CRUDActions from '/components/ui/Actions/CRUDActions';

import Form, { FormSubmit, FormCheckbox } from '/components/ui/Forms';

import { fetchCountry, updateCountry, updateCountryField } from '/actions/country';

@connect()
@decorate(withRouter)
export default class CountryUpdate extends Component {

  static mapStateToProps(globalState) {
    return {
      entry: globalState.country.data,
      isLoading: globalState.country.isLoading,
      submitting: globalState.country.updating,
      failSubmit: globalState.country.failUpdating
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      fetch: id => dispatch(fetchCountry(id)),
      updateField: (field, value) => dispatch(updateCountryField(field, value)),
      update: () => dispatch(updateCountry())
    }
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.id);
  }

  render() {
    if(this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <CRUDActions entry={ this.props.entry } entity="country" edit={ false } view={ false } delete={ false } copy={ false } />
        <h2>Update country <b>{ this.props.entry.name } (#{ this.props.entry.id })</b></h2>
        <Form
          onChange={ this.props.updateField }
          onSubmit={ this.props.update }
          values={ this.props.entry }
          submitting={this.props.submitting}
          failSubmit={this.props.failSubmit}
        >
          <div>
            <label>ISO Code:</label> { this.props.entry.code }
          </div>
          <div>
            <label>ISO Number:</label> { this.props.entry.number }
          </div>
          <FormCheckbox name="isEnabled" label="Enable" />
          <FormSubmit />
        </Form>
      </div>
    );
  }
}
