
import React, { Component } from 'react';

import Card from '/components/ui/CardList/Card';
import CRUDActions from '/components/ui/Actions/CRUDActions';

import prettyBytes from 'pretty-bytes';

export default class ExportCard extends Component {
  render() {
    const { entry, actions = {}, ...props } = this.props;

    return (
      <Card { ...props }>
        <CRUDActions entry={ entry } entity="export" create={ false } { ...actions } />
        <div>
          <b>File:</b>
          &nbsp;
          <a href={ `/api/v1/export/${ entry.filename }/download` } download={ entry.filename }>{ entry.filename }</a>
          &nbsp;
          ({ prettyBytes(entry.length) })
        </div>
        <div><b>md5:</b> { entry.md5 }</div>
      </Card>
    )
  }
}
