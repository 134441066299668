import React from 'react';
import withForm from './withForm';
import FormInputBase from '/components/ui/Forms/FormInputBase';

@withForm()
export default class FormInput extends FormInputBase {

  componentDidMount(){
    if(this.props.triggerDefaultValue){
      this.props.onChange(this.props.value)
    }
  }
  
  renderInput() {
    return (
      <input
        id={ this.props.name }
        type={ this.props.type }
        style={ this.props.style }
        className={ "form-control" + (this.props.size ? ' form-control-' + this.props.size : '') }
        placeholder={ this.props.placeholder }
        aria-describedby={ this.props.placeholder }
        readOnly={ this.props.readOnly }
        onChange={ e => this.props.onChange(e.target.value) }
        value={ this.props.value }
        autoComplete={this.props.autoComplete}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
      />
    );
  }
}
