
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';

import ValidatorEdit from '/components/views/Validator/ValidatorEdit';

import {
  createValidator,
  updateValidatorField
} from '/actions/validator';
import { fetchAllValidatorTypes } from '/actions/validator_types';

@connect()
@decorate(withRouter)
export default class SurveyCreate extends Component {

  static mapStateToProps(globalState) {
    return {
      entry: globalState.validator.data,
      types: globalState.validator_types.list.items,
      submitting: globalState.validator.creating,
      failSubmit: globalState.validator.failCreating
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateField: (field, value) => dispatch(updateValidatorField(field, value)),
      fetchTypes: () => dispatch(fetchAllValidatorTypes()),
      create: (validatorGroupId, callback) => dispatch(createValidator(callback, validatorGroupId))
    }
  }

  componentDidMount() {
    this.props.fetchTypes();
    this.props.updateField('options', {});
  }

  save() {
    const validatorGroupId = this.props.match.params.validatorGroupId;
    this.props.create(validatorGroupId, entry => {
      this.props.history.push(`/validator_group/${validatorGroupId}/validator/${entry.id}`);
    });
  }

  render() {
    return (
      <div className="container">
        <h2>Create validator</h2>
        <ValidatorEdit
          onChange={this.props.updateField}
          onSubmit={() => this.save()}
          values={this.props.entry || { options: '{}' }}
          name={false}
          types={this.props.types}
          submitting={this.props.submitting}
          failSubmit={this.props.failSubmit}
        />
      </div>
    );
  }
}
