
import CRUDReducer from '/lib/CRUDReducer';
import CRUDState from '/lib/CRUDState';

export default {
  initialState: CRUDState(),

  before: (state, action, handler) => {
    return handler(state, action);
  },

  actions: CRUDReducer('fieldmap', {
    INITIALIZE(state) {
      return { ...state }
    },

    FIELDMAP_LOAD_DATA(state, { data }) {
      return {
        ...state,
        isLoading: false,
        isRefreshing: false,
        data
      };
    },
  })
}
