import React, { Component } from 'react';
import { FormSelect } from '/components/ui/Forms';

import connect from '/components/decorators/connect';

import { ValidatorGroup } from '/actions/validator_group';

@connect()
export default class ValidatorGroupInput extends Component {

  state = {
    options: []
  }

  static mapStateToProps(globalState) {
    return {
      clientCode: globalState.client.code
    }
  }

  static get defaultProps() {
    return {
      label: 'Validator group:',
      valueField: 'id',
      contentField: 'name',
      name: 'validatorGroupId',
      placeholder: '',
      clientCode: ''
    }
  }

  componentWillMount() {
    this.load = true;
  }

  componentWillUnmount() {
    this.load = false;
  }

  async componentDidMount() {
    const { clientCode } = this.props;
    const { data: validatorGroups } = await ValidatorGroup.fetchAll({ clientCode }, 'name')

    if(!this.load) {
      return;
    }

    this.setState({
      options: validatorGroups.map(validatorGroup => ({
        value: validatorGroup[this.props.valueField],
        content: validatorGroup[this.props.contentField]
      }))
    })
  }

  render() {
    return (
      <FormSelect
        { ...this.props }
        placeholder="Select a validator group..."
        options={this.state.options}
      />
    );
  }
}
