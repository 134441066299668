import { I18n, loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';

import moment from 'moment';

import es from '/resources/dictionaries/es';
import en from '/resources/dictionaries/en';

const defaultLanguage = 'es';
const dictionary = { es, en };

const DEBUG_TRANSLATIONS = true;

I18n.setHandleMissingTranslation((key, replacements) => {
  if(DEBUG_TRANSLATIONS) {
    console.warn(`Missing translation for key: ${key}`);
  }

  return '';
});

export default (store) => {
  const { user: { lang: userLang }, siteConfig: { lang: siteConfigLang } } = store.getState();
  console.log('[I18n]', userLang, siteConfigLang, defaultLanguage);
  const lang = userLang || siteConfigLang || defaultLanguage;

  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(dictionary));
  store.dispatch(setLocale(lang));
  moment.locale(lang);
}
