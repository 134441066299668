
import React, { Component } from 'react';

import Icon from '/components/ui/Icon';
import Action from '/components/ui/Actions/Action';

export default props => (
  <Action label="List" { ...props } to={ props.to || ('/' + props.entity) }>
    <Icon icon="list" />
  </Action>
);
