
export default async (uri, options = {}) => {
  if(typeof path === 'object') {
    options = path;
    uri = options.uri || '';
  }

  return await fetch(uri, {
    credentials: 'include',
    method: 'GET',
    ...options
  });
}
