import ConsoleError from '/error/ConsoleError';
import Accessor from '/lib/accessors/Accessor';
import queryParams from '/lib/queryParams';

export const Report = new Accessor('/api/v1', 'report');

export const fetchReport = () => {
  return async (dispatch, getState) => {
    dispatch({ type: 'REPORT_FETCH_START' });

    const { client: { code }, reports: { query: { type, countryCode, yearMonth, store } } } = getState();
    const queryParam = {
      countryCode,
      year: yearMonth.split('-')[0],
      month: yearMonth.split('-')[1],
      store: store || undefined
    };
    try {
      const response = await Report.fetch(`/${code}/${type}?${queryParams(queryParam)}`, { credentials: 'include' });
      if (response.status === 401) {
        throw new ConsoleError('unouthorized', 'fetching report');
      }
      const results = await response.json();
      dispatch({ type: 'REPORT_FETCH_SUCCESS', results });
    } catch (error) {
      dispatch({ type: 'REPORT_FETCH_FAIL', error });
    }
  }
}
export const updateReportQueryField = (field, value) => ({ type: 'REPORT_QUERY_UPDATE', field, value });