import React from 'react';
import Form from '../Form';
import Field from './Field';

export default (props) => (
  <Form
    values={props.value || {}}
    name={props.label}
    onChange={(key, value) => props.onChange({ ...props.value, [key]: value })}>
    <h2 className={props.labelClassName} htmlFor={props.name}>{props.label}</h2>
    {
      Object.keys(props.properties).map(
        key => (
          <Field
            key={key}
            name={key}
            schema={props.properties[key]}
          />
        )
      )
    }
  </Form>
);