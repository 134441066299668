import React, { Component } from 'react';

import Card from '/components/ui/CardList/Card';
import CRUDActions from '/components/ui/Actions/CRUDActions';
import ResetPasswordAction from '/components/views/User/ResetPasswordAction';
import ChangeRolesAction from '/components/views/User/ChangeRolesAction';

export default class UserCard extends Component {
  render() {
    const { entry, actions = {}, ...props } = this.props;

    return (
      <Card {...props}>
        <CRUDActions
          entry={this.props.entry}
          entity="user"
          copy={false}
          basePath={'/console'}
          create={false}
          list={false}
          aditionalActions={[ResetPasswordAction, ChangeRolesAction]}
          {...actions}
        />
        <div>#{entry.id}</div>
        <div><b>Email:</b> {entry.email}</div>
        <div><b>Name:</b> {entry.name}</div>
        <div><b>Roles:</b> {entry.roles.reduce((preview, actual) => preview + ' ' + actual, '')}</div>
      </Card>
    )
  }
}
