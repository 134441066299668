
import React, { Component } from 'react';
import Form, { FormSelect } from '/components/ui/Forms';
import { runCommand } from '/actions/task';

import connect from '/components/decorators/connect';

import ProcessTaskForm from './forms/ProcessTaskForm';
import FetchTaskForm from './forms/FetchTaskForm';
import { ExportToDBForm, ExportToEmailForm } from './forms/ExportTaskForm';
import withUser from '../../decorators/withUser';
import RegenerateTaskForm from './forms/RegenerateTaskForm';

@withUser()
@connect()
export default class TaskRun extends Component {

  static mapStateToProps(globalState) {
    return {
      client: globalState.client.data
    }
  }

  state = {
    command: null,
    commandArgs: {}
  }

  onRun(command, args) {
    runCommand(this.props.client.code, command, args, task => {
      this.props.history.push(`/task/${ task._id }`);
    });
  }

  getCommandOptions() {
    const commandOptions = [];

    if(this.props.hasPermission('command:client:fetch')) {
      commandOptions.push({ value: "fetch", content: "Fetch responses" });
    }

    if(this.props.matchesPermission(/^command:client:process/)) {
      commandOptions.push({ value: "process", content: "Process responses" });
    }

    if(this.props.hasPermission('command:client:export:db')) {
      commandOptions.push({ value: "export:db", content: "Export" });
    }

    if(this.props.hasPermission('command:client:export:email')) {
      commandOptions.push({ value: "export:email", content: "Export to Email" });
    }

    return commandOptions;
  }

  render() {
    return (
      <div className="container">
        <h1>Create new task</h1>
        <Form values={ this.state } onChange={ (field, value) => this.setState({ [field]: value }) }>
          <FormSelect
            name="command"
            label="Task:"
            options={this.getCommandOptions()}
          />
        </Form>
        {
          this.state.command === 'fetch' && (
            <FetchTaskForm
              client={ this.props.client }
              values={ this.state.commandArgs }
              onSubmit={ (command, args) => this.onRun(command, args) }
              onChange={ (field, value) => this.setState({ commandArgs: { ...this.state.commandArgs, [field]: value } }) }
            />
          )
        }
        {
          this.state.command === 'process' && (
            <ProcessTaskForm
              client={ this.props.client }
              values={ this.state.commandArgs }
              onSubmit={ (command, args) => this.onRun(command, args) }
              onChange={ (field, value) => this.setState({ commandArgs: { ...this.state.commandArgs, [field]: value } }) }
            />
          )
        }
        {
          this.state.command === 'export:db' && (
            <ExportToDBForm
              client={ this.props.client }
              values={ this.state.commandArgs }
              onSubmit={ (command, args) => this.onRun(command, args) }
              onChange={ (field, value) => this.setState({ commandArgs: { ...this.state.commandArgs, [field]: value } }) }
            />
          )
        }
        {
          this.state.command === 'export:email' && (
            <ExportToEmailForm
              client={ this.props.client }
              values={ this.state.commandArgs }
              onSubmit={ (command, args) => this.onRun(command, args) }
              onChange={ (field, value) => this.setState({ commandArgs: { ...this.state.commandArgs, [field]: value } }) }
            />
          )
        }
        {
          this.state.command === 'regenerate' && (
            <RegenerateTaskForm
              client={ this.props.client }
              values={ this.state.commandArgs }
              onSubmit={ (command, args) => this.onRun(command, args) }
              onChange={ (field, value) => this.setState({ commandArgs: { ...this.state.commandArgs, [field]: value } }) }
            />
          )
        }
      </div>
    );
  }
}
