
import React, { Component } from 'react';

import Card from '/components/ui/CardList/Card';
import CRUDActions from '/components/ui/Actions/CRUDActions';

export default class ValidatorCard extends Component {
  render() {
    const { entry, actions = {}, hideOptions, validatorGroupId, ...props } = this.props;

    return (
      <Card { ...props }>
        <CRUDActions
          entry={ this.props.entry }
          entity="validator"
          basePath={ '/validator_group/' + validatorGroupId }
          create={ false }
          list={ false }
          { ...actions }
        />
        <div>#{ entry.id }</div>
        <div><b>Name:</b> { entry.name }</div>
        <div><b>Message:</b> { entry.message }</div>
        <div><b>Type:</b> { entry.type }</div>
        {
          !hideOptions && (
            <div>
              <b>Options:</b>
              <pre>{ JSON.stringify(entry.options, null, 4) }</pre>
            </div>
          )
        }
      </Card>
    )
  }
}
