
export default {
  login: {
    title: 'Por favor, inicie sesión',
    email: 'Email',
    username: 'Usuario',
    password: 'Contraseña',
    remember: 'Recordame',
    signIn: 'Ingresar',
    forgot: 'Olvidé mi contraseña',
    reset: 'Recuperar contraseña',
    back: 'Volver a inicio de sesión',
    error: {
      'invalidData': 'Usuario o contraseña invalido.',
      'unknown': 'Ocurrió un error inesperado, por favor intente de nuevo mas tarde.'
    }
  },
  dashboard: {
    dateSeparator: {
      today: 'Hoy',
      lastDay: 'Ayer',
      lastDays: 'Hace %{days} dias'
    }
  }
}
