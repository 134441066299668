import React, { Component } from 'react';

//import { Response } from '/actions/response';

import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';

import fetch from '/lib/fetch';
import moment from 'moment';

export default class ResponsesChart extends Component {

  static get defaultProps() {
    return {
      filter: {}
    }
  }

  state = {
    by: 'day',
    data: []
  }

  componentWillMount() {
    this.load = true;
  }

  componentWillUnmount() {
    this.load = false;
  }

  get paramsforMomentSubstract() {
    return {
      hour: [7, 'days'],
      day: [1, 'month'],
      month: [1, 'year']
    }
  }

  getFilter() {
    const answeredAt = {
      $lt: { $date: moment().format('YYYY-MM-DDThh:mm:ss.000Z') },
      $gte: { $date: null }
    };

    answeredAt.$gte.$date = moment()
      .subtract(...this.paramsforMomentSubstract[this.state.by])
      .format('YYYY-MM-DDThh:00:00.000Z');

    return {
      answeredAt,
      ...this.props.filter
    }
  }

  async componentDidMount() {
    const response = await fetch(`/api/v1/Response/${this.props.clientCode}/count`, {
      method: 'post',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({
        filter: this.getFilter(),
        by: this.state.by
      })
    });

    const data = await response.json();

    if (this.load) {
      this.setState({ data });
    }
  }

  render() {
    return (
      <ResponsiveContainer height={100}>
        <BarChart data={this.state.data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" hide />
          <YAxis hide />
          <Tooltip />
          <Bar dataKey="count" fill="#a1e260" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
