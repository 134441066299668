
import React, { Component } from 'react';

import './Pagination.scss';

export default class Pagination extends Component {

  getFrom() {
    return this.props.contentRange.from + 1;
  }

  getTo() {
    return this.props.contentRange.to + 1;
  }

  getTotal() {
    return this.props.contentRange.total;
  }

  canGoPrev() {
    return this.getFrom() > 1;
  }

  canGoNext() {
    return this.getTo() < this.getTotal();
  }

  isLoaded() {
    return this.props.contentRange && this.props.contentRange.total;
  }

  render() {
    return (
      <div className="pagination">
        <button className="btn btn-primary prev" onClick={ this.props.onPrev } disabled={ !this.isLoaded() || !this.canGoPrev() }>
          Prev
        </button>
        <button className="btn btn-primary next" onClick={ this.props.onNext } disabled={ !this.isLoaded() || !this.canGoNext() }>
          Next
        </button>
        {
          this.isLoaded() && (
            <span className="content-range">
              { this.getFrom() }-{ this.getTo() }/{ this.getTotal() }
            </span>
          )
        }
        {
          !this.isLoaded() && (
            <span className="content-range">
              -/-
            </span>
          )
        }
      </div>
    );
  }

}
