import React, { Component } from 'react';
import { FormSelect } from '/components/ui/Forms';

import { DataSource } from '/actions/dataSource';

export default class DataSourceInput extends Component {

  state = {
    options: []
  }

  static get defaultProps() {
    return {
      label: 'Data source:',
      valueField: 'id',
      contentField: 'name',
      name: 'dataSourceId',
      placeholder: ''
    }
  }

  componentWillMount() {
    this.load = true;
  }

  componentWillUnmount() {
    this.load = false;
  }

  async componentDidMount() {
    const { data: dataSources } = await DataSource.fetchAll({}, 'name');

    if(!this.load) {
      return;
    }

    this.setState({
      options: dataSources.map(dataSource => ({
        value: dataSource[this.props.valueField],
        content: dataSource[this.props.contentField]
      }))
    })
  }

  render() {
    return (
      <FormSelect
        { ...this.props }
        default="*undefined*"
        options={ [ { value: undefined, content: this.props.placeholder }, ...this.state.options ] }
      />
    );
  }
}
