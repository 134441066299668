import React, { Component } from 'react';

import FormContext from './FormContext';

export default () => Child => (
  class WithFormSubmit extends Component {

    handleSubmit(context, event) {
      if(this.props.onChange) {
        this.props.onChange(event);
      }

      if(context) {
        context.handleSubmit(event);
      }
    }

    getChildProps(context) {
      return {
        ...this.props,
        onSubmit: e => context.handleSubmit(e),
        submitting: context.submitting,
        failSubmit: context.failSubmit,
      }
    }
  
    render() {
      return (
        <FormContext.Consumer>
          {
            context => (
              <Child { ...this.getChildProps(context) } />
            )
          }
        </FormContext.Consumer>
      );
    }
  }
)
