
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import CRUDActions from '/components/ui/Actions/CRUDActions';

import Form, { FormInput, FormSubmit, FormJSON, FormSwitch } from '/components/ui/Forms';

import { updateClient, fetchClient, updateClientField } from '/actions/client';
import { FormObject, FormSelect, FormCheckbox } from '/components/ui/Forms/index';

import './ClientUpdate.scss';
import ConditionInput from './Config/ConditionInput';

@connect()
@decorate(withRouter)
export default class ClientUpdate extends Component {

  state = { currentTab: 'responses' };

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      entry: globalState.client.data,
      isLoading: globalState.client.isLoading,
      submitting: globalState.client.updating,
      failSubmit: globalState.client.failUpdating
    }
  }

  static mapDispatchToProps = {
    updateField: updateClientField,
    update: updateClient,
    fetch: fetchClient
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.clientCode);
  }

  renderResponsesTab(responsesConfig, update) {
    return (
      <FormObject name="responses">
        <div className="row configuration-item">
          <div className="col-10 info">
            <div className="title">Complete condition</div>
            <div className="description">

            </div>
          </div>
          <div className="col-2 switch">
            <FormSwitch
              name="completeCondition"
              keepOldValue
              onValue={{ field: '', comparator: 'equals', value: '' }}
              offValue={null}
            />
          </div>
          {
            responsesConfig.completeCondition && (
              <div className="col-12 additional-config">
                <ConditionInput name="completeCondition" />
              </div>
            )
          }
        </div>

        <div className="row configuration-item">
          <div className="col-10 info">
            <div className="title">Update existing</div>
            <div className="description">

            </div>
          </div>
          <div className="col-2 switch">
            <FormSwitch name="updateExisting" />
          </div>
        </div>

        <div className="row configuration-item">
          <div className="col-10 info">
            <div className="title">Event date</div>
            <div className="description">

            </div>
          </div>
          <div className="col-2 switch">
            <FormSwitch
              name="eventDate"
              keepOldValue
              onValue={{ dateField: '', timeField: '', dateFormat: 'DD/MM/YYYY', timeFormat: 'HH:mm' }}
              offValue={{ dateField: false, timeField: false, dateFormat: null, timeFormat: null }}
              isChecked={value => value.dateField !== false}
            />
          </div>
          {
            responsesConfig.eventDate.dateField !== false && (
              <div className="col-12 additional-config">
                <FormJSON name="eventDate" />
              </div>
            )
          }
        </div>

        <div className="row configuration-item">
          <div className="col-10 info">
            <div className="title">Allways skip locks</div>
            <div className="description">

            </div>
          </div>
          <div className="col-2 switch">
              <FormSwitch name="allwaysSkipLocks" />
          </div>
        </div>

        <div className="row configuration-item">
          <div className="col-10 info">
            <div className="title">Generate variables</div>
            <div className="description">

            </div>
          </div>
          <div className="col-2 switch">
            <FormSwitch name="generateVariables" />
          </div>
        </div>

        <div className="row configuration-item">
          <div className="col-10 info">
            <div className="title">Validate comments</div>
            <div className="description">

            </div>
          </div>
          <div className="col-2 switch">
              <FormSwitch name="validateComments" />
          </div>
        </div>

        <div className="row configuration-item">
          <div className="col-10 info">
            <div className="title">Enable dashboard API</div>
            <div className="description">

            </div>
          </div>
          <div className="col-2 switch">
              <FormSwitch name="enableDashboardDataApi" />
          </div>
        </div>

        <FormObject name="api">
          <FormObject name="default">
            <div className="row configuration-item">
              <div className="col-10 info">
                <div className="title">Default API</div>
                <div className="description">

                </div>
              </div>
              <div className="col-2 switch">
                <FormSwitch
                  name="disabled"
                  onValue={false}
                  offValue={true}
                  isChecked={value => !value}
                />
              </div>
              {
                !responsesConfig.api.default.disabled && (
                  <div className="col-12 additional-config">
                    <FormInput name="defaultLimit" label="Default limit" type="number" min={0} />
                    <FormCheckbox name="enableSort" label="Enable sort" />
                    <FormSelect
                      name="defaultSortOrder"
                      label="Default order"
                      options={[
                        { value: 'asc', content: 'Ascending' },
                        { value: 'desc', content: 'Descending' }
                      ]}
                    />
                    <FormCheckbox name="mergeVariablesWithContent" label="Megre variables with content" />
                    <FormSelect
                      name="excludeFields"
                      label="Excluded fields"
                      isMulti
                      canCreate
                    />
                    <FormJSON name="baseFilter" label="Base filter" />
                  </div>
                )
              }
            </div>
          </FormObject>
        </FormObject>
      </FormObject>
    );
  }

  renderReportsTab(reportsConfig) {
    return (
      <FormObject name="reports">
        <div className="row configuration-item">
          <div className="col-10 info">
            <div className="title">Validations reports</div>
            <div className="description">

            </div>
          </div>
          <div className="col-2 switch">
            <FormSwitch
              name="validations"
              keepOldValue
              onValue={{ email: { from: '', to: [], cc: [] } }}
              offValue={{ email: null }}
              isChecked={value => !!value?.email}
            />
          </div>
          {
            reportsConfig?.validations?.email && (
              <div className="col-12 additional-config">
                <FormObject name="validations">
                  <FormObject name="email">
                    <FormInput
                      name="from"
                      label="Email from:"
                    />
                    <FormSelect
                      name="to"
                      label="Email to:"
                      default={null}
                      isMulti
                      canCreate
                    />
                    <FormSelect
                      name="cc"
                      label="Email cc:"
                      default={null}
                      isMulti
                      canCreate
                    />
                  </FormObject>
                </FormObject>
              </div>
            )
          }
        </div>
      </FormObject>
    );
  }

  renderStoreTab(storeConfig) {
    return (
      <div className="row configuration-item">
        <div className="col-10 info">
          <div className="title">Store Data Service</div>
          <div className="description">

          </div>
        </div>
        <div className="col-2 switch">
          <FormSwitch
            name="store"
            keepOldValue
            onValue={{ apiUrl: '', accessToken: '' }}
            offValue={null}
          />
        </div>
        {
          storeConfig && (
            <div className="col-12 additional-config">
              <FormObject name="store">
                <FormInput
                  name="apiUrl"
                  label="API URL:"
                />
                <FormInput
                  name="accessToken"
                  label="Access Token:"
                />
              </FormObject>
            </div>
          )
        }
      </div>
    );
  }

  commentValidationTab(commentValidationConfig) {
    return (
      <div className="row configuration-item">
        <div className="col-10 info">
          <div className="title">Comment validation</div>
          <div className="description">

          </div>
        </div>
        <div className="col-2 switch">
          <FormSwitch
            name="commentValidation"
            keepOldValue
            onValue={{ commentField: [], SATField: [], languageField: 'startlanguage', commentScoreDefinitions: {} }}
            offValue={null}
          />
        </div>
        {
          commentValidationConfig && (
            <div className="col-12 additional-config">
              <FormObject name="commentValidation">
                <FormSelect
                  name="commentField"
                  label="Comment field:"
                  default={null}
                  isMulti
                  canCreate
                />
                <FormSelect
                  name="SATField"
                  label="Comment Satisfaction field:"
                  default={null}
                  isMulti
                  canCreate
                />
                <FormInput name="languageField" label="Language field name:" />
                <FormJSON name="commentScoreDefinitions" label="Comment score definitions map:" />
              </FormObject>
            </div>
          )
        }
      </div>
    );
  }

  renderDataSenderTab(commentValidationConfig) {
    return (
      <div className="row configuration-item">
        <div className="col-10 info">
          <div className="title">Data Senders</div>
          <div className="description">

          </div>
        </div>
        <div className="col-2 switch"></div>
        <div className="col-12 additional-config">
          <FormJSON name="dataSender" />
        </div>
      </div>
    );
  }

  render() {
    if (this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <CRUDActions entry={this.props.entry} entity="survey" edit={false} />
        <Form
          onChange={this.props.updateField}
          onSubmit={this.props.update}
          values={this.props.entry}
          submitting={this.props.submitting}
          failSubmit={this.props.failSubmit}
        >
          <h2 className="pt-5">
            Update client <b>{this.props.entry.code}</b>
            <span style={{ float: 'right' }}>
              <FormSubmit />
            </span>
          </h2>
          <FormInput name="name" label="Name" />
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a
                  style={{ cursor: 'pointer' }}
                  className={"pointer nav-link " + (this.state.currentTab === 'responses' ? 'active' : '') }
                  onClick={() => this.setState({ currentTab: 'responses' })}
                >
                  Responses
                </a>
              </li>
              <li className="nav-item">
                <a
                  style={{ cursor: 'pointer' }}
                  className={"pointer nav-link " + (this.state.currentTab === 'comments' ? 'active' : '') }
                  onClick={() => this.setState({ currentTab: 'comments' })}
                >
                  Comments
                </a>
              </li>
              <li className="nav-item">
                <a
                  style={{ cursor: 'pointer' }}
                  className={"pointer nav-link " + (this.state.currentTab === 'reports' ? 'active' : '') }
                  onClick={() => this.setState({ currentTab: 'reports' })}
                >
                  Reports
                </a>
              </li>
              <li className="nav-item">
                <a
                  style={{ cursor: 'pointer' }}
                  className={"pointer nav-link " + (this.state.currentTab === 'stores' ? 'active' : '') }
                  onClick={() => this.setState({ currentTab: 'stores' })}
                >
                  Stores
                </a>
              </li>
              <li className="nav-item">
                <a
                  style={{ cursor: 'pointer' }}
                  className={"pointer nav-link " + (this.state.currentTab === 'dataSender' ? 'active' : '') }
                  onClick={() => this.setState({ currentTab: 'dataSender' })}
                >
                  Data Senders
                </a>
              </li>
              <li className="nav-item">
                <a
                  style={{ cursor: 'pointer' }}
                  className={"pointer nav-link " + (this.state.currentTab === 'json' ? 'active' : '') }
                  onClick={() => this.setState({ currentTab: 'json' })}
                >
                  Edit as JSON
                </a>
              </li>
            </ul>
          <FormObject name="config">
            { this.state.currentTab === 'responses' && this.renderResponsesTab(this.props.entry.config.responses) }
            { this.state.currentTab === 'comments' && this.commentValidationTab(this.props.entry.config.commentValidation) }
            { this.state.currentTab === 'reports' && this.renderReportsTab(this.props.entry.config.reports) }
            { this.state.currentTab === 'stores' && this.renderStoreTab(this.props.entry.config.store) }
            { this.state.currentTab === 'dataSender' && this.renderDataSenderTab(this.props.entry.config.dataSender) }
          </FormObject>
          { this.state.currentTab === 'json' && <FormJSON name="config" label="Config:" />}
        </Form>
      </div>
    );
  }
}
