import React, { Component } from 'react';
import withForm from './withForm';

@withForm()
export default class FormValue extends Component {

  componentDidMount() {
    this.props.onChange(this.props.toValue);
  }

  render() {
    return null;
  }
}
