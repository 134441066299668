
let prefix = '';

class Storage {
  constructor(name, type = 'localStorage') {
    this.name = name;
    this.type = 'localStorage';
  }

  setState(obj) {
    if(!this._storageAvailable()) {
      console.warn(`Storage: Can not access storage type "${this.type}", make sure you are running the app in a browser that supports "${this.type}".`);
      return;
    }

    this._item = obj;
  }

  get state() {
    return this._item;
  }

  get _key() {
    return prefix + this.name;
  }

  get _item() {
    const json = this._getStorage().getItem(this._key);

    try {
      return JSON.parse(json);
    } catch(err) {
      return {};
    }
  }

  set _item(obj) {
    this._getStorage().setItem(this._key, JSON.stringify(obj));
  }

  _getStorage() {
    return window[this.type];
  }

  _storageAvailable() {
    if(typeof window === 'undefined') {
      return false;
    }

    const testKey = '__storage_test__';

    try {
      const storage = this._getStorage();
      storage.setItem(testKey, testKey);
      storage.removeItem(testKey);
      return true;
    } catch(err) {
      console.error(err);
      return false;
    }
  }

  static setPrefix(newPrefix) {
    prefix = newPrefix;
  }
}

export default Storage;
