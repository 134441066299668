
import React, { Component } from 'react';

import { orderBy } from 'lodash';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import CardList from '/components/ui/CardList/CardList';
import ValidatorCard from '/components/views/Validator/ValidatorCard';

import CRUDActions from '/components/ui/Actions/CRUDActions';

import { fetchValidatorGroup } from '/actions/validator_group';

@connect()
@decorate(withRouter)
export default class ValidatorGroupView extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      entry: globalState.validator_group.data,
      isLoading: globalState.validator_group.isLoading
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      fetch: id => dispatch(fetchValidatorGroup(id))
    }
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.id);
  }

  render() {
    if(this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <CRUDActions entry={ this.props.entry } entity="validator_group" permissionEntity="validator" view={ false } />
        <h1>Validator group <b>{ this.props.entry.name } (#{ this.props.entry.id })</b></h1>
        <label>Description:</label>
        <p>{ this.props.entry.description || "No description." }</p>
        <label>Validators:</label>
        <CardList
          cards={ this.props.entry.validators && orderBy(this.props.entry.validators, 'ValidatorGroupsValidators.order') }
          render={ entry => <ValidatorCard key={ entry.id } entry={ entry } validatorGroupId={ this.props.entry.id } /> }
          isLoading={ this.props.isLoading }
        />
      </div>
    );
  }
}
