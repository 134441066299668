
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import { deleteValidator, fetchValidator } from '/actions/validator';

@connect()
@decorate(withRouter)
export default class ValidatorDelete extends Component {

  static mapStateToProps(globalState) {
    return {
      entry: globalState.validator.data,
      isLoading: globalState.validator.isLoading
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      fetch: id => dispatch(fetchValidator(id)),
      delete: (id, callback) => dispatch(deleteValidator(id, callback))
    }
  }

  delete() {
    this.props.delete(this.props.entry.id, () => {
      this.goBack();
    });
  }

  goBack() {
    this.props.history.goBack();
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.validatorId);
  }

  render() {
    if(this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <h2>Delete validator <b>#{ this.props.entry.id }</b></h2>
        <p>Está seguro de que quiere eliminar el validador <b>"{ this.props.entry.name }"</b>?</p>
        <div>
          <button className="btn" onClick={ () => this.delete() }>Eliminar</button>
          &nbsp;
          <button className="btn" onClick={ () => this.goBack() }>Cancelar</button>
        </div>
      </div>
    );
  }
}
