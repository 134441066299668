import React, { Component } from 'react';

import Form, { FormInput, FormSubmit, FormSelect, FormObject, FormValue } from '/components/ui/Forms';
import ValueMapEdit from './ValueMapEdit';

export default (props) => (
  <Form
    onChange={props.onChange}
    onSubmit={props.onSubmit}
    values={props.values}
    submitting={props.submitting}
    failSubmit={props.failSubmit}
  >
    <div className="row">
      <div className="col-6">
        <FormObject name="options">
          <FormSelect name="source" label="Sources:" isMulti canCreate />
        </FormObject>
      </div>
      <div className="col-6">
        <FormInput name="target" label="Target:" />
      </div>
    </div>
    <FormObject name="options">
      <div className="row">
        <div className="col-6">
          <FormSelect
            name="skipSourceOn"
            label="Skip sources on:"
            isMulti
            canCreate
            options={[
              { label: 'null', value: null },
              { label: 'Empty String', value: "" }
            ]}
          />
        </div>
        <div className="col-3">
          <FormSelect
            name="joinBy"
            label="Join sources by:"
            canCreate
            default={null}
            options={[
              { label: 'Do not join, only skip', value: null },
              { label: 'Empty String', value: "" },
              { label: 'Space', value: " " },
              { label: 'Tab', value: "\t" },
              { label: 'Line break', value: "\n" }
            ]}
          />
        </div>
        <div className="col-3">
          <FormSelect
            name="hash"
            label="Hash"
            default={null}
            options={[
              { value: null, content: 'Do not hash values' },
              { value: true, content: 'Hash values' },
              { value: 'email', content: 'Hash email username for email values' }
            ]}
          />
        </div>
        <div className="col-6">
          <FormSelect
            name="type"
            label="Type:"
            options={[
              { value: undefined, content: 'Any' },
              { value: 'date', content: 'Date' }
            ]}
          />
          {
            props.values.options.type === 'date' && (
              <FormObject
                name="format"
                parseValue={
                  format => typeof format === 'string' ? { to: format } : format
                }
              >
                <span style={{ fontSize: '18px' }}>Format date:</span>
                <hr/>
                <div className="row">
                  <div className="col-6">
                    <FormInput name="from" label="From" />
                  </div>
                  <div className="col-6">
                    <FormInput name="to" label="To" />
                  </div>
                </div>
              </FormObject>
            ) || <FormValue name="format" toValue={undefined} />
          }
        </div>
        <div className="col-3">
          <FormSelect
            name="onNull"
            label="On null value:"
            default={null}
            canCreate
            options={[
              { label: 'Null', value: null },
              { label: 'Empty String', value: "" },
              { label: 'True', value: true },
              { label: 'False', value: false }
            ]}
            getCreatedValueOption={
              value => {
                if(!isNaN(value)) {
                  return { value: parseFloat(value), label: value };
                } else {
                  return { value, label: value }
                }
              }
            }
          />
        </div>
        <div className="col-3">
          <FormSelect
            name="default"
            label="Default value:"
            default={undefined}
            canCreate
            options={[
              { label: 'Undefined', value: undefined },
              { label: 'Null', value: null },
              { label: 'Empty String', value: "" },
              { label: 'True', value: true },
              { label: 'False', value: false }
            ]}
            getCreatedValueOption={
              value => {
                if(!isNaN(value)) {
                  return { value: parseFloat(value), label: value };
                } else {
                  return { value, label: value }
                }
              }
            }
          />
        </div>
        <div className="col-12">
          <ValueMapEdit name="valueMap" />
        </div>
      </div>
    </FormObject>
    <FormSubmit />
  </Form>
)