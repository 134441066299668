import React from 'react';
import withForm from './withForm';
const moment = require('moment');

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './FormDate.scss';
import FormInputBase from '/components/ui/Forms/FormInputBase';

@withForm()
export default class FormDate extends FormInputBase {
  renderInput() {
    return (
      <DatePicker
        id={ this.props.name }
        className="form-control"
        selected={ this.props.value ? moment(this.props.value, this.props.format) : null }
        onChange={ date => this.props.onChange(date.format(this.props.format)) }
        placeholderText={ this.props.placeholder }
        readOnly={ this.props.readOnly }
        dateFormat={ this.props.dateFormat }
        timeFormat={ this.props.timeFormat }
        showTimeSelect={ this.props.showTimeSelect }
        utcOffset={ this.props.utcOffset }
        autoComplete="off"
      />
    );
  }
}
