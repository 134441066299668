import React, { Component, Fragment } from 'react'

import Form, { FormInput, FormSelect, FormSubmit } from '/components/ui/Forms';
import CountryInput from '/components/views/Country/CountryInput';

import { Country } from '/actions/country';

import {
  fetchReport,
  updateReportQueryField
} from '/actions/reports';

import connect from '/components/decorators/connect';

@connect()
export default class ReportViewer extends Component {
  static mapStateToProps(store) {
    return {
      query: store.reports.query,
      results: store.reports.results,
    }
  }
  static mapDispatchToProps = {
    updateQuery: updateReportQueryField,
    fetchReport
  }

  async componentDidMount() {
    const { data: countries } = await Country.fetchAll({ isEnabled: true }, 'name');
    this.props.updateQuery('allCountries', countries.map(({ name, code }) => ({ name, code })));
  }

  get selectedCountryName() {
    return this.props.query.allCountries?.find(({ code }) => code === this.props.query.countryCode) ?.name;
  }

  render() {
    return (
      <div className="container-fluid view-list">
        <div className="row">
          <div className="col-9">
            <h1>
              {
                !this.props.results ? 'Reporte'
                  : (this.props.query.type === 'store' ?
                    `Reporte de Validaciones de ${this.props.query.store} (${this.selectedCountryName}) ${this.props.query.yearMonth}`
                    : `Reporte de Validaciones de ${this.selectedCountryName} (${this.props.query.countryCode?.toUpperCase()}) ${this.props.query.yearMonth}`)
              }
            </h1>
          </div>
          <div className="col-3">

          </div>
        </div>
        <div className="row">
          <div className="col-9">
            {
              this.props.results &&
              <Fragment>
                <h2>Casos:</h2>

                <table border="1" cellPadding="3" cellSpacing="0" width="100%">
                  <tbody>
                    <tr>
                      <td>Ingresos a la plataforma</td>
                      <td>{this.props.results.total}</td>
                    </tr>
                    <tr>
                      <td>Casos completos (procesados)</td>
                      <td>{this.props.results.complete}</td>
                    </tr>
                    <tr>
                      <td>Casos invalidos</td>
                      <td>{this.props.results.invalid}</td>
                    </tr>
                    <tr>
                      <td>Casos validos</td>
                      <td>{this.props.results.valid}</td>
                    </tr>
                  </tbody>
                </table>

                <h2>Cascada de validaciones:</h2>
                <table border="1" cellPadding="3" cellSpacing="0" width="100%" >
                  <thead>
                    <tr>
                      <th><b>Mensaje de validación</b></th>
                      <th><b>Casos afectados</b></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.results.invalidMessages.map((invalidMessage, i) =>
                      <tr key={i}>
                        <td>{invalidMessage.message}</td>
                        <td>{invalidMessage.count}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Fragment>
            }
          </div>
          <div className="col-3">
            <Form
              onChange={(field, value) => this.props.updateQuery(field, value || undefined)}
              onSubmit={() => this.props.fetchReport()}
              values={this.props.query}>
              <FormSelect
                name='type'
                options={[{ value: 'index', content: 'Country report' }, { value: 'store', content: 'Store report' }]} />
              <FormInput
                name="yearMonth"
                type="month"
                label="Year/Month:" />
              <CountryInput
                name="countryCode"
                valueField="code"
                label="Country:" />
              {
                this.props.query.type === 'store' &&
                <FormInput
                  name="store"
                  label="Store:"
                />
              }
              <FormSubmit children="Load report" />
            </Form>
          </div>
        </div>
      </div>
    );
  }
}