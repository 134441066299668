export default {
  initialState: {
    query: {
      type: '',
      countryCode: '',
      yearMonth: '',
      store: ''
    },
    results: undefined,
    loading: false,
    error: ''
  },

  actions: {
    REPORT_FETCH_START: (state) => ({ ...state, loading: true }),
    REPORT_FETCH_SUCCESS: (state, { results }) => ({ ...state, loading: false, error: '', results }),
    REPORT_FETCH_FAIL: (state, { error }) => ({ ...state, loading: false, error, results: {} }),

    REPORT_QUERY_UPDATE: (state, { field, value }) => ({ ...state, query: { ...state.query, [field]: value } }),
  }
}
