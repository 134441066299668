import React from 'react';
import { find } from 'lodash';

import Form, { FormInput, FormSubmit } from '/components/ui/Forms';
import { FormCheckbox, FormSelect, FormJSONSchema } from '/components/ui/Forms/index';

export const DataSourceEdit = props => {
  const datasource = find(props.types, { type: props.values.type });

  return (
    <Form
      onChange={props.onChange}
      onSubmit={props.onSubmit}
      values={props.values}
    >
      <FormInput name="name" label="Name:" />
      {
        props.values.type === 'fixture'
          ? (<FormInput name="type" label="Type:" readOnly />)
          : (<FormSelect
            name="type"
            label="Type:"
            options={props.types.map(({ title, type }) => ({ value: type, content: title }))}
            onChange={
              value => {
                props.onChange('options', {});
                return value;
              }
            } />)
      }
      {datasource && (<FormJSONSchema name="config" schema={datasource.schema} />)}
      <FormInput name="notes" label="Notas:" />
      <FormCheckbox name="isDisabled" label="Disable" />
      <FormSubmit />
    </Form>
  );
}