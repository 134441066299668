
import FinaleAccessor from '/lib/accessors/FinaleAccessor';
import CRUDActions from '/lib/CRUDActions';
import clientHooks from './clientHooks';

class FieldmapGroupAccessor extends FinaleAccessor {

  async copy(id, data = {}) {
    const response = await this.fetch(`/${id}/copy`, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(data)
    });

    if (response.status === 200) {
      return this.generateEntry(await response.json());
    } else if (response.status === 422) {
      throw new ConsoleError('validation_error', `copying ${this.entity}`);
    } else if (response.status === 403) {
      throw new ConsoleError('forbidden', `copying ${this.entity}`);
    } else if (response.status === 401) {
      throw new ConsoleError('unouthorized', `copying ${this.entity}`);
    } else {
      throw new ConsoleError('unknown', `copying ${this.entity}`);
    }
  }

  async import(definition) {
    const response = await this.fetch(`/import`, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(definition)
    });

    if (response.status === 200) {
      return this.generateEntry(await response.json());
    } else if (response.status === 422) {
      throw new ConsoleError('validation_error', `import ${this.entity}`);
    } else if (response.status === 403) {
      throw new ConsoleError('forbidden', `import ${this.entity}`);
    } else if (response.status === 401) {
      throw new ConsoleError('unouthorized', `import ${this.entity}`);
    } else {
      throw new ConsoleError('unknown', `import ${this.entity}`);
    }
  }

}

export const FieldmapGroup = new FieldmapGroupAccessor('/api/v1', 'fieldmap_group');
export const FieldmapGroupActions = CRUDActions(FieldmapGroup, {
  hooks: [
    {
      name: 'update_afterSave',
      async handler(orders) {
        if (!orders) {
          return;
        }

        const { fieldmap_group: { id } } = this.getState();
        await this.accessor.fetch(`/${id}/fieldmaps/order`, {
          method: 'PUT',
          headers: new Headers({ 'Content-Type': 'application/json' }),
          body: JSON.stringify({ orders })
        });
      }
    },
    ...clientHooks
  ],

  getCustomActions({ reducer, accessor, hookCallerGenerator }) {
    return {
      copyFromEntity(id, data, callback) {
        return async (dispatch, getState) => {
          dispatch(reducer('COPY', { id }));

          try {
            const data = await accessor.copy(id, data);

            if (callback) {
              callback(data);
            }

            dispatch(reducer('COPY_SUCCESS', {
              id,
              data: {
                ...data,
                id: undefined,
                createdAt: undefined,
                updatedAt: undefined
              }
            }));
          } catch (error) {
            dispatch(reducer('COPY_FAIL', { id, error }));
          }
        }
      },
      importEntity(data, callback) {
        return async (dispatch, getState) => {
          const callHook = hookCallerGenerator(dispatch, getState, arguments);

          dispatch(reducer('CREATE'));
          await callHook('create_data', { data });
          try {
            const entry = await accessor.import(data);
            await callHook('create_afterCreate', { entry });
            dispatch(reducer('CREATE_SUCCESS', { data: entry }));
  
            if (callback) {
              callback(entry);
            }
          } catch (error) {
            dispatch(reducer('CREATE_FAIL', { error }));
          }
        }
      }
    }
  }
});

export const fetchFieldmapGroups = FieldmapGroupActions.fetchFieldmapGroups;
export const fetchFieldmapGroup = FieldmapGroupActions.fetchFieldmapGroup;
export const setFieldmapGroupListQuery = FieldmapGroupActions.setFieldmapGroupListQuery;
export const updateFieldmapGroupField = FieldmapGroupActions.updateFieldmapGroupField;
export const updateFieldmapGroup = FieldmapGroupActions.updateFieldmapGroup;
export const deleteFieldmapGroup = FieldmapGroupActions.deleteFieldmapGroup;
export const copyFromFieldmapGroup = FieldmapGroupActions.copyFromFieldmapGroup;
export const createFieldmapGroup = FieldmapGroupActions.createFieldmapGroup;
export const cleanFieldmapGroupData = FieldmapGroupActions.cleanFieldmapGroupData;
export const importFieldmapGroup = FieldmapGroupActions.importFieldmapGroup;
