
import FinaleAccessor from '/lib/accessors/FinaleAccessor';
import CRUDActions from '/lib/CRUDActions';

export const Client = new FinaleAccessor('/api/v1', 'client');
export const ClientActions = CRUDActions(Client);

export const fetchClients = ClientActions.fetchAllClients;
export const fetchClient = ClientActions.fetchClient;
export const setClientListQuery = ClientActions.setClientListQuery;
export const updateClientField = ClientActions.updateClientField;
export const updateClient = ClientActions.updateClient;
export const deleteClient = ClientActions.deleteClient;
export const copyFromClient = ClientActions.copyFromClient;
export const createClient = ClientActions.createClient;
export const cleanClientData = ClientActions.cleanClientData;
