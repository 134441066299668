import React, { Component, createElement, Fragment } from 'react';
import withForm from '../withForm';

import TypeString from './TypeString';
import TypeNumber from './TypeNumber';
import TypeBoolean from './TypeBoolean';
import TypeArray from './TypeArray';
import TypeSpecialEnum from './TypeSpecialEnum';
import TypeSpecialAnyOneOf from './TypeSpecialAnyOneOf';
import TypeSpecialProperties from './TypeSpecialProperties';
import TypeNull from './TypeNull';
import TypeDate from './TypeDate';
import TypeInteger from './TypeInteger';
import TypeSpecialAdditionalProperties from './TypeSpecialAdditionalProperties';
import { FormSelect, FormGroup } from '/components/ui/Forms/index';

export const componentsByType = {
  string: TypeString,
  date: TypeDate,
  number: TypeNumber,
  boolean: TypeBoolean,
  array: TypeArray,
  null: TypeNull,
  integer: TypeInteger,
  'special:enum': TypeSpecialEnum,
  'special:anyoneof': TypeSpecialAnyOneOf,
  'special:properties': TypeSpecialProperties,
  'special:additionalProperties': TypeSpecialAdditionalProperties,
}
const Field = withForm()(class Field extends Component {

  state = { type: undefined }

  static getDerivedStateFromProps(props, state) {
    if (!state.type) {
      if (Array.isArray(props.schema.type)) {
        return { type: props.schema.type[0] };
      } else {
        return { type: props.schema.type };
      }
    }
  }

  getValue() {
    const { type, default: defaultValue } = this.props.schema;

    if (type === 'object' || !type) {
      return this.props.value || defaultValue || {};
    }
    return this.props.value || defaultValue;
  }

  getChildProps(extend = {}) {
    return {
      simple: true,
      name: this.props.name,
      ...extend
    }
  }
  resolveObjectField() {
    const {
      properties,
      anyOf,
      oneOf,
      additionalProperties,
      enum: _enum
    } = this.props.schema;

    if (!properties && !anyOf && !oneOf && !additionalProperties && !_enum) {
      if (typeof additionalProperties === 'boolean') {
        return <Fragment />
      }
      return (
        <label style={{ color: 'red' }}>¡¡SCHEMA ERROR!!<br />An object field has none of <code>properties</code>, <code>anyOf</code>, <code>oneOf</code>, <code>enum</code> or <code>additionalProperties</code> atribute.</label>
      );
    }
    if (_enum) {
      return createElement(componentsByType['special:enum'], this.getChildProps({ _enum }));
    }

    if (anyOf || oneOf) {

      // TODO Buscar una mejor forma de que le llegue al componente TypeSpecialAnyOneOf,
      //      en el estado de edición, la información de que tiene que desplegar los
      //      datos de la validacion en vez de borralos.
      let indexOneAnyOf;
      if (this.props.value?.field) {
        indexOneAnyOf = 0;
      }
      if (this.props.value?.fields) {
        indexOneAnyOf = 1;
      }

      return createElement(
        componentsByType['special:anyoneof'],
        this.getChildProps(
          {
            multiOp: (anyOf || oneOf),
            value: this.props.value,
            label: this.props.schema.title,
            indexOneAnyOf,
            onChange: value => this.props.onChange(value)
          }
        )
      );
    }
    if (additionalProperties) {
      return createElement(
        componentsByType['special:additionalProperties'],
        this.getChildProps(
          {
            type: this.props.schema.additionalProperties.type
          }
        )
      );
    }

    return createElement(
      componentsByType['special:properties'],
      this.getChildProps(
        {
          value: this.props.value,
          properties,
          onChange: value => this.props.onChange(value)
        }
      )
    );
  }

  canChooseType() {
    return this.props.schema.type && Array.isArray(this.props.schema.type);
  }

  getTypeOptions() {
    const types = Array.isArray(this.props.schema.type) ? this.props.schema.type : [this.props.schema.type];
    return types.map(type => ({ value: type, content: type }));
  }

  render() {
    const { type } = this.state;
    const label = this.props.schema.title;
    const moreProps = {};


    if (type === 'object' || !type) {
      // Asumo que nuestros esquemas los enums no tiene type y los manejo por aca
      return this.resolveObjectField();
    }

    if (type === 'array') {
      moreProps.parseItems = this.props.schema ?.items ?.type;
      if (this.props.schema ?.items ?.type === 'object') {
        moreProps.items = this.props.schema.items
      };
    }

    if (type === 'string') {
      if (this.props.schema.isPassword) {
        moreProps.type = "password";
      }
      if ((this.props.schema.format || '').includes('date')) {
        moreProps.format = this.props.schema.format;
        return createElement(componentsByType['date'], this.getChildProps(moreProps));
      }
    }

    if (type === 'integer') {
      moreProps.onChange = (value) => parseInt(value, 10);
    }

    if (type === 'number') {
      moreProps.onChange = (value) => parseFloat(value);
    }

    return (
      <FormGroup>
        {label && <label htmlFor={this.props.name}>{label}</label>}
        {
          this.canChooseType() && (
            <FormSelect
              simple
              value={type}
              name="typeSelect"
              noForm
              options={this.getTypeOptions()}
              onChange={type => this.setState({ type })} />
          )
        }
        {createElement(componentsByType[type], this.getChildProps(moreProps))}
      </FormGroup>
    );
  }

})

export default Field;