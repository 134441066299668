import React from 'react';
import FormSelect from '../FormSelect'

export default ({ _enum, ...props }) => {
  const options = _enum.map(op => ({ content: op, value: op }))
  const value = (options.length === 1) ? options[0].value : undefined;

  return (
    <FormSelect
      {...props}
      options={[ { content: 'None', value: undefined }, ...options ]}
      default="*undefined*"
      value={value}
    />
  );
}