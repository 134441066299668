
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Icon from '/components/ui/Icon';
import '/components/layout/MenuItem.scss';

export default ({ to, onClick, text, icon, className = "", iconProps = {}, exact }) => (
  <NavLink exact={exact} to={ to } onClick={ onClick } className={ "menu-item " + className }>
    { icon && <Icon icon={['fas', icon]} size="lg" { ...iconProps } /> }
    <span>{ text }</span>
  </NavLink>
);
