
import FinaleAccessor from '/lib/accessors/FinaleAccessor';
import CRUDActions from '/lib/CRUDActions';

export const Country = new FinaleAccessor('/api/v1', 'country');
export const CountryActions = CRUDActions(Country);

export const fetchCountries = CountryActions.fetchCountries;
export const fetchCountry = CountryActions.fetchCountry;
export const setCountryListQuery = CountryActions.setCountryListQuery;
export const updateCountryField = CountryActions.updateCountryField;
export const updateCountry = CountryActions.updateCountry;
export const copyFromCountry = CountryActions.copyFromCountry;
export const createCountry = CountryActions.createCountry;
export const cleanCountryData = CountryActions.cleanCountryData;
