
import React, { Component } from 'react';

import connect from '/components/decorators/connect';

import CardList from '/components/ui/CardList/CardList';
import Pagination from '/components/ui/Pagination';
import Form, { FormSelect } from '/components/ui/Forms';

import { Link } from 'react-router-dom';

import TaskCard from '/components/views/Task/TaskCard';

import { fetchTasks, setTaskListQuery } from '/actions/task';

@connect()
export default class ValidatorGroupList extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      items: globalState.task.list.items,
      isLoading: globalState.task.list.isLoading,
      page: globalState.task.list.page,
      contentRange: globalState.task.list.contentRange,
      query: globalState.task.list.query
    }
  }

  static mapDispatchToProps = {
    fetch: fetchTasks,
    setListQuery: setTaskListQuery
  }

  componentDidMount() {
    this.fetchPage();
  }

  fetchPage(page = this.props.page) {
    this.props.fetch(page, () => this.isReady = true);
  }

  onNext() {
    this.fetchPage(this.props.page + 1);
  }

  onPrev() {
    this.fetchPage(this.props.page - 1);
  }

  updateQuery(field, value) {
    this.props.setListQuery(field, value);
    if (this.timeOutId) {
      window.clearTimeout(this.timeOutId);
    }
    if (this.isReady) {
      this.timeOutId = setTimeout(() => {
        this.fetchPage();
      }, 500);
    }
  }

  render() {
    return (
      <div className="container-fluid view-list">
        <div className="row">
          <div className="col-9">
            <h1>
              Tasks
              <Link className="btn btn-primary float-right" to="/task/run">NEW TASK</Link>
            </h1>
          </div>
          <div className="col-3">
            <Pagination
              onNext={() => this.onNext()}
              onPrev={() => this.onPrev()}
              contentRange={this.props.contentRange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-9">
            <CardList
              cards={this.props.items}
              render={entry => <TaskCard key={entry._id} entry={entry} actions={{ list: false }} />}
              isLoading={this.props.isLoading}
            />
          </div>
          <div className="col-3">
            <Form
              onChange={(field, value) => this.updateQuery(field, value)}
              onSubmit={() => this.fetchPage()}
              values={this.props.query}
            >
              <FormSelect
                name="sort"
                label="Sort:"
                default="-lastRunAt"
                options={
                  [
                    { value: "-lastRunAt", content: "Ejecutados recientemente" },
                    { value: "lastRunAt", content: "Ejecutados anteriormente" },
                    { value: "-nextRunAt", content: "Proximos por ejecutar" },
                    { value: "nextRunAt", content: "Ultimos por ejecutar" }
                  ]
                }
              />
              <FormSelect
                name="status"
                label="Status:"
                placeholder
                default="*undefined*"
                options={
                  [
                    { value: "*undefined*", content: "Todos" },
                    { value: "running", content: "En ejecución" },
                    { value: "finished", content: "Finalizados" },
                    { value: "timedout", content: "Finalizados por timeout" },
                    { value: "waiting", content: "En espera" }
                  ]
                }
              />
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
