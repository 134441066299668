
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import CRUDActions from '/components/ui/Actions/CRUDActions';

import { fetchTask, runCommand } from '/actions/task';

import Ansi from 'ansi-to-react';

import './TaskView.scss';

@connect()
@decorate(withRouter)
export default class TaskView extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      entry: globalState.task.data,
      isLoading: globalState.task.isLoading,
      isRefreshing: globalState.task.isRefreshing,
      statusRefreshRates: globalState.task.statusRefreshRates
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      fetch: (id, refresh) => dispatch(fetchTask(id, refresh))
    }
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.id);
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.id);
  }

  componentDidUpdate(oldProps) {
    if(oldProps.match.params.id !== this.props.match.params.id) {
      this.props.fetch(this.props.match.params.id);
    } else if(!this.props.isLoading && this.props.entry) {
      const refreshRate = this.props.statusRefreshRates[this.props.entry.status];
      
      if(this.timeOutIdId) {
        clearTimeout(this.timeOutIdId);
      }

      if(refreshRate) {
        this.timeOutIdId = setTimeout(() => {
          this.props.fetch(this.props.entry._id, true)
        }, refreshRate);
      }
    }
  }

  componentWillUnmount() {
    if(this.timeOutIdId) {
      clearTimeout(this.timeOutIdId);
    }
  }

  runTask() {
    const { clientCode, command, args } = this.props.entry.data;

    runCommand(clientCode, command, args, task => {
      this.props.history.push(`/task/${ task._id }`);
    });
  }

  render() {
    if(this.props.isLoading && !this.props.isRefreshing || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <CRUDActions entry={ this.props.entry } entity="task" create={ false } delete={ false } copy={ false } edit={ false } idField='_id' permissionEntity={ null } view={ false } />
        <h1>Task #{ this.props.entry._id }</h1>
        <div><b>Command:</b></div>
        <pre className="command-string">{ this.props.entry.data.command } { this.props.entry.data.args.join(' ') }</pre>
        <div><b>Process ID:</b> { this.props.entry.process.pid }</div>
        <div>
          <b>Status: </b>
          { this.props.entry.status }
          &nbsp;
          { this.props.entry.status === 'error' && `(exit code: ${this.props.entry.process.exitCode})` }
        </div>
        <div className="log">
          {
            this.props.entry.process.log
              .join('')
              .split('\n')
              .map((line, index) => <Ansi key={ index } linkify>{ line }</Ansi>)
          }
        </div>
        <br/>
        <button
          className="btn btn-primary"
          onClick={ () => this.runTask() }
          disabled={ ['waiting', 'running'].includes(this.props.entry.status) }
        >
          RUN AGAIN
        </button>
      </div>
    );
  }
}
