
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import FieldmapEdit from '/components/views/Fieldmap/FieldmapEdit';

import { fetchFieldmap, updateFieldmap, updateFieldmapField } from '/actions/fieldmap';

@connect()
@decorate(withRouter)
export default class FieldmapUpdate extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      entry: globalState.fieldmap.data,
      isLoading: globalState.fieldmap.isLoading,
      submitting: globalState.fieldmap.updating,
      failSubmit: globalState.fieldmap.failUpdating
    }
  }

  static mapDispatchToProps = {
    fetch: fetchFieldmap,
    updateField: updateFieldmapField,
    update: updateFieldmap
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.fieldmapId);
  }

  render() {
    if (this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }
    return (
      <div className="container">
        <h2>Update fieldmap #{this.props.entry.id}</h2>
        <FieldmapEdit
          onChange={this.props.updateField}
          onSubmit={this.props.update}
          values={this.props.entry}
          types={this.props.types}
          submitting={this.props.submitting}
          failSubmit={this.props.failSubmit}
        />
      </div>
    );
  }
}
