
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import CRUDActions from '/components/ui/Actions/CRUDActions';

import ResetPasswordAction from '/components/views/User/ResetPasswordAction';
import ChangeRolesAction from '/components/views/User/ChangeRolesAction';

import { fetchUser } from '/actions/user';

@connect()
@decorate(withRouter)
export default class UserView extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      entry: globalState.user.otherUser.data,
      isLoading: globalState.user.otherUser.isLoading
    }
  }

  static mapDispatchToProps = {
    fetch: fetchUser
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.id);
  }

  render() {
    if (this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <CRUDActions
          entry={this.props.entry}
          entity="user"
          basePath={'/console'}
          view={false}
          list={false}
          copy={false}
          aditionalActions={[ResetPasswordAction, ChangeRolesAction]}
        />
        <h2>#{this.props.entry.id} - {this.props.entry.name}</h2>
        <div>
          <div><b>Email:</b> {this.props.entry.email}</div>
          <details>
            <summary><b>Roles</b></summary>
            <ul>
              {this.props.entry.roles?.map((role, key) => <li key={key}>{role}</li>)}
            </ul>
          </details>
          <details>
            <summary ><b>Permissions</b></summary>
            <ul>
              {this.props.entry.permissions?.map((permission, key) => <li key={key}>{permission}</li>)}
            </ul>
          </details>
        </div>
      </div>
    );
  }
}
