import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import merge from 'lodash/merge';

import Storage from '/utils/Storage';
import loadI18n from '/bootstrap/loadI18n';
import reducers, { initialState } from '/reducers';
import initialDispatches from '/bootstrap/initialDispatches';

function logger({ getState }) {
  return next => action => {
    const previewsState = getState();
    const returnValue = next(action);

    if(action.type) {
      console.log('[redux]', `[${action.type}]`, { action, previewsState, nextState: getState()});
    } else {
      console.log('[redux]', { action, previewsState, nextState: getState()});
    }
    return returnValue;
  }
}

function getStateToStore(obj) {
  const res = {};

  if(typeof obj === 'object' && !Array.isArray(obj)) {
    for(let key in obj) {
      if(obj[key]._stateToStore) {
        res[key] = obj[key]._stateToStore;
      }
    }
  }

  return res;
}

export default () => {
  Storage.setPrefix('app:');
  const storage = new Storage('storage');
  const combinedReducers = combineReducers(reducers);
  const store = createStore(combinedReducers, initialState, applyMiddleware(logger, thunk));

  const unsubscribe = store.subscribe((args) => {
    storage.setState(getStateToStore(store.getState()));
  });

  store.dispatch({ type: 'INITIALIZE', storedState: merge({}, store.getState(), storage.state) });

  loadI18n(store);

  initialDispatches(store.getState(), store.dispatch.bind(store));

  return store;
}
