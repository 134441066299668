
import CRUDReducer from '/lib/CRUDReducer';
import CRUDState from '/lib/CRUDState';

export default {
  initialState: CRUDState({
    list: {
      query: {
        sort: '-createdAt'
      }
    }
  }),

  before: (state, action, handler) => {
    return handler(state, action);
  },

  actions: CRUDReducer('survey', {
    INITIALIZE(state) {
      return { ...state }
    }
  })
}
