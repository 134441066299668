
import FinaleAccessor from '/lib/accessors/FinaleAccessor';
import CRUDActions from '/lib/CRUDActions';

import { FieldmapGroup } from './fieldmap_group';

export const Fieldmap = new FinaleAccessor('/api/v1', 'fieldmap');
export const FieldmapActions = CRUDActions(Fieldmap, {
  hooks: [
    {
      name: 'create_afterCreate',
      async handler(callback, fieldmapGroupId) {
        await FieldmapGroup.fetch(`/${fieldmapGroupId}/fieldmap/${this.data.entry.id}`, { method: 'POST' });
      }
    }
  ]
});

export const fetchFieldmaps = FieldmapActions.fetchFieldmaps;
export const fetchFieldmap = FieldmapActions.fetchFieldmap;
export const setFieldmapListQuery = FieldmapActions.setFieldmapListQuery;
export const updateFieldmapField = FieldmapActions.updateFieldmapField;
export const updateFieldmap = FieldmapActions.updateFieldmap;
export const deleteFieldmap = FieldmapActions.deleteFieldmap;
export const copyFromFieldmap = FieldmapActions.copyFromFieldmap;
export const createFieldmap = FieldmapActions.createFieldmap;
export const cleanFieldmapData = FieldmapActions.cleanFieldmapData;

export const importFieldmaps = (fieldmapGroupId, fieldmaps, callback) => {
  return async (dispatch) => {
    for (let fieldmap of fieldmaps) {
      const { id } = await Fieldmap.create(fieldmap);
      await FieldmapGroup.fetch(`/${fieldmapGroupId}/fieldmap/${id}`, { method: 'POST' });
    }

    callback();
  }
}