
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import CRUDActions from '/components/ui/Actions/CRUDActions';

import { fetchFieldmap } from '/actions/fieldmap';

import ReactJson from 'react-json-view';

@connect()
@decorate(withRouter)
export default class FieldmapView extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      entry: globalState.fieldmap.data,
      isLoading: globalState.fieldmap.isLoading
    }
  }

  static mapDispatchToProps = {
    fetch: fetchFieldmap
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.fieldmapId);
  }

  render() {
    if (this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <CRUDActions
          entry={this.props.entry}
          entity="fieldmap"
          basePath={'/fieldmap_group/' + this.props.match.params.fieldmapGroupId}
          view={false}
          list={false}
        />
        <h2>#{this.props.entry.id}</h2>
        <div><b>Target:</b> {this.props.entry.target}</div>
        <div>
          <b>Options:</b>
          <ReactJson src={this.props.entry.options} name={false} />
        </div>
      </div>
    );
  }
}
