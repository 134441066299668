import React, { Component } from 'react';

import connect from '/components/decorators/connect';

import { Link } from 'react-router-dom';

import CardList from '/components/ui/CardList/CardList';
import DataSourceCard from './DataSourceCard';

import { fetchDataSources } from '/actions/dataSource'

@connect()
export default class DataSourceList extends Component {

  static mapStateToProps(store) {
    return {
      user: store.data_source.data,
      items: store.data_source.list.items,
      isLoading: store.data_source.list.isLoading
    }
  }

  static mapDispatchToProps = {
    fetch: fetchDataSources,
  }

  componentDidMount() {
    this.props.fetch();
  }

  findResults(queryText) {
    if (this.timeOutId) {
      window.clearTimeout(this.timeOutId);
    }
    this.timeOutId = setTimeout(() => this.props.fetch(queryText), 500);
  }

  render() {
    return (
      <div className="container-fluid view-list">
        <div className="row">
          <div className="col-12">
            <h1>
              Data Sources
              <Link className="btn btn-primary float-right" to="/console/data_source/create">NEW DATA SOURCE</Link>
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <CardList
              cards={this.props.items}
              render={entry => <DataSourceCard key={entry.id} entry={entry} actions={{ list: false }} />}
              isLoading={this.props.isLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}
