
import fetch from '/lib/fetch';
import { doFetchLoggedUser } from './user';

export const login = () => {
  return async (dispatch, getState) => {
    const { email, password } = getState().login;

    const response = await fetch('/auth/login', {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({ email, password })
    });

    if(response.status === 422) {
      return dispatch({ type: 'LOGIN_FAIL', code: 'login.error.invalidData' });
    } else if(response.status !== 200) {
      return dispatch({ type: 'LOGIN_FAIL', code: 'login.error.unknown' });
    }

    document.cookie = response.headers.get('set-cookie');

    try {
      const data = await doFetchLoggedUser();
      dispatch({ type: 'LOGIN_SUCCESS', data });
    } catch(error) {
      dispatch({ type: 'LOGIN_FAIL', code: 'login.error.unknown2' });
    }
  }
}
