
import React, { Component } from 'react';

import Card from '/components/ui/CardList/Card';
import CRUDActions from '/components/ui/Actions/CRUDActions';

export default class FieldmapCard extends Component {
  render() {

    const { entry, actions = {}, hideOptions, fieldmapGroupId, ...props } = this.props;

    return (
      <Card {...props}>
        <CRUDActions
          entry={this.props.entry}
          entity="fieldmap"
          basePath={'/fieldmap_group/' + fieldmapGroupId}
          create={false}
          list={false}
          {...actions}
        />
        <div>#{entry.id}</div>
        <div><b>Order:</b> {entry.order}</div>
        <div><b>Target:</b> {entry.target}</div>
        {
          !hideOptions && (
            <div>
              <b>Options:</b>
              <pre>{JSON.stringify(entry.options, null, 4)}</pre>
            </div>
          )
        }
      </Card>
    )
  }
}
