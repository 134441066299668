
import React from 'react';

import Card from '/components/ui/CardList/Card'
import { Link } from 'react-router-dom';

import ResponsesCount from '/components/views/Response/ResponsesCount';
import CRUDActions from '/components/ui/Actions/CRUDActions';

const ClientCard = ({ entry, ...props }) => (
  <Card {...props}>
    <CRUDActions basePath='/console' entity='client' entry={entry} idField="code" list={false} delete={false} copy={false} create={false} />
    <div>
      <Link to={`/console/client/${entry.code}`}>{entry.name} ({entry.code})</Link>
    </div>
    <ResponsesCount label="Responses: " clientCode={entry.code} />
  </Card>
)

export default ClientCard;