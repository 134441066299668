export default class Accessor {

  constructor(baseUrl, entity) {
    this.baseUrl = baseUrl;
    this.entity = entity;
  }

  async fetch(path = '', options = {}) {
    if(typeof path === 'object') {
      options = path;
      path = options.path || '';
    }
    return await fetch(`${this.baseUrl}/${this.entity}` + path, {
      credentials: 'include',
      method: 'GET',
      ...options
    });
  }

  async fetchAll() {
    throw new Error('Method not implemented.');
  }

  async fetchList(page, perPage, sort) {
    throw new Error('Method not implemented.');
  }

  async fetchOne(id) {
    throw new Error('Method not implemented.');
  }

  async save(id, data) {
    throw new Error('Method not implemented.');
  }

  async create(data) {
    throw new Error('Method not implemented.');
  }

  async create(id) {
    throw new Error('Method not implemented.');
  }

  generateEntry(data) {
    return {
      ...data,

      _entity: this.entity,

      toJSON() {
        return { ...this, _entity: undefined };
      }
    }
  }

  generateEntries(list) {
    return list.map(data => this.generateEntry(data));
  }

  getContentRange(response) {
    throw new Error('Method not implemented.');
  }

}