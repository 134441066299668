
export default class ConsoleError extends Error {

	get name() {
		return 'ConsoleError'
	}

	constructor(code, message) {
		super(`${code} while ${message}`);
		this.code = code;
	}

}