
import React, { Component } from 'react';

import { orderBy } from 'lodash';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import CRUDActions from '/components/ui/Actions/CRUDActions';

import { fetchValidator } from '/actions/validator';

import ReactJson from 'react-json-view';

@connect()
@decorate(withRouter)
export default class ValidatorView extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      entry: globalState.validator.data,
      isLoading: globalState.validator.isLoading
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      fetch: id => dispatch(fetchValidator(id))
    }
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.validatorId);
  }

  render() {
    if(this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <CRUDActions
          entry={ this.props.entry }
          entity="validator"
          basePath={ '/validator_group/' + this.props.match.params.validatorGroupId }
          view={ false }
          list={ false }
        />
        <h2>#{ this.props.entry.id }</h2>
        <div><b>Name:</b> { this.props.entry.name }</div>
        <div><b>Message:</b> { this.props.entry.message }</div>
        <div><b>Type:</b> { this.props.entry.type }</div>
        <div>
          <b>Options:</b>
          <ReactJson src={ this.props.entry.options } name={ false } />
        </div>
      </div>
    );
  }
}
