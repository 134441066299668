
import Case from 'case';
import { plural, singular } from 'pluralize';
import { filter } from 'lodash';

export default (accessor, { prefix, hooks = [], getCustomActions } = {}) => {
  prefix = prefix || Case.constant(accessor.entity) + '_' + 'CRUD';

  const replaceEntity = actions => {
    const name = Case.pascal(accessor.entity);
    return Object.keys(actions).reduce(
      (res, key) => {
        const newKey = key
          .replace('Entity', singular(name))
          .replace('Entities', plural(name));

        return {
          ...res,
          [newKey]: actions[key]
        };
      },
      {}
    );
  };

  const reducer = (type, data) => ({ type: prefix + '_' + type, ...data });

  const hookCallerGenerator = (dispatch, getState, args) => {
    return async (name, data) => {
      const context = { dispatch, getState, data, accessor };

      for (let { handler } of filter(hooks, { name })) {
        if (typeof handler === 'function') {
          const promise = handler.call(context, ...args);
          if (promise && promise instanceof Promise) {
            await promise;
          }
        }
      }
    }
  }

  if (!getCustomActions) {
    getCustomActions = () => ({});
  }

  const actions = replaceEntity({
    fetchEntities(page = 1, callback) {
      return async (dispatch, getState) => {
        const callHook = hookCallerGenerator(dispatch, getState, arguments);

        dispatch(reducer('FETCH_LIST', { page }))

        try {
          const { perPage } = getState()[accessor.entity].list;
          const { sort, ...query } = getState()[accessor.entity].list.query;
          await callHook('fetch_query', { query });

          const { data, contentRange } = await accessor.fetchList(page, perPage, sort, query);
          dispatch(reducer('FETCH_LIST_SUCCESS', { page, data, contentRange }));
          if (callback) {
            callback();
          }
        } catch (error) {
          dispatch(reducer('FETCH_LIST_FAIL', { error }));
        }
      }
    },

    fetchAllEntities() {
      return async (dispatch, getState) => {
        const callHook = hookCallerGenerator(dispatch, getState, arguments);

        dispatch(reducer('FETCH_LIST', { page: 1 }))

        try {
          const { sort, ...query } = getState()[accessor.entity].list.query;
          await callHook('fetch_query', { query });

          const { data, contentRange } = await accessor.fetchAll(query, sort);
          dispatch(reducer('FETCH_LIST_SUCCESS', { page: 1, data, contentRange }));
        } catch (error) {
          dispatch(reducer('FETCH_LIST_FAIL', { error }));
        }
      }
    },

    fetchEntity(id, refresh = false) {
      return async (dispatch, getState) => {
        const callHook = hookCallerGenerator(dispatch, getState, arguments);

        dispatch(reducer('FETCH', { id, refresh }));

        try {
          const data = await accessor.fetchOne(id);
          dispatch(reducer('FETCH_SUCCESS', { id, data }));
        } catch (error) {
          dispatch(reducer('FETCH_FAIL', { id, error }));
        }
      }
    },

    deleteEntity(id, callback) {
      return async (dispatch, getState) => {
        const callHook = hookCallerGenerator(dispatch, getState, arguments);

        dispatch(reducer('DELETE', { id }));

        try {
          await accessor.delete(id);
          dispatch(reducer('DELETE_SUCCESS', { id }));

          if (callback) {
            callback();
          }
        } catch (error) {
          dispatch(reducer('DELETE_FAIL', { id, error }));
        }
      }
    },

    setEntityListQuery(field, value) {
      return reducer('LIST_QUERY_SET', { field, value });
    },

    updateEntityField(field, value) {
      return reducer('UPDATE_FIELD', { field, value });
    },

    updateEntity() {
      return async (dispatch, getState) => {
        const callHook = hookCallerGenerator(dispatch, getState, arguments);

        dispatch(reducer('UPDATE', { id }));

        const { id, data } = getState()[accessor.entity];

        try {
          const entry = await accessor.save(id, data);
          await callHook('update_afterSave', { entry });
          dispatch(reducer('UPDATE_SUCCESS', { id }));
        } catch (error) {
          dispatch(reducer('UPDATE_FAIL', { id, error }));
        }
      }
    },

    copyFromEntity(id) {
      return async (dispatch, getState) => {
        const callHook = hookCallerGenerator(dispatch, getState, arguments);

        dispatch(reducer('COPY', { id }));

        try {
          const data = await accessor.fetchOne(id);
          dispatch(reducer('COPY_SUCCESS', {
            id,
            data: {
              ...data,
              id: undefined,
              createdAt: undefined,
              updatedAt: undefined
            }
          }));
        } catch (error) {
          dispatch(reducer('COPY_FAIL', { id, error }));
        }
      }
    },

    createEntity(callback) {
      return async (dispatch, getState) => {
        const callHook = hookCallerGenerator(dispatch, getState, arguments);

        dispatch(reducer('CREATE'));
        const { data } = getState()[accessor.entity];
        await callHook('create_data', { data });
        try {
          const entry = await accessor.create(data);
          await callHook('create_afterCreate', { entry });
          dispatch(reducer('CREATE_SUCCESS', { data: entry }));

          if (callback) {
            callback(entry);
          }
        } catch (error) {
          dispatch(reducer('CREATE_FAIL', { error }));
        }
      }
    },

    cleanEntityData() {
      return reducer('CLEAN_DATA');
    },

    ...getCustomActions({ reducer, hookCallerGenerator, accessor })
  });

  actions._prefix = prefix;
  actions._accessor = accessor;
  actions._reducer = reducer;

  return actions;
}
