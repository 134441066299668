
const defaultHandler = (state, action) => {
  return state;
}

export default ({ initialState, actions, mapStateToStore, before }) => (state = initialState, action = {}) => {
  const handler = actions[action.type];
  let newState;

  if(before && handler) {
    newState = before(state, action, handler);
  } else if(handler) {
    newState = handler(state, action);
  } else {
    newState = defaultHandler(state, action);
  }

  if(mapStateToStore) {
    newState['_stateToStore'] = mapStateToStore(newState);
  }

  return newState;
}
