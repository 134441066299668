
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import CRUDActions from '/components/ui/Actions/CRUDActions';
import ValidatorGroupInput from '/components/views/ValidatorGroup/ValidatorGroupInput';

import Form, { FormInput, FormSubmit, FormDate, FormObject, FormSelect, FormCheckbox } from '/components/ui/Forms';

import { fetchSurvey, updateSurvey, updateSurveyField } from '/actions/survey';

@connect()
@decorate(withRouter)
export default class SurveyUpdate extends Component {

  static mapStateToProps(globalState) {
    return {
      entry: globalState.survey.data,
      isLoading: globalState.survey.isLoading,
      submitting: globalState.survey.updating,
      failSubmit: globalState.survey.failUpdating
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      fetch: surveyId => dispatch(fetchSurvey(surveyId)),
      updateField: (field, value) => dispatch(updateSurveyField(field, value)),
      update: () => dispatch(updateSurvey())
    }
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.surveyId);
  }

  render() {
    if(this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <CRUDActions entry={ this.props.entry } entity="survey" edit={ false } />
        <h2>Update survey <b>#{ this.props.entry.id }</b></h2>
        <Form
          onChange={ this.props.updateField }
          onSubmit={ this.props.update }
          values={ this.props.entry }
          submitting={this.props.submitting}
          failSubmit={this.props.failSubmit}
        >
          <div className="row">
            <div className="col-3">
              <FormInput name="country.name" label="Country:" type="string" readOnly />
            </div>
            <div className="col-3">
              <FormInput name="dataSource.name" label="Data Source:" type="string" readOnly />
            </div>
            <div className="col-6">
              <FormInput name="sourceSurveyId" label="Source Survey ID:" type="string" readOnly />
            </div>
            <div className="col-6">
              <ValidatorGroupInput label="Primary Validation Group:" name="validatorGroupId" />
            </div>
            <div className="col-6">
              <ValidatorGroupInput label="Secondary Validation Group:" name="secondaryValidatorGroupId" />
            </div>
            <div className="col-3">
              <FormDate name="startDate" label="Start date:" dateFormat="YYYY-MM-DD" format="YYYY-MM-DD 00:00:00" />
            </div>
            <div className="col-3">
              <FormDate name="endDate" label="End date:" dateFormat="YYYY-MM-DD" format="YYYY-MM-DD 00:00:00" />
            </div>
          </div>
          <FormObject name="sourceOptions">
            <div className="row">
              {
                this.props.entry?.dataSource?.type === 'limesurvey' && (
                  <div className="col-6">
                    <FormCheckbox name="fetchParticipantAttributes" label="Fetch participant attributes" />
                  </div>
                )
              }
              {
                this.props.entry?.sourceOptions?.fetchParticipantAttributes && (
                  <div className="col-6">
                    <FormSelect
                      name="participantAttributeNames"
                      label="Participant attribute names:"
                      canCreate
                      isMulti
                    />
                  </div>
                )
              }
            </div>
          </FormObject>
          <FormSubmit />
        </Form>
      </div>
    );
  }
}
