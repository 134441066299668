import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import FormContext from './FormContext';

export default () => Child => (
  class extends Component {

    static name = '@withForm'

    static propTypes = {
      name: PropTypes.string.isRequired
    };

    static get defaultProps() {
      return {
        if: true
      }
    }

    handleChange(context, value) {
      value = this.props.parsePreHandleChange ? this.props.parsePreHandleChange(value) : value;
      if (this.props.onChange) {
        value = this.props.onChange(value);
      }

      if (context) {
        context.handleChange(this.props.name, value);
      }
    }

    componentDidUpdate({ if: prevIf }) {
      if (this.props.if !== prevIf && !this.props.if) {
        this.handleChange(undefined);
      }
    }

    getChildProps(context) {
      return {
        ...this.props,

        onChange: value => this.handleChange(context, value),
        onSubmit: context.handleSubmit,
        onRename: context.handleRename,
        value: this.getValue(context),
        classNames: context.classNames
      }
    }

    getValue(context) {
      let value = get(context?.values, this.props.name);

      if(value === undefined) {
        value = this.props.value || this.props.default;
      }

      return this.parseValue(value);
    }

    parseValue(value) {
      return this.props.parseValue ? this.props.parseValue(value) : value;
    }

    render() {
      if (!this.props.if) {
        return null;
      }

      if (this.props.noForm) {
        return <Child {...this.props} />;
      }

      return (
        <FormContext.Consumer>
          {
            context => (
              <Child {...this.getChildProps(context)} />
            )
          }
        </FormContext.Consumer>
      );
    }
  }
)