
import React, { Component } from 'react';

import connect from '/components/decorators/connect';

import CardList from '/components/ui/CardList/CardList';
import Pagination from '/components/ui/Pagination';
import Form, { FormInput, FormSelect } from '/components/ui/Forms';
import CountryCard from './CountryCard';

import { fetchCountries, setCountryListQuery } from '/actions/country';

@connect()
export default class CountryList extends Component {

  static mapStateToProps(globalState) {
    return {
      entries: globalState.country.list.items,
      isLoading: globalState.country.list.isLoading,
      page: globalState.country.list.page,
      perPage: globalState.country.list.perPage,
      contentRange: globalState.country.list.contentRange,
      query: globalState.country.list.query
    }
  }

  static mapDispatchToProps = {
    fetch: fetchCountries,
    setListQuery: setCountryListQuery
  }

  componentDidMount() {
    this.fetchPage();
  }

  fetchPage(page = this.props.page) {
    if (!this.props.isLoading) {
      this.props.fetch(page, () => this.isReady = true);
    }
  }

  onNext() {
    this.fetchPage(this.props.page + 1);
  }

  onPrev() {
    this.fetchPage(this.props.page - 1);
  }

  updateQuery(field, value) {
    this.props.setListQuery(field, value);
    if (this.timeOutId) {
      window.clearTimeout(this.timeOutId);
    }
    if (this.isReady) {
      this.timeOutId = setTimeout(() => {
        this.fetchPage();
      }, 500);
    }
  }

  render() {
    return (
      <div className="container-fluid view-list">
        <div className="row">
          <div className="col-9">
            <h1>Countries</h1>
          </div>
          <div className="col-3">
            <Pagination
              onNext={() => this.onNext()}
              onPrev={() => this.onPrev()}
              contentRange={this.props.contentRange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-9">
            <CardList
              cards={this.props.entries}
              render={entry => <CountryCard key={entry.id} entry={entry} actions={{ list: false, view: false, delete: false, copy: false }} />}
              isLoading={this.props.isLoading}
            />
          </div>
          <div className="col-3">
            <Form
              onChange={(field, value) => this.updateQuery(field, value)}
              onSubmit={() => this.fetchPage()}
              values={this.props.query}
            >
              <FormSelect
                name="sort"
                label="Sort:"
                options={
                  [
                    { value: "name", content: "Ordenar por nombre (A-Z)" },
                    { value: "-name", content: "Ordenar por nombre (Z-A)" }
                  ]
                }
              />
              <FormInput name="q" label="Search:" />
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
