
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import CRUDActions from '/components/ui/Actions/CRUDActions';

import Moment from '/components/ui/Moment';

import { fetchExport } from '/actions/export';

import prettyBytes from 'pretty-bytes';

@connect()
@decorate(withRouter)
export default class ExportView extends Component {

  static mapStateToProps(globalState) {
    return {
      entry: globalState.export.data,
      isLoading: globalState.export.isLoading
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      fetch: filename => dispatch(fetchExport(filename))
    }
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.filename);
  }

  render() {
    if(this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <CRUDActions entry={ this.props.entry } entity="export" view={ false } />
        <h2>
          <a href={ `/api/v1/export/${ this.props.entry.filename }/download` } download={ this.props.entry.filename }>{ this.props.entry.filename }</a>
        </h2>
        <div>
          <label>Size:</label> { prettyBytes(this.props.entry.length) }
        </div>
        <div>
          <label>MD5 sum:</label> { this.props.entry.md5 }
        </div>
        <div>
          <label>Export date:</label> <Moment date={ this.props.entry.uploadDate } />
        </div>
        <table>
          <thead>
            <tr>
              <th>User</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {
              this.props.entry.metadata.downloads?.map((download, index) => (
                <tr key={ index }>
                  <td>{ download.name }</td>
                  <td><Moment date={ download.downloadDate } /></td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    );
  }
}
