
export default {
  initialState: {
    isOpen: true,
    isLocked: true
  },

  before: (state, action, handler) => {
    return handler(state, action);
  },

  actions: {
    INITIALIZE(state) {
      return { ...state }
    },
    MENU_OPEN(state, { isLocked = false }) {
      return { ...state, isOpen: true, isLocked }
    },
    MENU_CLOSE(state) {
      return { ...state, isOpen: false }
    },
    MENU_NAVIGATE(state) {
      if(state.isLocked) {
        return state;
      } else {
        return { ...state, isOpen: false };
      }
    }
  }
}
