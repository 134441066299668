import React from 'react';
import FormInput from '../FormInput';

const parInt = (value) => value ? parseInt(value) : ''

export default (props) => (
  <FormInput
    {...props}
    type="number"
    onChage={parInt}
    parseValue={parInt}
    parsePreHandleChange={parInt} />
);