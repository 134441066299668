
import FinaleAccessor from '/lib/accessors/FinaleAccessor';
import CRUDActions from '/lib/CRUDActions';
import clientHooks from './clientHooks';

class ValidatorGroupAccessor extends FinaleAccessor {

  async copy(id, data = {}) {
    const response = await this.fetch(`/${id}/copy`, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(data)
    });

    if(response.status === 200) {
      return this.generateEntry(await response.json());
    } else if(response.status === 422) {
      throw new ConsoleError('validation_error', `copying ${this.entity}`);
    } else if(response.status === 403) {
      throw new ConsoleError('forbidden', `copying ${this.entity}`);
    } else if(response.status === 401) {
      throw new ConsoleError('unouthorized', `copying ${this.entity}`);
    } else {
      throw new ConsoleError('unknown', `copying ${this.entity}`);
    }
  }

}

export const ValidatorGroup = new ValidatorGroupAccessor('/api/v1', 'validator_group');
export const ValidatorGroupActions = CRUDActions(ValidatorGroup, {
  hooks: [
    {
      name: 'update_afterSave',
      async handler(orders) {
        if(!orders) {
          return;
        }

        const { validator_group: { id } } = this.getState();
        await this.accessor.fetch(`/${ id }/validators/order`, {
          method: 'PUT',
          headers: new Headers({ 'Content-Type': 'application/json' }),
          body: JSON.stringify({ orders })
        });
      }
    },
    ...clientHooks
  ],

  getCustomActions({ reducer, accessor }) {
    return {
      copyFromEntity(id, data, callback ) {
        return async (dispatch, getState) => {
          dispatch(reducer('COPY', { id }));

          try {
            const data = await accessor.copy(id, data);

            if(callback) {
              callback(data);
            }

            dispatch(reducer('COPY_SUCCESS', {
              id,
              data: {
                ...data,
                id: undefined,
                createdAt: undefined,
                updatedAt: undefined
              }
            }));
          } catch(error) {
            dispatch(reducer('COPY_FAIL', { id, error }));
          }
        }
      }
    }
  }
});

export const fetchValidatorGroups = ValidatorGroupActions.fetchValidatorGroups;
export const fetchValidatorGroup = ValidatorGroupActions.fetchValidatorGroup;
export const setValidatorGroupListQuery = ValidatorGroupActions.setValidatorGroupListQuery;
export const updateValidatorGroupField = ValidatorGroupActions.updateValidatorGroupField;
export const updateValidatorGroup = ValidatorGroupActions.updateValidatorGroup;
export const deleteValidatorGroup = ValidatorGroupActions.deleteValidatorGroup;
export const copyFromValidatorGroup = ValidatorGroupActions.copyFromValidatorGroup;
export const createValidatorGroup = ValidatorGroupActions.createValidatorGroup;
export const cleanValidatorGroupData = ValidatorGroupActions.cleanValidatorGroupData;
