import React, { Component } from 'react';
import FormGroup from './FormGroup';
import withForm from './withForm';

@withForm()
export default class FormTextarea extends Component {
  render() {
    return (
      <FormGroup className={ this.props.groupClassName }>
        { this.props.label && <label className={ this.props.labelClassName } htmlFor={ this.props.name }>{ this.props.label }</label> }
        <div className={ this.props.inputClassName }>
          <textarea
            id={ this.props.name }
            type={ this.props.type }
            className={ "form-control" + (this.props.size ? ' form-control-' + this.props.size : '') }
            placeholder={ this.props.placeholder }
            aria-describedby={ this.props.placeholder }
            readOnly={ this.props.readOnly }
            onChange={ e => this.props.onChange(e.target.value) }
            value={ this.props.value }
          />
        </div>
        { this.props.text && <small id={ this.props.name + '_small' } className="form-text text-muted">{ this.props.text }</small> }
      </FormGroup>
    );
  }
}
