
import React, { Component } from 'react';

import connect from '/components/decorators/connect';

import { Link } from 'react-router-dom';

import CardList from '/components/ui/CardList/CardList';
import UserCard from './UserCard';
import { FormInput } from '/components/ui/Forms';

import { fetchUsersList } from '/actions/user'

@connect()
export default class UserList extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      items: globalState.user.list.items,
      isLoading: globalState.user.list.isLoading
    }
  }

  static mapDispatchToProps = {
    fetch: fetchUsersList,
  }

  componentDidMount() {
    this.props.fetch();
  }

  findResults(queryText) {
    if (this.timeOutId) {
      window.clearTimeout(this.timeOutId);
    }
    this.timeOutId = setTimeout(() => this.props.fetch(queryText), 500);
  }

  render() {
    return (
      <div className="container-fluid view-list">
        <div className="row">
          <div className="col-9">
            <h1>
              Users
              <Link className="btn btn-primary float-right" to="/console/user/create">NEW USER</Link>
            </h1>
          </div>
          <div className="col-3">
            <FormInput noForm name="queryText" onChange={value => this.findResults(value)} placeholder="Find by text" />
          </div>
        </div>
        <div className="row">
          <div className="col-9">
            <CardList
              cards={this.props.items}
              render={entry => <UserCard key={entry.id} entry={entry} actions={{ list: false }} />}
              isLoading={this.props.isLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}
