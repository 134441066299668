
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import Form, { FormInput,  FormSubmit } from '/components/ui/Forms';
import Case from 'case';

import { createClient, updateClientField } from '/actions/client';

@connect()
@decorate(withRouter)
export default class ClientCreate extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      entry: globalState.client.data,
      isLoading: globalState.client.isLoading,
      submitting: globalState.client.creating,
      failSubmit: globalState.client.failCreating
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateField: (field, value) => dispatch(updateClientField(field, value)),
      create: callback => dispatch(createClient(callback))
    }
  }

  componentDidMount() {
  }

  save() {
    this.props.create(entry => {
      this.props.history.push(`/console/client/${ this.props.entry.code }/survey`);
    });
  }

  update(field, value) {
    if(field === 'name') {
      this.update('code', Case.kebab((value || '').toLowerCase()).substr(0, 50));
    }

    this.props.updateField(field, value);
  }

  render() {
    if(this.props.isLoading) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <h1>Create new client</h1>
        <Form
          onChange={ (field, value) => this.update(field, value) }
          onSubmit={ () => this.save() }
          values={ this.props.entry || {} }
          submitting={this.props.submitting}
          failSubmit={this.props.failSubmit}
        >
          <FormInput name="name" label="Name:" text={`code: ${this.props.entry?.code || ''}`} /> 
          <FormSubmit />
        </Form>
      </div>
    );
  }
}
