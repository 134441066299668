
import React, { Component } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import connect from '/components/decorators/connect';
import decorate from '/components/decorators/decorate';
import '/components/layout/Menu.scss';

@connect()
@decorate(withRouter)
export default class extends Component {

  static mapStateToProps(globalState) {
    return {
      isOpen: globalState.menu.isOpen,
      isLocked: globalState.menu.isLocked
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      showMenu: (isLocked) => dispatch({ type: 'MENU_OPEN' }),
      hideMenu: () => dispatch({ type: 'MENU_CLOSE' })
    }
  }

  componentDidMount() {
    this.lastIsMobile = this.isMobile();
    this.toggleMenu(this.isMobile() ? false : true);

    window.addEventListener('resize', () => {
      if(this.lastIsMobile !== this.isMobile()) {
        this.toggleMenu(this.isMobile() ? false : true, true);
        this.lastIsMobile = this.isMobile();
      }
    });

    this.props.history.listen(() => {
      if(this.isMobile()) {
        this.toggleMenu(false);
      }
    });
  }

  isMobile() {
    return window.matchMedia(`(max-width: 768px)`).matches;
  }

  toggleMenu(bool = !this.props.isOpen) {
    if(this.props.isOpen === bool) {
      return;
    }

    if(bool) {
      this.props.showMenu();
    } else {
      this.props.hideMenu();
    }
  }

  render() {
    return (
      <Menu
        customBurgerIcon={ false }
        isOpen={ this.props.isOpen }
        onStateChange={ state => this.toggleMenu(state.isOpen) }
        noOverlay={ !this.isMobile() }
        pageWraperId={ this.props.pageWraperId }
        outerContainerId={ this.props.outerContainerId }
        width="160px"
        disableCloseOnEsc
      >
        <div className="logo">
          <NavLink to={ this.props.logoTo }>
            <img src={ this.props.logo } alt={ this.props.logoAlt } />
          </NavLink>
        </div>
        <div className="menu-items">
          { this.props.children }
        </div>
      </Menu>
    );
  }
}
