import React, { Component } from 'react';
import { FormSelect } from '/components/ui/Forms';

import { Country } from '/actions/country';

export default class CountryInput extends Component {

  state = {
    options: []
  }

  static get defaultProps() {
    return {
      label: 'Country:',
      valueField: 'id',
      contentField: 'name',
      name: 'countryId',
      placeholder: ''
    }
  }

  componentWillMount() {
    this.load = true;
  }

  componentWillUnmount() {
    this.load = false;
  }

  async componentDidMount() {
    const { data: countries } = await Country.fetchAll({ isEnabled: true }, 'name')

    if(!this.load) {
      return;
    }

    this.setState({
      options: countries.map(country => ({
        value: country[this.props.valueField],
        content: country[this.props.contentField]
      }))
    })
  }

  render() {
    return (
      <FormSelect
        { ...this.props }
        default="*undefined*"
        options={ [ { value: undefined, content: this.props.placeholder }, ...this.state.options ] }
      />
    );
  }
}
