
import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import Card from '/components/ui/CardList/Card';
import ReactCountryFlag from "react-country-flag";

export default ({ entry, ...props }) => (
  <Link to={ `/country/${entry.code}` }>
    {entry.name} <ReactCountryFlag svg code={ entry.code } />
  </Link>
)