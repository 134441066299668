import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import FormContext from './FormContext';

export default class Form extends Component {

  static propTypes = {
    values: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    classNames: PropTypes.object,
    failSubmit: PropTypes.bool
  };

  handleSubmit(e) {
    e.preventDefault();
    this.props.onSubmit();
    return false;
  }

  handleChange(name, value) {
    if (this.props.onChange) {
      this.props.onChange(name, value);
    }
  }

  getChildContextValue() {
    return {
      handleChange: (name, value) => this.handleChange(name, value),
      handleSubmit: (event) => this.handleSubmit(event),
      handleRename: (oldName, newName) => this.handleRename(oldName, newName),
      submitting: this.props.submitting,
      failSubmit: this.props.failSubmit,
      values: this.props.values,
      ...(this.props.classNames || {})
    }
  }

  handleRename(oldName, newName) {
    if (!this.props.onRename) {
      throw new Error('Can not rename field.');
    }

    this.props.onRename(oldName, newName);
  }

  render() {
    const props = { ...this.props };
    delete props.onChange;
    delete props.onSubmit;
    delete props.onRename;
    delete props.values;
    delete props.submitting;
    delete props.failSubmit;

    return (
      <FormContext.Provider value={this.getChildContextValue()}>
        <div {...props}>
          {this.props.children}
        </div>
      </FormContext.Provider>
    );
  }
}
