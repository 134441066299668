
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';

import Form, { FormInput, FormSubmit } from '/components/ui/Forms';

import {
  resetPassword,
  updateUserField
} from '/actions/user';

@connect()
@decorate(withRouter)
export default class ResetPassword extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      entry: globalState.user.otherUser.data
    }
  }

  static mapDispatchToProps = {
    updateField: updateUserField,
    resetPassword: resetPassword
  }

  save() {
    if (this.props.entry.password === this.props.entry.confirmPassword) {
      const id = this.props.match.params.id;
      this.props.resetPassword(id, entry => {
        this.props.history.push(`/console/user/${id}`);
      });
    }
  }

  render() {
    return (
      <div className="container">
        <h1>Reset password</h1>
        <Form
          onChange={this.props.updateField}
          onSubmit={() => this.save()}
          values={this.props.entry || {}}>
          <FormInput name="password" autoComplete="new-password" type="password" label="Password:" />
          <FormInput name="confirmPassword" autoComplete="new-password" type="password" label="Confirm password:" />
          <FormSubmit />
        </Form>
      </div>
    );
  }
}
