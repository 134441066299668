
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import CRUDActions from '/components/ui/Actions/CRUDActions';

import DataSourceCard from '/components/views/DataSource/DataSourceCard';
import CountryCard from '/components/views/Country/CountryCard';
import ValidatorGroupCard from '/components/views/ValidatorGroup/ValidatorGroupCard';

import moment from 'moment';

import { fetchSurvey } from '/actions/survey';

import ResponsesCount from '/components/views/Response/ResponsesCount';
import ResponsesChart from '/components/views/Response/ResponsesChart';

@connect()
@decorate(withRouter)
export default class SurveyView extends Component {

  static mapStateToProps(globalState) {
    return {
      entry: globalState.survey.data,
      isLoading: globalState.survey.isLoading,
      clientCode: globalState.client.code
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      fetchSurvey: surveyId => dispatch(fetchSurvey(surveyId))
    }
  }

  componentDidMount() {
    this.props.fetchSurvey(this.props.match.params.surveyId);
  }

  render() {
    if(this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }

    return (
      <div className="container">
        <CRUDActions entry={ this.props.entry } entity="survey" view={ false } />
        <h2>Survey #{ this.props.entry.id }</h2>
        <div><b>Source Survey ID:</b> { this.props.entry.sourceSurveyId }</div>
        {
          (this.props.entry.startDate || this.props.entry.endDate) && (
            <div>
              Responses are valid
              &nbsp;
              {
                this.props.entry.startDate && (
                  <span>since { moment(this.props.entry.startDate).format('YYYY-MM-DD') }</span>
                )
              }
              &nbsp;
              {
                this.props.entry.endDate && (
                  <span>until { moment(this.props.entry.endDate).format('YYYY-MM-DD') }</span>
                )
              }
            </div>
          )
        }
        <h3>Responses (<ResponsesCount clientCode={this.props.clientCode} filter={{ surveyId: this.props.entry.id }} />)</h3>
        <ResponsesChart clientCode={this.props.clientCode} filter={{ surveyId: this.props.entry.id }} />
        <div className="row">
          <div className="col-6">
            <h3>Data Source</h3>
            { this.props.entry.dataSource && <DataSourceCard entry={ this.props.entry.dataSource } /> }
          </div>
          <div className="col-6">
            <h3>Country</h3>
            { this.props.entry.country && <CountryCard entry={ this.props.entry.country } /> }
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <h3>Primary Validator group</h3>
            { this.props.entry.validatorGroup && <ValidatorGroupCard entry={ this.props.entry.validatorGroup } /> }
          </div>
          <div className="col-6">
            <h3>Secondary Validator group</h3>
            { this.props.entry.validatorGroup && <ValidatorGroupCard entry={ this.props.entry.secondaryValidatorGroup } /> }
          </div>
        </div>
      </div>
    );
  }
}
