
import Case from 'case';

export default (entity, prefix, reducers = {}) => {
  if (typeof prefix === 'object') {
    reducers = prefix;
    prefix = undefined;
  }

  prefix = prefix || Case.constant(entity) + '_' + 'CRUD';

  const withPrefix = reducer => {
    return Object.keys(reducer).reduce(
      (res, key) => ({
        ...res,
        [prefix + '_' + key]: reducer[key]
      }),
      {}
    );
  };

  const updateList = (state, update) => ({
    ...state,
    list: {
      ...state.list,
      ...update
    }
  });

  const crudReducers = withPrefix({
    FETCH_LIST(state, { page }) {
      return updateList(state, {
        isLoading: true,
        items: [],
        page,
        contentRange: null
      });
    },

    FETCH_LIST_SUCCESS(state, { data, page, contentRange }) {
      if (state.list.page !== page) {
        return state;
      }

      return updateList(state, {
        isLoading: false,
        items: data,
        contentRange
      });
    },

    FETCH_LIST_FAIL(state, { error }) {
      return updateList(state, {
        isLoading: false,
        error
      });
    },

    LIST_QUERY_SET(state, { field, value }) {
      return updateList(state, {
        query: {
          ...state.list.query,
          [field]: value
        }
      });
    },

    FETCH(state, { id, refresh }) {
      if (refresh && id !== state.id) {
        return state;
      } else if (refresh) {
        return { ...state, isLoading: true, isRefreshing: true };
      } else {
        return { ...state, id, isLoading: true, data: null, copyId: null };
      }
    },

    FETCH_SUCCESS(state, { id, data }) {
      if (id !== state.id) {
        return state;
      }

      return { ...state, isLoading: false, isRefreshing: false, data };
    },

    FETCH_FAIL(state, { id, error }) {
      if (id !== state.id) {
        return state;
      }

      return { ...state, isLoading: false, isRefreshing: false, error };
    },

    UPDATE_FIELD(state, { field, value }) {
      return {
        ...state,
        failUpdating: false,
        failCreating: false,
        data: {
          ...state.data,
          [field]: value
        }
      };
    },

    COPY(state, { id }) {
      return { ...state, isLoading: true, data: null, copyId: id };
    },

    COPY_SUCCESS(state, { id, data }) {
      if (id !== state.copyId) {
        return state;
      }

      return {
        ...state,
        isLoading: false,
        data: {
          ...data,
          id: state.data ? state.data.id : undefined
        }
      };
    },

    COPY_FAIL(state, { id, error }) {
      if (id !== state.copyId) {
        return state;
      }

      return { ...state, isLoading: false, error, copyId: null };
    },

    CLEAN_DATA(state) {
      return { ...state, data: {} };
    },




    UPDATE(state) {
      return { ...state, updating: true, failUpdating: false };
    },
    UPDATE_SUCCESS(state) {
      return { ...state, updating: false, failUpdating: false };
    },
    UPDATE_FAIL(state) {
      return { ...state, updating: false, failUpdating: true };
    },
    CREATE(state) {
      return { ...state, creating: false, failCreating: false };
    },
    CREATE_SUCCESS(state) {
      return { ...state, creating: false, failCreating: false };
    },
    CREATE_FAIL(state) {
      return { ...state, creating: false, failCreating: true };
    },

  });

  return {
    ...crudReducers,
    ...reducers
  };
}
