import React, { Component } from 'react';
import withForm from './withForm';
import Switch from "react-switch";

@withForm()
export default class FormSwitch extends Component {

  static get defaultProps() {
    return {
      onValue: true,
      offValue: false,
      keepOldValue: false,
      isChecked: value => !!value
    }
  }

  state = {
    oldValue: undefined
  }

  render() {
    return (
      <Switch
        checked={this.props.isChecked(this.props.value)}
        onChange={value => {
          if(value) {
            if(this.props.keepOldValue && typeof this.state.oldValue !== 'undefined') {
              this.props.onChange(this.state.oldValue);
            } else {
              this.props.onChange(this.props.onValue);
            }
          } else {
            if(this.props.keepOldValue) {
              this.setState({ oldValue: this.props.value });
            }

            this.props.onChange(this.props.offValue);
          }
        }}
      />
    )
  }
}
