import React, { Component } from 'react';

import Icon from '/components/ui/Icon';

export default props => (
  <a
    className="action"
    download={ (props.name || props.entry.name || props.entity + '_' + props.id ) + '.json' }
    href={ 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(props.entry, null, 4)) }
  >
    <Icon icon="download" />
  </a>
);
