
import React, { Component } from 'react';

import Form, { FormInput, FormSelect, FormSubmit } from '/components/ui/Forms';
import CountryInput from '/components/views/Country/CountryInput';

import withUser from '../../../decorators/withUser';

@withUser()
export default class ProcessTaskForm extends Component {

  getTypeOptions() {
    const typeOptions = [];

    if(this.props.hasPermission('command:client:process:new')) {
      typeOptions.push({ value: "new", content: "Process newly fetched responces" });
    }

    if(this.props.hasPermission('command:client:process:all')) {
      typeOptions.push({ value: "all", content: "Process all responces from a given year-month" });
    }

    if(this.props.hasPermission('command:client:process:valid')) {
      typeOptions.push({ value: "valid", content: "Process all valid responses (only generate variables and validate comments)" });
    }

    return typeOptions;
  }

  render() {
    return (
      <Form
        values={ this.props.values }
        onChange={ this.props.onChange }
        onSubmit={ 
          () => this.props.onSubmit(`client:process:${ this.props.values.type }`, [
            this.props.client.code,
            '--country', this.props.values.country,
            ...(this.props.values.year ? ['--year', this.props.values.year] : []),
            ...(this.props.values.month ? ['--month', this.props.values.month] : []),
            '--verbose'
          ])
        }
      >
        <FormSelect
          name="type"
          label="Process type:"
          options={ this.getTypeOptions() }
        />
        <CountryInput label="Country:" name="country" valueField="code" />
        <FormInput label="Year:" name="year" type="number" if={ this.props.values.type === 'all' } />
        <FormInput label="Month:" name="month" type="number" if={ this.props.values.type === 'all' } />
        <FormSubmit>Run</FormSubmit>
      </Form>
    );
  }

}
