
import React, { Component } from 'react';

import Form, { FormInput, FormSelect, FormSubmit, FormDate, FormCheckbox, FormTextarea } from '/components/ui/Forms';
import CountryInput from '/components/views/Country/CountryInput';
import FieldmapGroupInput from '../../FieldmapGroup/FieldmapGroupInput';

export const ExportToDBForm = ({ onSubmit, ...props }) => (
  <Form
    { ...props }
    onSubmit={ 
      () => onSubmit('client:export:db', [
        props.client.code,
        '--format', props.values.format,
        '--mapId', props.values.mapId,
        '--country', props.values.country,
        '--from', props.values.from,
        '--to', props.values.to,
        '--filename', props.values.filename,
        ...(props.values.valid ? [ '--valid' ] : []),
        '--verbose'
      ])
    }
  >
    <FormSelect
      name="format"
      label="Format:"
      options={
        [
          { value: "spss", content: "DAT (SPSS)" },
          { value: "csv", content: "CSV (Comma separated values)" },
          { value: "psv", content: "PSV (Pipe separated values)" },
          { value: "tsv", content: "TSV (Tab separated values)" },
          { value: "xlsx", content: "XLSX (Microsoft Excel 2017)" },
          { value: "xls", content: "XLS (Microsoft Excel)" },
          { value: "jsonarray", content: "JSON (Array)" },
          { value: "jsonlist", content: "JSON (Object list)" }
        ]
      }
    />
    <CountryInput label="Country:" name="country" valueField="code" />
    <FormDate label="From date:" name="from" utcOffset={ 0 } />
    <FormDate label="To date:" name="to" utcOffset={ 0 } />
    <FormInput label="File name:" name="filename" />
    <FieldmapGroupInput name="mapId" />
    <FormCheckbox label="Only valids" name="valid" />
    <FormSubmit>Run</FormSubmit>
  </Form>
);

export const ExportToEmailForm = ({ onSubmit, ...props }) => (
  <Form
    { ...props }
    onSubmit={ 
      () => onSubmit('client:export:email', [
        props.client.code,
        '--format', props.values.format,
        '--mapId', props.values.mapId,
        '--country', props.values.country,
        '--from', props.values.from,
        '--to', props.values.to,
        '--filename', props.values.filename,
        ...(props.values.valid ? [ '--valid' ] : []),
        '--emailFrom', props.values.emailFrom,
        '--emailTo', props.values.emailTo.join(','),
        ...(props.values.emailCc && props.values.emailCc.length ? ['--emailCc', props.values.emailCc.join(',')] : []),
        ...(props.values.emailBcc && props.values.emailBcc.length ? ['--emailBcc', props.values.emailBcc.join(',')] : []),
        '--emailSubject', props.values.emailSubject,
        '--emailText', props.values.emailText,
        '--verbose'
      ])
    }
  >
    <FormSelect
      name="format"
      label="Format:"
      options={
        [
          { value: "spss", content: "DAT (SPSS)" },
          { value: "csv", content: "CSV (Comma separated values)" },
          { value: "psv", content: "PSV (Pipe separated values)" },
          { value: "tsv", content: "TSV (Tab separated values)" },
          { value: "xlsx", content: "XLSX (Microsoft Excel 2017)" },
          { value: "xls", content: "XLS (Microsoft Excel)" },
          { value: "jsonarray", content: "JSON (Array)" },
          { value: "jsonlist", content: "JSON (Object list)" }
        ]
      }
    />
    <CountryInput label="Country:" name="country" valueField="code" />
    <FormDate label="From date:" name="from" utcOffset={ 0 } />
    <FormDate label="To date:" name="to" utcOffset={ 0 } />
    <FormInput label="File name:" name="filename" />
    <FieldmapGroupInput name="mapId" />
    <FormCheckbox label="Only valids" name="valid" />
    <hr />
    <h2>Email:</h2>
    <FormInput name="emailFrom" label="From:" />
    <FormSelect name="emailTo" label="To:" canCreate isMulti />
    <FormSelect name="emailCc" label="Cc:" canCreate isMulti />
    <FormSelect name="emailBcc" label="Bcc:" canCreate isMulti />
    <FormInput name="emailSubject" label="Subject:" />
    <FormTextarea name="emailText" label="Content:" />
    <FormSubmit>Run</FormSubmit>
  </Form>
);
