
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';

import FieldmapEdit from '/components/views/Fieldmap/FieldmapEdit';

import {
  createFieldmap,
  updateFieldmapField,
  copyFromFieldmap
} from '/actions/fieldmap';

@connect()
@decorate(withRouter)
export default class FieldmapCreate extends Component {

  static mapStateToProps(globalState) {
    return {
      entry: globalState.fieldmap.data,
      submitting: globalState.fieldmap.creating,
      failSubmit: globalState.fieldmap.failCreating
    }
  }

  static mapDispatchToProps = {
    updateField: updateFieldmapField,
    copyFrom: copyFromFieldmap,
    create: (fieldmapGroupId, callback) => createFieldmap(callback, fieldmapGroupId)
  }

  componentDidMount() {
    if (this.props.match.params.fieldmapId) {
      this.props.copyFrom(this.props.match.params.fieldmapId);
    } else {
      this.props.updateField('options', {});
    }
  }

  save() {
    const fieldmapGroupId = this.props.match.params.fieldmapGroupId;
    this.props.create(fieldmapGroupId, entry => {
      this.props.history.push(`/fieldmap_group/${fieldmapGroupId}/fieldmap/${entry.id}`);
    });
  }

  render() {
    return (
      <div className="container">
        <h2>Create fieldmap</h2>
        <FieldmapEdit
          onChange={this.props.updateField}
          onSubmit={() => this.save()}
          values={this.props.entry || { options: {} }}
          name={false}
          submitting={this.props.submitting}
          failSubmit={this.props.failSubmit}
        />
      </div>
    );
  }
}
