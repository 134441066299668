import React from 'react'
import FormInput from '../FormInput';

export default (props) => {
  return <FormInput
    {...props}
    readOnly
    triggerDefaultValue
    value="null"
    onChange={() => null} />
}
