
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import ValidatorEdit from '/components/views/Validator/ValidatorEdit';

import { fetchValidator, updateValidator, updateValidatorField } from '/actions/validator';
import { fetchAllValidatorTypes } from '/actions/validator_types';

@connect()
@decorate(withRouter)
export default class ValidatorUpdate extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      entry: globalState.validator.data,
      isLoading: globalState.validator.isLoading || globalState.validator_types.list.isLoading,
      types: globalState.validator_types.list.items,
      submitting: globalState.validator.updating,
      failSubmit: globalState.validator.failUpdating
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      fetch: id => dispatch(fetchValidator(id)),
      fetchTypes: () => dispatch(fetchAllValidatorTypes()),
      updateField: (field, value) => dispatch(updateValidatorField(field, value)),
      update: () => dispatch(updateValidator())
    }
  }

  componentDidMount() {
    this.props.fetchTypes();
    this.props.fetch(this.props.match.params.validatorId);
  }

  render() {
    if (this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }
    return (
      <div className="container">
        <h2>Update validator #{ this.props.entry.id }</h2>
        <ValidatorEdit
          onChange={ this.props.updateField }
          onSubmit={ this.props.update }
          values={ this.props.entry }
          types={ this.props.types }
          submitting={this.props.submitting}
          failSubmit={this.props.failSubmit}
        />
      </div>
    );
  }
}
