
import FinaleAccessor from '/lib/accessors/FinaleAccessor';
import CRUDActions from '/lib/CRUDActions';
import clientHooks from './clientHooks';

export const Export = new FinaleAccessor('/api/v1', 'export');
export const ExportActions = CRUDActions(Export, { hooks: clientHooks });

export const fetchExports = ExportActions.fetchExports;
export const fetchExport = ExportActions.fetchExport;
export const setExportListQuery = ExportActions.setExportListQuery;
export const updateExportField = ExportActions.updateExportField;
export const updateExport = ExportActions.updateExport;
export const copyFromExport = ExportActions.copyFromExport;
export const createExport = ExportActions.createExport;
export const cleanExportData = ExportActions.cleanExportData;
