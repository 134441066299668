
import FinaleAccessor from '/lib/accessors/FinaleAccessor';
import CRUDActions from '/lib/CRUDActions';
import clientHooks from './clientHooks';

export const Survey = new FinaleAccessor('/api/v1', 'survey');
export const SurveyActions = CRUDActions(Survey, { hooks: clientHooks });

export const fetchSurveys = SurveyActions.fetchSurveys;
export const fetchSurvey = SurveyActions.fetchSurvey;
export const setSurveyListQuery = SurveyActions.setSurveyListQuery;
export const updateSurveyField = SurveyActions.updateSurveyField;
export const updateSurvey = SurveyActions.updateSurvey;
export const deleteSurvey = SurveyActions.deleteSurvey;
export const copyFromSurvey = SurveyActions.copyFromSurvey;
export const createSurvey = SurveyActions.createSurvey;
export const cleanSurveyData = SurveyActions.cleanSurveyData;
