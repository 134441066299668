
import React, { Component } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import withLocale from '/components/decorators/withLocale';

@withLocale()
export default class extends Component {
	render() {
		const props = { ...this.props };
		delete props.dispatch;

		return <Moment { ...props } date={ moment.utc(props.date).local().toDate() } />
	}
}
