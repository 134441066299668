
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import connect from '/components/decorators/connect';
import decorate from '/components/decorators/decorate';

import '/components/layers/LoginLayer.scss';

@connect()
@decorate(withRouter)
export default class LoginLayer extends Component {

  static mapStateToProps(globalState) {
    return {
      isUserLogged: globalState.user.isLogged
    }
  }

  componentDidMount() {
    if(this.props.hasOwnProperty('isUserLogged') && this.props.isUserLogged) {
      this.props.history.push('/console');
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(nextProps.hasOwnProperty('isUserLogged') && nextProps.isUserLogged) {
      this.props.history.push('/console');
      return false;
    } else {
      return true;
    }
  }

  render() {
    return <div className="layer-login">{this.props.children}</div>;
  }
}
