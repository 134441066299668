
import React, { Component } from 'react';

import withUser from '/components/decorators/withUser';

import EditAction from '/components/ui/Actions/EditAction';
import ViewAction from '/components/ui/Actions/ViewAction';
import CopyAction from '/components/ui/Actions/CopyAction';
import DeleteAction from '/components/ui/Actions/DeleteAction';
import CreateAction from '/components/ui/Actions/CreateAction';
import ListAction from '/components/ui/Actions/ListAction';
import ExportAction from '/components/ui/Actions/ExportAction';
import Actions from '/components/ui/Actions/Actions';

@withUser()
export default class CRUDActions extends Component {

  static get defaultProps() {
    return {
      list: true,
      view: true,
      edit: true,
      delete: true,
      copy: true,
      create: true,
      export: true,
      permissionEntity: undefined,
      idField: 'id',
      nameField: 'name',
      aditionalActions: []
    }
  }

  getPermissionEntity() {
    return this.props.permissionEntity === undefined ? this.props.entity : this.props.permissionEntity;
  }

  hasPermission(action) {
    const permissionEntity = this.getPermissionEntity();
    if(permissionEntity) {
      return this.props.hasPermission(permissionEntity + ':' + action);
    } else {
      return true;
    }
  }

  canList() {
    return this.props.list && this.hasPermission('view');
  }

  canView() {
    return this.props.view && this.hasPermission('view');
  }

  canEdit() {
    return this.props.edit && this.hasPermission('update');
  }

  canCreate() {
    return this.props.create && this.hasPermission('create');
  }

  canCopy() {
    return this.props.copy && this.hasPermission('create');
  }

  canDelete() {
    return this.props.delete && this.hasPermission('delete');
  }

  canExport() {
    return this.props.export && this.hasPermission('view');
  }

  render() {
    const actionProps = {
      entity: this.props.entity,
      entry: this.props.entry,
      id: this.props.entry[this.props.idField],
      basePath: this.props.basePath || '',
      name: this.props.entry[this.props.nameField]
    }

    return (
      <Actions>
        { this.canList() && <ListAction { ...actionProps } /> }
        { this.canView() && <ViewAction { ...actionProps } /> }
        { this.canEdit() && <EditAction { ...actionProps } /> }
        { this.canCreate() && <CreateAction { ...actionProps } /> }
        { this.canCopy() && <CopyAction { ...actionProps } /> }
        { this.canDelete() && <DeleteAction { ...actionProps } /> }
        { this.canExport() && <ExportAction { ...actionProps } /> }
        { this.props.aditionalActions.map((Action, key) => <Action { ...actionProps } key={key} />) }
        { this.props.children }
      </Actions>
    )
  }
}
