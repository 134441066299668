
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';
import Loader from '/components/ui/Loader';

import UserEdit from '/components/views/User/UserEdit';

import { fetchUser, updateUserField, updateUser } from '/actions/user';

@connect()
@decorate(withRouter)
export default class UserUpdate extends Component {

  static mapStateToProps(globalState) {
    return {
      user: globalState.user.data,
      entry: globalState.user.otherUser.data,
      isLoading: globalState.user.otherUser.isLoading,
      submitting: globalState.user.otherUser.updating,
      failSubmit: globalState.user.otherUser.failUpdating
    }
  }

  static mapDispatchToProps = {
    fetch: fetchUser,
    updateField: updateUserField,
    update: updateUser
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.id);
  }

  render() {
    if (this.props.isLoading || !this.props.entry) {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }
    return (
      <div className="container">
        <h2>Update user #{this.props.entry.id}</h2>
        <UserEdit
          onChange={this.props.updateField}
          onSubmit={this.props.update}
          values={this.props.entry}
          types={this.props.types}
          submitting={this.props.submitting}
          failSubmit={this.props.failSubmit}
        />
      </div>
    );
  }
}
