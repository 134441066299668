import React from 'react';

import Form, { FormInput, FormSubmit } from '/components/ui/Forms';

export default (props) => (
  <Form
    onChange={props.onChange}
    onSubmit={props.onSubmit}
    values={props.values}
  >
    <FormInput name="name" label="Name:" />
    <FormInput name="email" label="Email:" />
    <FormSubmit />
  </Form>
)