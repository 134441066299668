
import fetch from '/lib/fetch';

import Accessor from '/lib/accessors/Accessor';
import queryParams from '/lib/queryParams';
import CRUDActions from '/lib/CRUDActions';
import ConsoleError from '/error/ConsoleError';
import clientHooks from './clientHooks';

class TaskAccessor extends Accessor {

  async fetchList(page, perPage, sort, query) {
    const offset = (page - 1) * perPage;
    const count = perPage;
    const response = await this.fetch(`?${queryParams({ offset, count, sort, ...query })}`);
    
    if(response.status === 200) {
      const contentRange = this.getContentRange(response);
      const data = this.generateEntries(await response.json());
      return { data, contentRange };
    } else if(response.status === 403) {
      throw new ConsoleError('forbidden', `fetching ${this.entity} list`);
    } else if(response.status === 401) {
      throw new ConsoleError('unouthorized', `fetching ${this.entity} list`);
    } else {
      throw new ConsoleError('unknown', `fetching ${this.entity} list`);
    }
	}

  async fetchOne(id) {
    const response = await this.fetch(`/${id}`);

    if(response.status === 200) {
      return this.generateEntry(await response.json());
    } else if(response.status === 404) {
      throw new ConsoleError('not found', `fetching ${this.entity} ${id}`);
    } else if(response.status === 403) {
      throw new ConsoleError('forbidden', `fetching ${this.entity} ${id}`);
    } else if(response.status === 401) {
      throw new ConsoleError('unouthorized', `fetching ${this.entity} ${id}`);
    } else {
      throw new ConsoleError('unknown', `fetching ${this.entity} ${id}`);
    }
  }

  getContentRange(response) {
    const contentRange = response.headers.get('content-range');
    const [ match, $1, $2, $3 ] = contentRange.match(/items ([0-9]{1,})-([0-9]{1,})\/([0-9]{1,})/);
    const [ from, to, total ] = [ parseInt($1), parseInt($2), parseInt($3) ];
    return { from, to, total };
  }

}

export const Task = new TaskAccessor('/api/v1', 'task');
export const TaskActions = CRUDActions(Task, { hooks: clientHooks });

export const fetchTasks = TaskActions.fetchTasks;
export const fetchTask = TaskActions.fetchTask;
export const setTaskListQuery = TaskActions.setTaskListQuery;

export const runCommand = async (clientCode, command, args, callback) => {
  const response = await fetch('/api/v1/task/run/command', {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      clientCode,
      command,
      args
    })
  });

  if(callback) {
  	callback(await response.json());
  }
}

