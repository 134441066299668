
import React from 'react';
import { find } from 'lodash';

import Form, { FormInput, FormSubmit, FormTextarea, FormSelect, FormJSONSchema } from '/components/ui/Forms';

export default ({ onChange, onSubmit, values, types, ...props }) => {
  const validator = find(types, { type: values.type });
  return (
    <Form
      onChange={onChange}
      onSubmit={onSubmit}
      values={values}
      submitting={props.submitting}
      failSubmit={props.failSubmit}
    >
      <FormInput name="name" label="Name:" />
      <FormInput name="message" label="Message:" />
      <FormTextarea name="description" label="Description:" placeholder="(optional)" />
      <FormSelect
        name="type"
        label="Type:"
        options={types.map(({ type }) => ({ value: type, content: type }))}
        onChange={
          value => {
            onChange('options', {});
            return value;
          }
        } />
      {validator && (<FormJSONSchema name="options" schema={validator.schema} />)}
      <FormSubmit />
    </Form>
  )
}
