
import React, { Component } from 'react';

import { withRouter } from 'react-router';
import decorate from '/components/decorators/decorate';
import connect from '/components/decorators/connect';

import { updateDataSourceField, createDataSource, cleanDataSourceData, copyFromDataSource } from '/actions/dataSource';
import { DataSourceEdit } from './DataSourceEdit';
import { fetchAllDataSourcesTypes } from '/actions/datasource_types';

@connect()
@decorate(withRouter)
export default class DataSourceCreate extends Component {

  static mapStateToProps(store) {
    return {
      entry: store.data_source.data,
      submitting: store.data_source.creating,
      failSubmit: store.data_source.failCreating,
      types: store.datasource_types.list.items
    }
  }

  static mapDispatchToProps = {
    updateField: updateDataSourceField,
    create: createDataSource,
    cleanData: cleanDataSourceData,
    copyFrom: copyFromDataSource,
    fetchTypes: fetchAllDataSourcesTypes
  }

  componentDidMount() {
    this.props.fetchTypes();
    if (this.props.match.params.id) {
      this.props.copyFrom(this.props.match.params.id);
    } else {
      this.props.cleanData();
    }
  }

  save() {
    this.props.create(entry => {
      this.props.history.push(`/console/data_source/${entry.id}`);
    });
  }

  render() {

    return (
      <div className="container">
        <h1>Create new Data source</h1>

        <DataSourceEdit
          onChange={this.props.updateField}
          onSubmit={() => this.save()}
          types={this.props.types}
          values={this.props.entry || {}}
          submitting={this.props.submitting}
          failSubmit={this.props.failSubmit} />

      </div>
    );
  }
}
